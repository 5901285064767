import { createSlice } from '@reduxjs/toolkit';

import { STORE_PATH_CRM } from '@loop/constants/store-constatns';

import {
  AboutCustomer,
  CreateCommercialCustomer,
  GeneralInfo,
} from '@loop-crm/features/commercial-customers/types/commercial-customers-type';
import { CreateCustomerForm } from '@loop-crm/types/contact-type';

const sliceName = STORE_PATH_CRM.COMMERCIAL_CUSTOMERS;
const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as CreateCommercialCustomer)
  : null;

const defaultState = {
  aboutCustomer: null,
  generalInfo: null,
  contactPerson: null,
};

let initialState: CreateCommercialCustomer = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveCompanyId: (state, action: { payload: string }) => {
      state.companyId = action.payload;
    },
    saveAboutCustomer: (state, action: { payload: AboutCustomer }) => {
      state.aboutCustomer = action.payload;
    },
    saveGeneralInfo: (state, action: { payload: GeneralInfo }) => {
      state.generalInfo = action.payload;
    },
    saveContactPerson: (
      state,
      action: { payload: CreateCustomerForm | undefined }
    ) => {
      state.contactPerson = action.payload;
    },
    resetCustomerState: (state) => {
      state.aboutCustomer = null;
      state.contactPerson = null;
      state.generalInfo = null;
    },
    resetCompanyId: (state) => {
      state.companyId = undefined;
    },
  },
});

export const {
  saveCompanyId,
  saveAboutCustomer,
  saveGeneralInfo,
  saveContactPerson,
  resetCustomerState,
  resetCompanyId,
} = slice.actions;

export default slice.reducer;

import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

// use https://github.com/andraaspar/i18next-json-csv-converter to send files for translations
i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: localStorage.getItem('language') || 'en',
    backend: {
      backends: [
        HttpBackend,
        // TODO: when production/staging is set up, use localstorage only on those envs
        // LocalStorageBackend,
      ],
      backendOptions: [
        {
          loadPath: `${process.env.PUBLIC_URL}/translations/{{lng}}/{{ns}}.json`,
        },
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        },
      ],
    },
  });

export default i18n;

import { useTranslation } from 'react-i18next';

import { Box, FormLabel, GridItem, SimpleGrid, Text } from '@chakra-ui/react';

import { UserInfo } from '@loop-np/api/services';

interface Props {
  userInfo: UserInfo | null;
}

function UserDetails({ userInfo }: Props) {
  const { t } = useTranslation();

  return (
    <SimpleGrid columns={2} spacing={4} spacingY={0}>
      <Box mb={6}>
        <FormLabel fontWeight={500}>{t('services:First Name')}</FormLabel>
        <Text>{userInfo?.firstName}</Text>
      </Box>

      <Box mb={6}>
        <FormLabel fontWeight={500}>{t('services:Last Name')}</FormLabel>
        <Text>{userInfo?.lastName}</Text>
      </Box>

      <Box mb={6}>
        <FormLabel fontWeight={500}>{t('services:Email Address')}</FormLabel>
        <Text>{userInfo?.email}</Text>
      </Box>

      <Box mb={6}>
        <FormLabel fontWeight={500}>{t('services:Phone Number')}</FormLabel>
        <Text>{userInfo?.phone}</Text>
      </Box>

      <GridItem colSpan={2}>
        <FormLabel fontWeight={500}>{t('services:Description')}</FormLabel>
        <Text>{userInfo?.description}</Text>
      </GridItem>
    </SimpleGrid>
  );
}

export default UserDetails;

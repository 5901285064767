import { createSlice } from '@reduxjs/toolkit';

import { STORE_PATH_NP } from '@loop/constants/store-constatns';

import { BidAttachment, BidState } from '@loop-np/types/projects-types';

const defaultState: BidState = {
  bid: {
    docUrl: [],
  },
};

const sliceName = STORE_PATH_NP.BID;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as BidState)
  : defaultState;

let initialState: BidState = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetBidState: () => {
      initialState = defaultState;
      return defaultState;
    },
    setBidState: (_, action: { payload: BidState }) => action.payload,

    addBidFile: (
      state,
      action: {
        payload: {
          attachment: BidAttachment;
        };
      }
    ) => {
      state.bid.docUrl.push(action.payload.attachment);
    },
    removeBidFile: (
      state,
      action: {
        payload: { index: number };
      }
    ) => {
      state.bid.docUrl.splice(action.payload.index, 1);
    },

    updateBidFile: (
      state,
      action: {
        payload: {
          index: number;
          file: BidAttachment;
        };
      }
    ) => {
      if (
        action.payload.file.name ===
        state.bid.docUrl[action.payload.index]?.name
      ) {
        state.bid.docUrl[action.payload.index] = action.payload.file;
      } else {
        state.bid.docUrl.splice(action.payload.index, 0, action.payload.file);
      }
    },
  },
});

export const {
  updateBidFile,
  addBidFile,
  removeBidFile,
  resetBidState,
  setBidState,
} = slice.actions;

export default slice.reducer;

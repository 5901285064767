import styled from '@emotion/styled';
import { useHistory } from 'react-router';

import { Box, Center, Container, Flex, HStack, Link } from '@chakra-ui/react';

import { ReactComponent as LoopLogo } from '@loop/assets/img/icons/logo.svg';
import { ReactComponent as LoopLogoSmall } from '@loop/assets/img/icons/loop-logo-small.svg';
import HeaderMenu from '@loop/components/layout/header-menu';
import NotificationsList from '@loop/components/layout/notifications-list';
import useRedirectToDashboard from '@loop/hooks/redirect-to-page-by-role';
import { useAppSelector } from '@loop/hooks/redux';

const NoPrint = styled(Box)`
  @media print {
    display: none;
  }
`;

function Header() {
  const pushToDashboard = useRedirectToDashboard();
  const history = useHistory();
  const token = useAppSelector(
    (state) => state.authModule.auth.tokens.accessToken
  );

  const restrictedUrl = ['/signup/company', '/login', '/signup', '/error404'];

  const navigationNotAllowed = restrictedUrl.find(
    (route) => route === history.location.pathname
  );

  return (
    <>
      <NoPrint
        as="header"
        bg="grey.600"
        position="sticky"
        top="0"
        zIndex="2"
        w="100%"
      >
        <Container maxW="container.xl" px={0}>
          <Flex height={14} justifyContent="space-between" align="center">
            {token ? (
              <>
                <Link
                  as={navigationNotAllowed ? 'p' : Link}
                  onClick={() => {
                    pushToDashboard();
                  }}
                  pl="2"
                >
                  <LoopLogo />
                </Link>
                <HStack spacing="4">
                  {!navigationNotAllowed && <NotificationsList />}
                  <HeaderMenu navigationNotAllowed={!!navigationNotAllowed} />
                </HStack>
              </>
            ) : (
              <Center w="100%">
                <LoopLogoSmall />
              </Center>
            )}
          </Flex>
        </Container>
      </NoPrint>
    </>
  );
}

export default Header;

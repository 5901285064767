import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { ReactComponent as CRMIcon } from '@loop/assets/img/icons/crm-management.svg';
import { ReactComponent as MarketingIcon } from '@loop/assets/img/icons/marketing.svg';
import { ReactComponent as DashboardIcon } from '@loop/assets/img/icons/menu-icons/common/dashboard.svg';
import { ReactComponent as DocumentsIcon } from '@loop/assets/img/icons/menu-icons/np/documents.svg';
import { ReactComponent as NpLaborIcon } from '@loop/assets/img/icons/menu-icons/np/labor-items.svg';
import { ReactComponent as NpProductIcon } from '@loop/assets/img/icons/menu-icons/np/np-products.svg';
import { ReactComponent as ProjectsIcon } from '@loop/assets/img/icons/menu-icons/np/projects.svg';
import { ReactComponent as ProposalsIcon } from '@loop/assets/img/icons/menu-icons/np/proposals.svg';
import { ReactComponent as PurchaseRequestIcon } from '@loop/assets/img/icons/menu-icons/np/purchase-orders.svg';
import { ReactComponent as QuotesIcon } from '@loop/assets/img/icons/menu-icons/np/quotes.svg';
import { ReactComponent as ServicesIcon } from '@loop/assets/img/icons/menu-icons/np/services.svg';
import { ReactComponent as BellIcon } from '@loop/assets/img/icons/notif-bell.svg';
import { ReactComponent as ProfileIcon } from '@loop/assets/img/icons/profile.svg';
import {
  INVOICES_ROUTES,
  LEAD_ROUTES,
  LOOP_SETTINGS_ROUTES,
  NP_SETTINGS_ROUTES,
  PROJECTS_ROUTES,
  PROPOSAL_ROUTES,
  PURCHASE_ORDERS_ROUTES,
  QUOTES_ROUTES,
  ROUTES,
  SERVICES_ROUTES,
  getLeadRouteByType,
} from '@loop/constants/routes-constants';
import { NpUser, USER_GROUPS, USER_ROLES } from '@loop/constants/user-roles';
import CreateCompanyLaborModal from '@loop/features/network-partner/features/settings/company-labors/modals/create-company-labor-modal';
import useRoleCheck from '@loop/hooks/role-check';
import ChangeCompanyStatusModal from '@loop/pages/notifications/modals/company-modal';
import ChangeServiceStatusModal from '@loop/pages/notifications/modals/service-modal';
import UserRequestToAssignModal from '@loop/pages/notifications/modals/user-request-to-assign-modal';
import {
  NOTIFICATION_COMPANY,
  NOTIFICATION_INVOICES,
  NOTIFICATION_LEAD,
  NOTIFICATION_NP_LABOR,
  NOTIFICATION_NP_PRODUCT,
  NOTIFICATION_QUOTES,
  NOTIFICATION_TYPES,
  NOTIFICATION_USER,
  Notification,
} from '@loop/types/notifications';

import LandingPageContactFormPreview from '@loop-np/features/settings/landing-page/modals/landing-page-contact-form-preview';

interface ChildProps {
  notification: Notification;
  clickOnNotification: (notification: Notification) => void;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface Props {
  notification: Notification;
  children: (value: ChildProps) => JSX.Element;
}

interface RequestAssignUser {
  id: string;
  role: NpUser;
}

const NotificationWrapper = ({ notification, children }: Props) => {
  const isLoopUser = useRoleCheck(USER_GROUPS.LOOP);
  const history = useHistory();
  const isNpAdmin = useRoleCheck([USER_ROLES.NP_ADMIN]);

  const [serviceOrderId, setServiceOrderId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [userId, setUserId] = useState('');
  const [verifiedCompanyId, setVerifiedCompanyId] = useState('');
  const [isContactFormPreviewOpen, setIsContactFormPreviewOpen] =
    useState(false);
  const [requestAssignUser, setRequestAssignUser] =
    useState<RequestAssignUser | null>(null);
  const [npLaborId, setNpLaborId] = useState('');

  const serviceActionHandler = (id: string) => {
    if (isLoopUser) {
      setServiceOrderId(id);
    } else {
      history.push(SERVICES_ROUTES.BASE_PATH);
    }
  };

  const quotesActionHandler = (id: string) => {
    if (notification.type !== NOTIFICATION_QUOTES.DELETE) {
      history.push(`${QUOTES_ROUTES.PREVIEW}/${id}`);
    } else {
      history.push(QUOTES_ROUTES.BASE_PATH);
    }
  };

  const productActionHandler = () => {
    switch (notification.type) {
      case NOTIFICATION_NP_PRODUCT.UPDATE:
      case NOTIFICATION_NP_PRODUCT.DELETE_FILE:
        history.push(
          `${NP_SETTINGS_ROUTES.BASE_PATH}/company-products/edit/${notification.metadata.id}`
        );
        break;
      default:
        history.push(`${NP_SETTINGS_ROUTES.BASE_PATH}/company-products`);
    }
  };

  const npLaborActionHandler = (id: string) => {
    if (notification.type === NOTIFICATION_NP_LABOR.UPDATE) {
      setNpLaborId(id);
    }
  };

  const invoiceActionHandler = (id: string) => {
    if (notification.type !== NOTIFICATION_INVOICES.DELETE) {
      history.push(`${INVOICES_ROUTES.PREVIEW}/${id}`);
    } else {
      history.push(INVOICES_ROUTES.BASE_PATH);
    }
  };

  const purchaseActionHandler = (id: string) => {
    history.push(`${PURCHASE_ORDERS_ROUTES.PREVIEW}/${id}`, {
      isPreview: true,
    });
  };

  const userActionHandler = () => {
    switch (notification.type) {
      case NOTIFICATION_USER.UPDATE:
        history.push(
          isLoopUser
            ? `${LOOP_SETTINGS_ROUTES.BASE_PATH}/loop-user-profile`
            : `${NP_SETTINGS_ROUTES.BASE_PATH}/user-profile`
        );
        break;
      case NOTIFICATION_USER.DEACTIVATE:
        history.push(`${NP_SETTINGS_ROUTES.BASE_PATH}/company-users`);
        break;
      case NOTIFICATION_USER.REQUEST_ASSIGN:
        if (!!notification.metadata?.requestAssignUserId) {
          setRequestAssignUser({
            id: notification.metadata.requestAssignUserId,
            role: notification.metadata.role as NpUser,
          });
        }
    }
  };

  const projectActionHandler = () => {
    if (notification?.metadata?.projectId) {
      history.push(
        `${PROJECTS_ROUTES.BASE_PATH}/preview/${notification.metadata.projectId}`
      );
    } else {
      history.push(PROJECTS_ROUTES.BASE_PATH);
    }
  };

  const proposalActionHandler = () => {
    if (notification?.metadata?.proposalVersionId) {
      history.push(
        `${PROPOSAL_ROUTES.PREVIEW}/${notification?.metadata?.proposalVersionId}`
      );
    } else {
      history.push(PROPOSAL_ROUTES.BASE_PATH);
    }
  };

  const companyActionHandler = (id: string) => {
    if (isLoopUser) {
      switch (notification.type) {
        case NOTIFICATION_COMPANY.VERIFY:
          setVerifiedCompanyId(id);
          break;
        case NOTIFICATION_COMPANY.CREATE:
          setUserId(notification.metadata.userId || '');
          setCompanyId(id);
          break;
      }
    }
    if (isNpAdmin) {
      history.push(`${NP_SETTINGS_ROUTES.BASE_PATH}/company-information`);
    }
  };

  const crmActionHandler = (id: string) => {
    switch (notification.type) {
      case NOTIFICATION_LEAD.ASSIGN:
      case NOTIFICATION_LEAD.CREATE:
      case NOTIFICATION_LEAD.ARCHIVE:
      case NOTIFICATION_LEAD.UPDATE:
      case NOTIFICATION_LEAD.RESTORED:
        history.push(
          `${getLeadRouteByType(notification.metadata.leadType)}/${
            ROUTES.CRM.LEADS.PREVIEW
          }/${id}`
        );
        break;
      case NOTIFICATION_LEAD.DELETE:
        history.push(`${LEAD_ROUTES.BASE_PATH}`);
        break;
      case NOTIFICATION_LEAD.UPDATE_ACTIVITY_CONFIG:
        break;
      case NOTIFICATION_LEAD.UPDATE_EMBEDDED_FORM_CONFIG:
        setIsContactFormPreviewOpen(true);
        break;
    }
  };

  const clickOnNotification = (notification: Notification) => {
    const { id } = notification.metadata || { id: '' };

    switch (notification.category) {
      case NOTIFICATION_TYPES.SERVICE: {
        serviceActionHandler(id);
        break;
      }
      case NOTIFICATION_TYPES.QUOTES: {
        quotesActionHandler(id);
        break;
      }
      case NOTIFICATION_TYPES.NP_PRODUCT: {
        productActionHandler();
        break;
      }
      case NOTIFICATION_TYPES.NP_LABOR: {
        npLaborActionHandler(id);
        break;
      }
      case NOTIFICATION_TYPES.INVOICE: {
        invoiceActionHandler(id);
        break;
      }
      case NOTIFICATION_TYPES.PURCHASE: {
        purchaseActionHandler(id);
        break;
      }
      case NOTIFICATION_TYPES.USER: {
        userActionHandler();
        break;
      }
      case NOTIFICATION_TYPES.BID:
      case NOTIFICATION_TYPES.PROJECT: {
        projectActionHandler();
        break;
      }
      case NOTIFICATION_TYPES.PROPOSAL: {
        proposalActionHandler();
        break;
      }
      case NOTIFICATION_TYPES.COMPANY: {
        companyActionHandler(id);
        break;
      }
      case NOTIFICATION_TYPES.CRM: {
        crmActionHandler(id);
        break;
      }
      default: {
        break;
      }
    }
  };

  const getProperIcon = (category: string) => {
    switch (category) {
      case NOTIFICATION_TYPES.DASHBOARD:
        return DashboardIcon;
      case NOTIFICATION_TYPES.USER:
      case NOTIFICATION_TYPES.COMPANY:
        return ProfileIcon;
      case NOTIFICATION_TYPES.MARKETING:
        return MarketingIcon;
      case NOTIFICATION_TYPES.PROPOSAL:
        return ProposalsIcon;
      case NOTIFICATION_TYPES.QUOTES:
        return QuotesIcon;
      case NOTIFICATION_TYPES.INVOICE:
        return DocumentsIcon;
      case NOTIFICATION_TYPES.PURCHASE:
        return PurchaseRequestIcon;
      case NOTIFICATION_TYPES.PROJECT:
      case NOTIFICATION_TYPES.BID:
        return ProjectsIcon;
      case NOTIFICATION_TYPES.SERVICE:
        return ServicesIcon;
      case NOTIFICATION_TYPES.CRM:
        return CRMIcon;
      case NOTIFICATION_TYPES.NP_PRODUCT:
        return NpProductIcon;
      case NOTIFICATION_TYPES.NP_LABOR:
        return NpLaborIcon;
      default:
        return BellIcon;
    }
  };

  return (
    <>
      {children({
        notification,
        clickOnNotification,
        icon: getProperIcon(notification.category),
      })}

      {!!serviceOrderId && (
        <ChangeServiceStatusModal
          isOpen={!!serviceOrderId}
          onClose={() => setServiceOrderId('')}
          orderId={serviceOrderId}
        />
      )}
      {!!requestAssignUser && (
        <UserRequestToAssignModal
          isOpen={!!requestAssignUser}
          onClose={() => setRequestAssignUser(null)}
          userId={requestAssignUser?.id}
          role={requestAssignUser?.role}
        />
      )}
      {!!(companyId && userId) && (
        <ChangeCompanyStatusModal
          isOpen={!!(companyId && userId)}
          onClose={() => {
            setCompanyId('');
            setUserId('');
          }}
          companyId={companyId}
          userId={userId}
        />
      )}
      {!!verifiedCompanyId && (
        <ChangeCompanyStatusModal
          isOpen={!!verifiedCompanyId}
          onClose={() => {
            setVerifiedCompanyId('');
          }}
          companyId={verifiedCompanyId}
        />
      )}
      {isContactFormPreviewOpen && (
        <LandingPageContactFormPreview
          isOpen={isContactFormPreviewOpen}
          onClose={() => {
            setIsContactFormPreviewOpen(false);
          }}
        />
      )}
      {!!npLaborId && (
        <CreateCompanyLaborModal
          isOpen={!!npLaborId}
          laborId={npLaborId}
          onClose={() => {
            setNpLaborId('');
          }}
        />
      )}
    </>
  );
};

export default NotificationWrapper;

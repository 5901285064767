import { useAppSelector } from 'hooks/redux';
import { Redirect, Route } from 'react-router-dom';

import { RoleCheck } from '@loop/constants/user-roles';

interface Props extends React.ComponentProps<typeof Route> {
  roles?: RoleCheck;
}

function ProtectedRoute({ children, roles = [], ...rest }: Props) {
  const token = useAppSelector(
    (state) => state.authModule.auth.tokens.accessToken
  );
  const activeRoles = useAppSelector(
    (state) => state.authModule.auth.decodedToken?.roles
  );
  const isRoleHasAccess =
    !roles?.length || activeRoles?.some((item) => roles.includes(item));

  return (
    <Route {...rest}>
      {!!token ? (
        isRoleHasAccess ? (
          children
        ) : (
          <Redirect to="/error404" />
        )
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
}

export default ProtectedRoute;

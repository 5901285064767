import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Divider,
  Icon,
  MenuItem,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { useUpdateNotificationStatusMutation } from '@loop/api/common/notifications';
import { ReactComponent as CheckIcon } from '@loop/assets/img/icons/check.svg';
import DateText from '@loop/components/common/text/date-text';
import { useAppDispatch } from '@loop/hooks/redux';
import { decreaseNotificationsCount } from '@loop/store/slices/notification-slice';
import { Notification } from '@loop/types/notifications';

interface Props {
  notification: Notification;
  clickOnNotification: (notification: Notification) => void;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const IconStyled = styled(Icon)`
  color: var(--chakra-colors-primary-600);
  & path {
    fill: var(--chakra-colors-primary-600);
  }
`;

const PopupNotification = ({
  notification,
  clickOnNotification,
  icon,
}: Props) => {
  const { t } = useTranslation();
  const [updateNotificationStatus, { isLoading }] =
    useUpdateNotificationStatusMutation();
  const dispatch = useAppDispatch();

  return (
    <>
      <MenuItem
        alignItems="center"
        maxWidth="400px"
        p={3}
        pr={2}
        _hover={{ bgColor: 'grey.100' }}
        cursor="pointer"
        justifyContent="space-between"
        onClick={() => {
          clickOnNotification(notification);
        }}
      >
        <IconStyled as={icon} fontSize={20} ml={2} mr={4} />
        <Box flexGrow={2}>
          <Text wordBreak="break-word">{notification.message}</Text>
          <Text color="grey.250" fontSize={13}>
            <DateText value={notification.createdAt} fontSize={13} as="span" />{' '}
            <Text as="span" px={1}>
              |
            </Text>{' '}
            {notification.type}
          </Text>
        </Box>
        <Tooltip label={t('notifications:Mark as read')} hasArrow>
          <Button
            variant="ghost"
            p="0"
            _hover={{ bgColor: 'primary.200' }}
            borderRadius="50%"
            onClick={async (e) => {
              e.stopPropagation();
              await updateNotificationStatus({
                id: notification.id,
                isRead: !notification.isRead,
              });
              dispatch(decreaseNotificationsCount());
            }}
            isLoading={isLoading}
          >
            <Icon
              as={CheckIcon}
              stroke="primary.600"
              borderRadius="50%"
              fontSize={20}
              p={1}
              w={7}
              h={7}
              marginX={2}
            />
          </Button>
        </Tooltip>
      </MenuItem>
      <Divider />
    </>
  );
};

export default PopupNotification;

export enum STORE_MODULES {
  CORE = 'coreModule',
  AUTH = 'authModule',
  NP = 'npModule',
  DISTRIBUTOR = 'distributorModule',
  CRM = 'crmModule',
}

export enum CORE_SLICES {
  NOTIFICATIONS = 'notifications',
  UTILS = 'utils',
}

export enum AUTH_SLICES {
  AUTH = 'auth',
}

export enum CRM_SLICES {
  CRM_EMBEDDED_FORM_CONFIG = 'crmEmbeddedFormConfig',
  CRM_EMBEDDED_FORM = 'crmEmbeddedForm',
  LEADS = 'leads',
  RESIDENTIAL_LEADS = 'residentialLeads',
  COMMERCIAL_CUSTOMERS = 'commercialCustomers',
  SITES = 'installationSites',
}

export enum DISTRIBUTOR_SLICES {
  DISTRIBUTOR = 'distributor',
}

export enum NP_SLICES {
  PURCHASE_REQUEST = 'purchaseRequest',
  INVOICE = 'invoice',
  QUOTE = 'quote',
  PROPOSAL = 'proposal',
  PRODUCT = 'product',
  PROJECT = 'project',
  BID = 'bid',
}

export const STORE_PATH_CORE = {
  NOTIFICATIONS: `${STORE_MODULES.CORE}/${CORE_SLICES.NOTIFICATIONS}`,
  UTILS: `${STORE_MODULES.CORE}/${CORE_SLICES.UTILS}`,
};

export const STORE_PATH_NP = {
  PURCHASE_REQUEST: `${STORE_MODULES.NP}/${NP_SLICES.PURCHASE_REQUEST}`,
  INVOICE: `${STORE_MODULES.NP}/${NP_SLICES.INVOICE}`,
  QUOTE: `${STORE_MODULES.NP}/${NP_SLICES.QUOTE}`,
  PROPOSAL: `${STORE_MODULES.NP}/${NP_SLICES.PROPOSAL}`,
  PRODUCT: `${STORE_MODULES.NP}/${NP_SLICES.PRODUCT}`,
  PROJECT: `${STORE_MODULES.NP}/${NP_SLICES.PROJECT}`,
  BID: `${STORE_MODULES.NP}/${NP_SLICES.BID}`,
};

export const STORE_PATH_AUTH = {
  AUTH: `${STORE_MODULES.AUTH}/${AUTH_SLICES.AUTH}`,
};

export const STORE_PATH_DISTRIBUTOR = {
  DISTRIBUTOR: `${STORE_MODULES.DISTRIBUTOR}/${DISTRIBUTOR_SLICES.DISTRIBUTOR}`,
};

export const STORE_PATH_CRM = {
  CRM_EMBEDDED_FORM_CONFIG: `${STORE_MODULES.CRM}/${CRM_SLICES.CRM_EMBEDDED_FORM_CONFIG}`,
  CRM_EMBEDDED_FORM: `${STORE_MODULES.CRM}/${CRM_SLICES.CRM_EMBEDDED_FORM}`,
  LEADS: `${STORE_MODULES.CRM}/${CRM_SLICES.LEADS}`,
  RESIDENTIAL_LEADS: `${STORE_MODULES.CRM}/${CRM_SLICES.RESIDENTIAL_LEADS}`,
  COMMERCIAL_CUSTOMERS: `${STORE_MODULES.CRM}/${CRM_SLICES.COMMERCIAL_CUSTOMERS}`,
  SITES: `${STORE_MODULES.CRM}/${CRM_SLICES.SITES}`,
};

import { yupResolver } from '@hookform/resolvers/yup';
import {
  debounce,
  find,
  get,
  matchesProperty,
  omit,
  omitBy,
  throttle,
} from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  Box,
  Button,
  ButtonGroup,
  Table as ChakraTable,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SkeletonText,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
} from '@chakra-ui/react';

import { ReactComponent as ArrowDownIcon } from '@loop/assets/img/icons/arrow-down.svg';
import { ReactComponent as SearchIcon } from '@loop/assets/img/icons/search.svg';
import PaginatedSelect from '@loop/components/common/inputs/paginated-select';
import { RoleCheck } from '@loop/constants/user-roles';
import usePagination from '@loop/hooks/pagination';
import { SetFilters } from '@loop/hooks/paginationFilters';
import { useAppSelector } from '@loop/hooks/redux';
import {
  PaginatedData,
  PaginatedRequest,
  PaginatedRow,
} from '@loop/types/paginated-data';

import companyApi from '@loop-np/api/company';

export interface Column<T extends PaginatedRow = PaginatedRow> {
  isNumeric?: boolean;
  header: React.ReactNode;
  render?: (row: T) => React.ReactNode;
  path: string;
  align?: 'left' | 'right' | 'center';
  maxWidth?: string;
  isSortable?: boolean;
  roles?: RoleCheck;
  isFixed?: boolean;
  isHidden?: boolean;
  isNotBreakable?: boolean;
}

export interface FilterOption<
  Params = {
    [param: string]: any; // actual param(s) that will be added to the query. `undefined` to remove
  }
> {
  name: string; // name visible for users
  value: string; // unique value for iteration, NOT used for queries
  params: Params;
}

interface ITableActionsProps {
  filterOptions?: FilterOption[];
  hasCompaniesFilterForLoop: boolean;
  setFilters: SetFilters;
  filter: boolean;
  search?: boolean;
  action?: React.ReactNode | false;
  onActionClick?: () => void;
}

interface CompanyFilterForm {
  companyId: string[];
}

function TableActions({
  filterOptions,
  hasCompaniesFilterForLoop,
  setFilters,
  filter,
  search,
  action,
  onActionClick,
}: ITableActionsProps) {
  const { t } = useTranslation();
  const debounceSetSearchFor = useMemo(
    () =>
      debounce((value) => {
        setFilters(value);
      }, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const defaultValues = {
    companyId: ['all'],
  };
  const schema = () =>
    yup.object().shape({
      companyId: yup.array().nullable(),
    });

  const form = useForm<CompanyFilterForm>({
    resolver: yupResolver<yup.AnyObjectSchema>(schema()),
    mode: 'onChange',
    defaultValues,
  });

  return (
    <HStack justify="space-between" px={8} py={5}>
      {(filter || search || hasCompaniesFilterForLoop || !!action) && (
        <HStack spacing={4}>
          {filter && (
            <FormControl>
              <FormLabel>{t('Filter')}</FormLabel>
              <Select
                onChange={(e) => {
                  const value = e.target.value;
                  const newFilters = omitBy(
                    find(filterOptions, { value })?.params,
                    matchesProperty('value', undefined)
                  );
                  setFilters({ ...newFilters, offset: 0 });
                }}
                menuPortalTarget={document.body}
              >
                {filterOptions?.map((filterOption) => (
                  <option key={filterOption.value} value={filterOption.value}>
                    {filterOption.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {hasCompaniesFilterForLoop && (
            <FormControl>
              <FormLabel>{t('Filter by Company')}</FormLabel>
              <FormProvider {...form}>
                <Box as="form">
                  <PaginatedSelect
                    name="companyId"
                    additionalOptions={[{ value: 'all', label: 'all' }]}
                    defaultValues={[{ value: 'all', label: 'all' }]}
                    api={companyApi}
                    endpointName="companies"
                    queryOptions={{
                      orderBy: 'companyName',
                      orderDirection: 'asc',
                    }}
                    labelBy="companyName"
                    onChange={(value) => {
                      const newFilters = {
                        ...filterOptions,
                        companyIds: value !== 'all' ? [value] : undefined,
                      };

                      setFilters({ ...newFilters, offset: 0 });
                    }}
                  />
                </Box>
              </FormProvider>
            </FormControl>
          )}

          {!!search && (
            <FormControl>
              <FormLabel>{t('Search')}</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" zIndex="0">
                  <SearchIcon />
                </InputLeftElement>
                <Input
                  placeholder={t('Search')}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length) {
                      debounceSetSearchFor({ searchTerm: value, offset: 0 });
                    } else {
                      debounceSetSearchFor(
                        setFilters((filters) => omit(filters, 'searchTerm'))
                      );
                    }
                  }}
                />
              </InputGroup>
            </FormControl>
          )}
        </HStack>
      )}
      {action && (
        <Button colorScheme="primary" onClick={() => onActionClick?.()}>
          {action}
        </Button>
      )}
    </HStack>
  );
}

interface ITableHeadProps {
  columns: Column[];
  filters: PaginatedRequest;
  setFilters: (filters: Partial<PaginatedRequest>) => void;
}

function TableHead({ columns, filters, setFilters }: ITableHeadProps) {
  const handleSorting = (column: Column) => () => {
    if (column.isSortable === false) return;

    if (column.path === 'createdAt') {
      setFilters({
        orderBy: 'createdAt',
        orderDirection: filters.orderDirection === 'desc' ? 'asc' : 'desc',
        offset: 0,
      });
    } else if (
      filters.orderBy === column.path &&
      filters.orderDirection === 'desc'
    ) {
      setFilters({
        orderBy: 'createdAt',
        orderDirection: 'desc',
        offset: 0,
      });
    } else {
      setFilters({
        orderBy: column.path,
        orderDirection:
          filters.orderBy === column.path && filters.orderDirection === 'asc'
            ? 'desc'
            : 'asc',
        offset: 0,
      });
    }
  };

  return (
    <Thead bg="grey.100">
      <Tr>
        {columns.map((column, index) => (
          <Th
            textAlign={column.align || 'left'}
            key={`${column.path}_${index}`}
            whiteSpace="nowrap"
            position={column.isFixed ? 'sticky' : 'static'}
            right="0px"
            bg="grey.100"
            cursor={column.isSortable !== false ? 'pointer' : 'auto'}
            onClick={handleSorting(column)}
          >
            {column.header}
            <chakra.span pl="4">
              {filters.orderBy === column.path ? (
                <Icon
                  as={ArrowDownIcon}
                  transform={`rotate(${
                    filters.orderDirection === 'desc' ? 0 : 180
                  }deg)`}
                />
              ) : null}
            </chakra.span>
          </Th>
        ))}
      </Tr>
    </Thead>
  );
}

interface ITableBodyProps {
  data?: PaginatedData;
  columns: Column[];
  isLoading?: boolean;
  isFetching?: boolean;
  showShadow?: boolean;
  rowClickAction?: (id: string) => void;
  idPropertyName?: string;
}

function TableBody({
  data,
  columns,
  isLoading,
  isFetching,
  showShadow,
  rowClickAction,
  idPropertyName,
}: ITableBodyProps) {
  const getCellValue = (column: Column, row: any) => {
    const cellValue = column.render
      ? column.render(row)
      : column.path && get(row, column.path);

    return Array.isArray(cellValue) ? cellValue.join('\n') : cellValue;
  };

  return (
    <Tbody fontSize="md">
      {isLoading &&
        !data?.records &&
        [0, 1, 2].map((i) => (
          <Tr key={i}>
            {columns.map((column) => (
              <Td textAlign={column.align} key={column.path}>
                <SkeletonText noOfLines={1} />
              </Td>
            ))}
          </Tr>
        ))}
      {data?.records &&
        data.records.map((row, indexRow) => (
          <Tr
            key={`${idPropertyName ? row[idPropertyName] : row.id}_${indexRow}`}
            onClick={
              rowClickAction && idPropertyName
                ? () => rowClickAction(row[idPropertyName])
                : () => {}
            }
            cursor={rowClickAction && 'pointer'}
          >
            {columns.map((column, indexColumn) => (
              <Td
                textAlign={column.align}
                key={`${column.path}_${indexRow}_${indexColumn}`}
                color={isFetching || isLoading ? 'grey.300' : 'grey.600'}
                wordBreak={column.isNotBreakable ? 'unset' : 'break-word'}
                whiteSpace={column.isNotBreakable ? 'unset' : 'pre-line'}
                maxWidth={column.maxWidth || 'auto'}
                position={column.isFixed ? 'sticky' : 'static'}
                boxShadow={
                  showShadow && column.isFixed
                    ? 'inset 1px 0 0 0 rgba(0, 0, 0, 0.05)'
                    : ''
                }
                right="0"
                backgroundColor="white"
              >
                {getCellValue(column, row)}
              </Td>
            ))}
          </Tr>
        ))}
    </Tbody>
  );
}

interface IPaginationButtonProps
  extends React.ComponentPropsWithRef<typeof Button> {
  children: React.ReactNode;
  selected?: boolean;
}

function PaginationButton({
  children,
  selected = false,
  ...rest
}: IPaginationButtonProps) {
  return (
    <Button
      fontWeight="400"
      variant={selected ? 'solid' : 'outline'}
      colorScheme={selected ? 'primary' : 'gray'}
      {...rest}
    >
      {children}
    </Button>
  );
}

interface ITablePaginationProps {
  data?: PaginatedData;
  setFilters: (filters: Partial<PaginatedRequest>) => void;
  filters: Partial<PaginatedRequest>;
}

function TablePagination({ data, setFilters }: ITablePaginationProps) {
  const { t } = useTranslation();
  const filters = data ?? { offset: 0, limit: 10, totalRecords: 2 };
  const items = usePagination({
    ...filters,
    onChange: (offset) => setFilters({ offset }),
  });

  return (
    <HStack
      justify={
        !(data && data?.totalRecords >= 10) ? 'flex-end' : 'space-between'
      }
      px={8}
      py={5}
    >
      {data && data?.totalRecords >= 10 && (
        <HStack whiteSpace="nowrap">
          <Text color="grey.300" fontSize="xs">
            {t('Showing')}
          </Text>
          <Select
            mx={2}
            defaultValue={10}
            onChange={(e) =>
              setFilters({ limit: parseInt(e.target.value), offset: 0 })
            }
          >
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </Select>
          <Text color="grey.300" fontSize="xs">
            {t('of ') + data?.totalRecords}
          </Text>
        </HStack>
      )}

      <ButtonGroup isAttached>
        {items.map((item) => (
          <PaginationButton {...item} />
        ))}
      </ButtonGroup>
    </HStack>
  );
}

interface ITableProps {
  columns: Column<any>[];
  data?: PaginatedData;
  filters: PaginatedRequest;
  setFilters: SetFilters;
  filterOptions?: FilterOption[];
  hasCompaniesFilterForLoop?: boolean;
  filter?: boolean; // if false, hide the filter select
  search?: boolean; // if false, hide the search field
  action?: React.ReactNode | false; // if false, hide the action button
  onActionClick?: () => void;
  rowClickAction?: (id: string) => void;
  idPropertyName?: string;
  isFetching?: boolean; // if true, table will show old values greyed out
  isLoading?: boolean; // if true, table will show placeholder skeleton
}

function Table({
  columns: allColumns,
  data,
  filters,
  setFilters,
  filterOptions,
  filter = true,
  search = true,
  action,
  onActionClick,
  isFetching = false,
  isLoading = false,
  rowClickAction,
  idPropertyName,
  hasCompaniesFilterForLoop = false,
}: ITableProps) {
  const { t } = useTranslation();
  const activeRoles = useAppSelector(
    (state) => state.authModule.auth.decodedToken?.roles
  );
  const [showShadow, setShowShadow] = useState<boolean>(false);
  const columns = allColumns
    .filter((column) => {
      if (!column.roles || !activeRoles) return true;
      return !!column.roles.find((role) => activeRoles.includes(role));
    })
    .filter((column) => !column.isHidden);

  const tableRef = useRef<HTMLDivElement>(null);

  const onScroll = useMemo(
    () =>
      throttle(() => {
        if (!tableRef.current) return;
        const { scrollLeft, clientWidth, scrollWidth } = tableRef.current;
        if (scrollLeft + clientWidth >= scrollWidth) {
          setShowShadow(false);
        } else {
          setShowShadow(true);
        }
      }, 100),
    []
  );

  useEffect(() => {
    onScroll();
  }, [onScroll]);

  return (
    <Box
      bg="white"
      borderRadius={8}
      boxShadow="md"
      maxWidth="100%"
      overflow="hidden"
    >
      <TableActions
        filterOptions={filterOptions}
        setFilters={setFilters}
        filter={filter}
        hasCompaniesFilterForLoop={hasCompaniesFilterForLoop}
        search={search}
        action={action}
        onActionClick={onActionClick}
      />
      <Box overflow="auto" maxWidth="100%" ref={tableRef} onScroll={onScroll}>
        <ChakraTable>
          <TableHead
            columns={columns}
            filters={filters}
            setFilters={setFilters}
          />
          {!data?.records?.length && !isLoading && (
            <TableCaption mt="0">
              <Text color="grey.300" py="40px">
                {t('There are no items')}
              </Text>
            </TableCaption>
          )}
          <TableBody
            data={data}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching}
            showShadow={showShadow}
            rowClickAction={rowClickAction}
            idPropertyName={idPropertyName}
          />
        </ChakraTable>
      </Box>
      <TablePagination data={data} filters={filters} setFilters={setFilters} />
    </Box>
  );
}

export default Table;

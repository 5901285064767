import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useUserByIdQuery } from '@loop/api/common/user';

import { useCompanyByIdQuery } from '@loop-np/api/company';
import CompanyInfo from '@loop-np/features/settings/network-partner-companies/modals/company-info';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
  userId?: string;
}

function ChangeCompanyStatusModal({
  isOpen,
  onClose,
  companyId,
  userId,
}: Props) {
  const { t } = useTranslation();

  const {
    data: userResponse,
    isLoading: userIsLoading,
    isFetching: userIsFetching,
  } = useUserByIdQuery(
    { id: userId },
    { skip: !isOpen || !userId, refetchOnMountOrArgChange: true }
  );

  const {
    data: companyResponse,
    isLoading: companyIsLoading,
    isFetching: companyIsFetching,
  } = useCompanyByIdQuery(
    { id: companyId },
    {
      skip: !companyId,
      refetchOnMountOrArgChange: true,
    }
  );
  const isLoading = companyIsLoading || userIsLoading;
  const isFetching = companyIsFetching || userIsFetching;

  const user = userResponse
    ? {
        firstName: userResponse.firstName,
        lastName: userResponse.lastName,
        phone: userResponse.phone,
        email: userResponse.email,
      }
    : undefined;

  const userCompany = companyResponse
    ? {
        id: companyResponse.id,
        isActive: companyResponse.isActive,
        isVerified: companyResponse.isVerified,
        companyName: companyResponse.companyName,
        address: companyResponse.address,
        stateOperatingIn: companyResponse.stateOperatingIn,
        phone: companyResponse.phone,
        email: companyResponse.email,
        timeZone: companyResponse.timeZone,
      }
    : undefined;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent p={5}>
        <Box as="form">
          <ModalHeader color="grey.600" fontWeight="500" fontSize="2xl" pb={8}>
            {!!userId
              ? t('notifications:Change Company Status')
              : t('notifications:Company Details')}
          </ModalHeader>
          <ModalBody>
            <CompanyInfo
              user={user}
              company={userCompany}
              isLoading={isLoading || isFetching}
            />
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button
              onClick={() => {
                onClose();
              }}
              fontWeight="500"
              colorScheme="primary"
              h="40px"
              w="120px"
              isLoading={isFetching || isLoading}
            >
              {t('Close')}
            </Button>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default ChangeCompanyStatusModal;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { STORE_PATH_CORE } from '@loop/constants/store-constatns';

interface UtilsState {
  isLoading: boolean;
  isFormValid: boolean;
  activeModule: string;
  currentStepIndex: number;
  countOfSteps: number;
  isSidebarCollapsed: boolean;
  country: {
    value: string;
    label: string;
  };
}

const sliceName = STORE_PATH_CORE.UTILS;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as UtilsState)
  : null;

const defaultState: UtilsState = {
  isLoading: false,
  isFormValid: true,
  activeModule: '',
  currentStepIndex: 0,
  countOfSteps: 0,
  isSidebarCollapsed: window.innerWidth < 992,
  country: {
    value: 'US',
    label: 'United States',
  },
};

let initialState: UtilsState = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setIsLoadingState: (
      state,
      { payload: isLoading }: PayloadAction<boolean>
    ) => {
      state.isLoading = isLoading;
    },
    setIsFormValidState: (
      state,
      { payload: isFormValid }: PayloadAction<boolean>
    ) => {
      state.isFormValid = isFormValid;
    },
    setActiveModule: (
      state,
      { payload: activeModule }: PayloadAction<string>
    ) => {
      state.activeModule = activeModule;
    },
    resetUtilsState: () => initialState,
    setCurrentStepIndex: (
      state,
      { payload: currentStepIndex }: PayloadAction<number>
    ) => {
      state.currentStepIndex = currentStepIndex;
    },
    resetCurrentStepIndex: (state) => {
      state.currentStepIndex = 0;
    },
    setIsSidebarCollapsed: (
      state,
      { payload: isSidebarCollapsed }: PayloadAction<boolean>
    ) => {
      state.isSidebarCollapsed = isSidebarCollapsed;
    },
    increaseStepIndex: (state) => {
      state.currentStepIndex = ++state.currentStepIndex;
    },
    decreaseStepIndex: (state) => {
      state.currentStepIndex = --state.currentStepIndex;
    },
    setCountOfSteps: (
      state,
      { payload: countOfSteps }: PayloadAction<number>
    ) => {
      state.countOfSteps = countOfSteps;
    },
    resetCountOfSteps: (state) => {
      state.countOfSteps = 0;
    },
  },
});

export const {
  setIsLoadingState,
  setIsFormValidState,
  setActiveModule,
  resetUtilsState,
  setCurrentStepIndex,
  resetCurrentStepIndex,
  setIsSidebarCollapsed,
  setCountOfSteps,
  resetCountOfSteps,
  increaseStepIndex,
  decreaseStepIndex,
} = slice.actions;

export default slice.reducer;

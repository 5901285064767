import { Box, Text } from '@chakra-ui/react';

import { FIELD_TYPES } from '@loop-crm/features/settings/constants/contact-form-constants';
import ContactFormInputConsentPreview from '@loop-crm/features/settings/embedded-form-config/components/fields-preview/contact-form-input-consent-preview';
import ContactFormInputDropdownPreview from '@loop-crm/features/settings/embedded-form-config/components/fields-preview/contact-form-input-dropdown-preview';
import ContactFormInputNumberPreview from '@loop-crm/features/settings/embedded-form-config/components/fields-preview/contact-form-input-number-preview';
import ContactFormInputTextPreview from '@loop-crm/features/settings/embedded-form-config/components/fields-preview/contact-form-input-text-preview';
import ContactFormInputTextareaPreview from '@loop-crm/features/settings/embedded-form-config/components/fields-preview/contact-form-input-textarea-preview';
import ContactFormInputAddressPreview from '@loop-crm/features/settings/embedded-form-config/components/fields-preview/contact-from-input-address-preview';
import {
  ContactFormField,
  ContactFormStyles,
} from '@loop-crm/features/settings/types/embedded-form-types';

interface Props {
  fields: ContactFormField[];
  styles: ContactFormStyles;
}

const ContactFormFieldsPreview = ({ fields, styles }: Props) => {
  const getProperComponent = (field: ContactFormField) => {
    switch (field.general.type) {
      case FIELD_TYPES.INPUT_TEXT:
        return <ContactFormInputTextPreview field={field} styles={styles} />;
      case FIELD_TYPES.INPUT_EMAIL:
        return <ContactFormInputTextPreview field={field} styles={styles} />;
      case FIELD_TYPES.INPUT_PHONE:
        return <ContactFormInputTextPreview field={field} styles={styles} />;
      case FIELD_TYPES.INPUT_ADDRESS:
        return <ContactFormInputAddressPreview field={field} styles={styles} />;
      case FIELD_TYPES.DROPDOWN_LIST:
        return (
          <ContactFormInputDropdownPreview field={field} styles={styles} />
        );
      case FIELD_TYPES.NUMBER_INPUT:
        return <ContactFormInputNumberPreview field={field} styles={styles} />;
      case FIELD_TYPES.TEXT_AREA:
        return (
          <ContactFormInputTextareaPreview field={field} styles={styles} />
        );
      case FIELD_TYPES.AGREEMENT_CONSENT:
        return <ContactFormInputConsentPreview field={field} styles={styles} />;
      default:
        return <Text>{field.general.type}</Text>;
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <Box key={field.general.modelId}>{getProperComponent(field)}</Box>
      ))}
    </>
  );
};

export default ContactFormFieldsPreview;

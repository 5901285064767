const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  aliensUrl: process.env.REACT_APP_ALIENS_URL,
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  currencyLayerId: process.env.REACT_APP_CURRENCY_LAYER_ID,
  clientApp: process.env.REACT_APP_CLIENT_APP,
  googleKey: process.env.REACT_APP_GOOGLE_SECRET_KEY,
  preferredCurrency: 'USD', // TODO: change this to actual preferred currency
} as const;

export const currencies = [
  {
    code: 'USD',
    symbol: '$',
    label: 'USD - $',
  },
  {
    code: 'EUR',
    symbol: '€',
    label: 'EUR - €',
  },
] as const;

export type CurrencyCode = typeof currencies[number]['code'];

export default config;

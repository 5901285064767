import api from '@loop/api/api';
import { BASE_URL } from '@loop/config/constants';

import { ContactForm } from '@loop-crm/features/settings/types/embedded-form-types';

const leadContactFormApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEmbeddedForm: build.query<ContactForm, void>({
      query: () => ({
        url: `${BASE_URL.EMBEDDED_FORM}/admin`,
        method: 'GET',
      }),
      providesTags: ['LeadContactForm'],
    }),
    getEmbeddedFormNP: build.query<ContactForm, void>({
      query: () => ({
        url: `${BASE_URL.EMBEDDED_FORM}/company-style`,
        method: 'GET',
      }),
      providesTags: ['LeadContactForm'],
    }),
    updateEmbeddedFormStructure: build.mutation<void, ContactForm>({
      query: (data: ContactForm) => ({
        url: `${BASE_URL.EMBEDDED_FORM}/admin/update`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['LeadContactForm'],
    }),
  }),
});

export const {
  useGetEmbeddedFormQuery,
  useGetEmbeddedFormNPQuery,
  useUpdateEmbeddedFormStructureMutation,
} = leadContactFormApi;

export default leadContactFormApi;

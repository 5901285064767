import { TFunction } from 'i18next';
import * as yup from 'yup';

import { yupMoney } from '@loop/utils/yup-schemas';

const companyLaborCreateSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(t('Labor Name is Required'))
      .max(255, t('settings:Labor Name is too long')),
    unitType: yup.string().required(t('settings:Unit Type is Required')),
    price: yupMoney(t('Price is Required')),
  });

export default companyLaborCreateSchema;

import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { UserInfo } from '@loop-np/api/services';
import UserDetails from '@loop-np/features/services/components/user-details';
import CompanyInfo from '@loop-np/features/services/components/сompany-info';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
  userInfo: UserInfo | null;
}

function ServiceRequestDetailsModal({
  isOpen,
  onClose,
  companyId,
  userInfo,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      preserveScrollBarGap
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent p={5} pr="0">
        <ModalHeader color="grey.600" fontWeight="500" fontSize="2xl" pb={4}>
          {t('services:Service Request Details')}
        </ModalHeader>
        <Box as="form" overflowY="auto">
          <ModalBody pr="10">
            <Text mb="2" fontSize="xl" fontWeight="500">
              {t('services:Company Details')}
            </Text>
            <CompanyInfo companyId={companyId} />
            <Text mb="2" fontSize="xl" fontWeight="500">
              {t('services:User Details')}
            </Text>
            <UserDetails userInfo={userInfo} />
          </ModalBody>
        </Box>
        <ModalFooter justifyContent="flex-end">
          <Button
            onClick={() => {
              onClose();
            }}
            fontWeight="500"
            colorScheme="primary"
            h="40px"
            w="120px"
          >
            {t('Close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ServiceRequestDetailsModal;

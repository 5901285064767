import { createSlice } from '@reduxjs/toolkit';

import { CurrencyCode } from '@loop/config/config';
import { STORE_PATH_NP } from '@loop/constants/store-constatns';
import { IMoney } from '@loop/utils/money';

import { SortedAssumptions } from '@loop-np/hooks/use-sort-assumptions';
import {
  ProposalErrors,
  ProposalProjectSummaryResponse,
  ProposalScopeOfWork, //   ProposalAssumptions,
  //   ProposalProjectSummaryResponse,
  //   ProposalRecommendedScopeOfWork,
  ProposalScopeOfWorkRequest,
  ProposalSiteInformation,
  ProposalState,
} from '@loop-np/types/proposal-types';

const defaultState: ProposalState = {
  country: undefined,
  currency: undefined,
  siteInformation: null,
  errors: undefined,
  scopeOfWork: null,
  proposalSummary: null,
};

const sliceName = STORE_PATH_NP.PROPOSAL;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as ProposalState)
  : defaultState;

let initialState: ProposalState = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveCompanyId: (state, action: { payload: string }) => ({
      ...state,
      companyId: action.payload,
    }),
    saveProposalId: (state, action: { payload: string }) => ({
      ...state,
      proposalId: action.payload,
    }),
    saveProposalLeadId: (
      state,
      action: { payload: string | undefined | null }
    ) => ({
      ...state,
      leadId: action.payload,
    }),
    saveProposalDraftId: (state, action: { payload: string }) => ({
      ...state,
      draftProposalId: action.payload,
    }),
    setProposalState: (_, action: { payload: ProposalState }) => action.payload,

    saveProposalSiteInformation: (
      state,
      action: { payload: ProposalSiteInformation }
    ) => {
      state.siteInformation = {
        ...action.payload,
      };
    },
    saveProposalCountry: (
      state,
      action: {
        payload: { country: string; currency: CurrencyCode };
      }
    ) => ({
      ...state,
      ...action.payload,
    }),
    saveProposalScopeOfWork: (
      state,
      action: { payload: ProposalScopeOfWork }
    ) => {
      state.scopeOfWork = {
        ...action.payload,
      };
    },
    saveIncludedTaxIncentives: (state, action: { payload: boolean }) => {
      if (state.scopeOfWork) {
        state.scopeOfWork.assumptions.includedTaxIncentives = action.payload;
      }
    },
    saveAlternativeVehicleFuelTaxQualified: (
      state,
      action: { payload: boolean | undefined }
    ) => {
      if (state.scopeOfWork) {
        state.scopeOfWork.assumptions.alternativeVehicleFuelTaxQualified =
          action.payload;
      }
    },
    saveProposalSummary: (
      state,
      action: { payload: ProposalProjectSummaryResponse }
    ) => {
      state.proposalSummary = {
        ...action.payload,
      };
    },
    editProposal: (state, action: { payload: ProposalState }) => ({
      ...state,
      ...action.payload,
    }),
    resetProposalState: () => {
      initialState = defaultState;
      return defaultState;
    },
    setProposalFromLeadState: (state, action: { payload: any }) => ({
      ...state,
      companyId: state.companyId,
      leadId: state.leadId,
      ...action.payload,
    }),
    saveProposalErrors: (state, action: { payload: ProposalErrors }) => ({
      ...state,
      errors: action.payload,
    }),
    resetProposalScopeOfWorkAndSummary: (state) => ({
      ...state,
      scopeOfWork: null,
      proposalSummary: null,
    }),
    resetProposalSummary: (state) => ({
      ...state,
      proposalSummary: null,
    }),
  },
});

export const {
  saveCompanyId,
  saveProposalId,
  saveProposalDraftId,
  saveProposalLeadId,
  setProposalState,
  saveIncludedTaxIncentives,
  saveProposalSiteInformation,
  saveProposalCountry,
  saveProposalScopeOfWork,
  resetProposalState,
  setProposalFromLeadState,
  saveProposalSummary,
  editProposal,
  saveProposalErrors,
  saveAlternativeVehicleFuelTaxQualified,
  resetProposalScopeOfWorkAndSummary,
  resetProposalSummary,
} = slice.actions;

export default slice.reducer;

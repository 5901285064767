import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import { Tokens } from '@loop/api/common/user';
import { STORE_PATH_AUTH } from '@loop/constants/store-constatns';
import { UserRole } from '@loop/constants/user-roles';

export interface DecodedToken {
  userId: string;
  companyId?: string;
  organizationId?: string;
  distributorId?: string;
  roles: UserRole[] | null;
  type: 'access' | 'refresh';
  iat: number;
  exp: number;
}

export interface AuthState {
  tokens: Tokens;
  decodedToken: DecodedToken | null;
}

const sliceName = STORE_PATH_AUTH.AUTH;

const savedTokens = localStorage.getItem(sliceName);
const parsedTokens = savedTokens
  ? (JSON.parse(savedTokens) as AuthState)
  : null;
const decodedSavedToken = parsedTokens?.tokens?.accessToken
  ? (jwtDecode(parsedTokens.tokens.accessToken) as DecodedToken)
  : null;

const noTokens = {
  accessToken: null,
  refreshToken: null,
};

const initialState: AuthState =
  parsedTokens && decodedSavedToken
    ? { tokens: parsedTokens.tokens, decodedToken: decodedSavedToken }
    : { tokens: noTokens, decodedToken: null };

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { tokens } }: PayloadAction<{ tokens: Tokens }>
    ) => {
      const decodedToken = tokens.accessToken
        ? (jwtDecode(tokens.accessToken) as DecodedToken)
        : null;
      state.tokens = tokens;
      state.decodedToken = decodedToken;
    },
    resetAllState: (state) => {
      state.tokens = noTokens;
      state.decodedToken = null;
    },
  },
});

export const { setCredentials, resetAllState } = slice.actions;

export default slice.reducer;

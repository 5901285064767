import { EndpointDefinitions } from '@reduxjs/toolkit/dist/query';

import api from '@loop/api/api';
import store from '@loop/store/store';

function getQueryCacheEntries<
  Endpoints extends keyof EndpointDefinitions = string
>(query?: Endpoints) {
  const { queries } = store.getState()[api.reducerPath];
  let allQueries = [];
  for (let queryCache of Object.values(queries)) {
    if (!query || queryCache?.endpointName === query) {
      allQueries.push(queryCache);
    }
  }
  return allQueries;
}

export default getQueryCacheEntries;

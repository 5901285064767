import { combineReducers } from '@reduxjs/toolkit';

import { NP_SLICES } from '@loop/constants/store-constatns';

import bidReducer from '@loop-np/store/slices/bid-slice';
import invoiceReducer from '@loop-np/store/slices/invoice-slice';
import productReducer from '@loop-np/store/slices/product-slice';
import projectReducer from '@loop-np/store/slices/project-slice';
import proposalReducer from '@loop-np/store/slices/proposal-slice';
import purchaseRequestReducer from '@loop-np/store/slices/purchase-request-slice';
import quoteReducer from '@loop-np/store/slices/quote-slice';

export const npFeatureReducer = combineReducers({
  [NP_SLICES.PURCHASE_REQUEST]: purchaseRequestReducer,
  [NP_SLICES.INVOICE]: invoiceReducer,
  [NP_SLICES.QUOTE]: quoteReducer,
  [NP_SLICES.PROPOSAL]: proposalReducer,
  [NP_SLICES.PRODUCT]: productReducer,
  [NP_SLICES.PROJECT]: projectReducer,
  [NP_SLICES.BID]: bidReducer,
})!;

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { debounce, find, isEqual, omit } from 'lodash';
import { CSSProperties, useContext, useEffect, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { StylesConfig } from 'react-select/src/styles';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  SkeletonText,
  Text,
} from '@chakra-ui/react';

import { EditFormContext } from '@loop/components/common/inputs/editable-form';
import usePaginationFilters from '@loop/hooks/paginationFilters';
import useSelectStyles from '@loop/hooks/use-select-styles';
import {
  PaginatedData,
  PaginatedRequest,
  PaginatedRow,
} from '@loop/types/paginated-data';
import getQueryCacheEntries from '@loop/utils/get-query-cache-entries';

interface Option {
  value: string;
  label: string;
}

interface Props<T extends PaginatedRow = any> {
  name: string;
  label?: React.ReactNode;
  api: any;
  endpointName: string;
  queryOptions?: Partial<PaginatedRequest>;
  skip?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  controlProps?: FormControlProps;
  labelBy: keyof T;
  valueBy?: string | string[];
  isMulti?: boolean;
  defaultValues?: Option[];
  additionalOptions?: Option[];
  onChange?: (value: string | string[]) => void;
  canCreateOnFly?: boolean;
  needAllValues?: boolean;
  skeletonValue?: string;
  makeLabel?: (option: any) => string;
  styles?: CSSProperties;
  isClearable?: boolean;
  isSearchTermOmitted?: boolean;
  showInPortal?: boolean;
  skipFiltersField?: string;
  getCustomStyles?: (values: {
    styles?: CSSProperties;
    error?: string;
  }) => StylesConfig<any, boolean, any>;
}

function PaginatedSelect<T extends PaginatedRow = any>({
  name,
  label,
  api,
  endpointName,
  queryOptions,
  skip = false,
  isRequired,
  controlProps,
  labelBy,
  valueBy = 'id',
  isMulti,
  defaultValues,
  isDisabled = false,
  additionalOptions = [],
  needAllValues = false,
  onChange,
  skeletonValue,
  makeLabel,
  styles,
  isClearable,
  isSearchTermOmitted = false,
  showInPortal = true,
  skipFiltersField,
  getCustomStyles,
}: Props<T>) {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({ name });
  const form = useFormContext();
  const { editable } = useContext(EditFormContext);
  const filterLimit = 10;

  const { getSelectStyles } = useSelectStyles();

  const [filters, setFilters] = usePaginationFilters({
    limit: filterLimit,
    offset: 0,
    ...queryOptions,
  });

  const queryOptionsString = JSON.stringify(
    isSearchTermOmitted ? omit(queryOptions, ['searchTerm']) : queryOptions
  );

  useEffect(() => {
    setFilters(() => {
      return {
        offset: 0,
        limit: filterLimit,
        ...omit(queryOptions, isSearchTermOmitted ? ['searchTerm'] : ''),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryOptionsString]);

  const skipByFilterField = skipFiltersField
    ? !filters?.[skipFiltersField]
    : false;
  const { data, isLoading, isFetching, refetch } = api.endpoints[
    endpointName
  ].useQuery(skip || skipByFilterField ? skipToken : filters, {
    skip,
    subscribe: false,
    forceRefetch: true,
  });

  useEffect(() => {
    if (skip) {
      return;
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapCache = (entry: any) =>
    (entry?.data as PaginatedData)?.records.map((option) => ({
      value:
        typeof valueBy === 'string'
          ? option[valueBy]
          : valueBy.reduce(
              (acc, value) => ({ ...acc, [value]: option[value] }),
              {}
            ),
      label: !!makeLabel ? makeLabel(option) : option[labelBy],
    }));

  const allCache = [
    ...(defaultValues || []),
    ...getQueryCacheEntries(endpointName).flatMap(mapCache),
  ];

  const cache = getQueryCacheEntries(endpointName)
    .filter(
      (entry) =>
        entry?.status === 'fulfilled' &&
        isEqual(
          omit(entry?.originalArgs as PaginatedRequest, 'offset'),
          omit(filters, 'offset')
        )
    )
    .slice()
    .sort(
      (a, b) =>
        (a?.data as PaginatedData)?.offset - (b?.data as PaginatedData)?.offset
    )
    .flatMap(mapCache);

  const debouncedInputChange = useMemo(
    () =>
      debounce((value: string) => {
        if (value.length) {
          setFilters({ offset: 0, searchTerm: value });
        } else {
          setFilters(({ searchTerm, offset, ...filters }) => ({
            ...filters,
            offset: 0,
          }));
        }
      }, 300),
    [setFilters]
  );

  const mapFieldValue = (value: any) => ({
    value,
    label:
      find(defaultValues, { value })?.label || find(allCache, { value })?.label,
  });

  const formValue = form.watch(name);

  const customStyles =
    getCustomStyles?.({ styles, error: error?.message }) ||
    getSelectStyles({ styles, error: error?.message });

  return (
    <FormControl
      display="inline-block"
      position="relative"
      isInvalid={invalid}
      isRequired={isRequired}
      {...controlProps}
    >
      {label && (
        <FormLabel fontWeight={editable ? 400 : 500}>{label}</FormLabel>
      )}
      {editable ? (
        <Select
          isMulti={isMulti}
          isClearable={isClearable}
          isInvalid={error?.message}
          cacheOptions
          options={[...additionalOptions, ...cache]}
          isLoading={isLoading || isFetching}
          isDisabled={isDisabled}
          onInputChange={debouncedInputChange}
          onChange={(values) => {
            const newValues =
              values && 'value' in values
                ? values?.value
                : values?.map(({ value }: { value: string }) => value);
            field.onChange(newValues || '');
            onChange?.(needAllValues ? values : newValues);
          }}
          filterOption={() => true}
          value={
            Array.isArray(field.value)
              ? field.value?.map(mapFieldValue)
              : mapFieldValue(field.value)
          }
          menuPortalTarget={showInPortal ? document.body : null}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={customStyles}
          onMenuScrollToBottom={() =>
            setFilters((filters) => {
              if (
                data &&
                !isLoading &&
                !isFetching &&
                filters?.offset !== undefined &&
                filters?.limit !== undefined &&
                filters.offset + filters.limit < data.totalRecords
              ) {
                return {
                  ...filters,
                  offset: filters.offset + filterLimit,
                };
              } else return filters;
            })
          }
        />
      ) : (
        <SkeletonText isLoaded={!isLoading} noOfLines={1}>
          <Text>{skeletonValue || formValue}</Text>
        </SkeletonText>
      )}

      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

export default PaginatedSelect;

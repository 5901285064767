import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';

import {
  useAssignUserToCompanyByUserIdMutation,
  useCheckIsAssignedRequestProcessedQuery,
  useDeclineUserRequestMutation,
  useUnassignedUserByIdQuery,
} from '@loop/api/common/user';
import SelectField from '@loop/components/common/inputs/select-field';
import { NpUser, USER_GROUPS } from '@loop/constants/user-roles';
import assignUserSchema from '@loop/pages/notifications/schemes/assign-user-schema';

import UserInfo from '@loop-np/features/settings/network-partner-companies/modals/user-info';

interface RoleForm {
  role: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userId?: string;
  role?: NpUser;
}

function UserRequestToAssignModal({ isOpen, onClose, userId }: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const [assignUserToCompany, { isLoading: isUserAssigning }] =
    useAssignUserToCompanyByUserIdMutation();
  const [declineUser, { isLoading: isUserDeclining }] =
    useDeclineUserRequestMutation();

  const { data } = useCheckIsAssignedRequestProcessedQuery(userId || '', {
    skip: !userId,
    refetchOnMountOrArgChange: true,
  });

  const defaultValues = {
    role: '',
  };

  const form = useForm<RoleForm>({
    resolver: yupResolver<yup.AnyObjectSchema>(assignUserSchema(t)),
    mode: 'onChange',
    defaultValues,
  });

  const closeHandler = () => {
    form.reset(defaultValues);
    onClose();
  };

  const {
    data: userResponse,
    isLoading: userIsLoading,
    isFetching: userIsFetching,
  } = useUnassignedUserByIdQuery(
    { id: userId },
    { skip: !isOpen || !userId, refetchOnMountOrArgChange: true }
  );

  const isLoading = userIsLoading;
  const isFetching = userIsFetching;

  const user = userResponse
    ? {
        firstName: userResponse.firstName,
        lastName: userResponse.lastName,
        phone: userResponse.phone,
        email: userResponse.email,
      }
    : undefined;

  const isUserAlreadyAssigned = !!userResponse?.companyId;
  const isAssignedRequestProcessed = data?.isAssignedRequestProcessed;
  const isModalWithoutControlButtons =
    isUserAlreadyAssigned || isAssignedRequestProcessed;

  const acceptHandler = async (values: RoleForm) => {
    if (!userId || !values.role) return;

    await assignUserToCompany({ userId, role: values.role as NpUser }).unwrap();
    toast({
      status: 'success',
      description: t('User assigned successfully'),
    });
    closeHandler();
  };

  const declineHandler = async () => {
    if (!userId) return;

    await declineUser(userId).unwrap();
    toast({
      status: 'success',
      description: t('User declined successfully'),
    });
    closeHandler();
  };

  const getMessage = () => {
    switch (true) {
      case !isModalWithoutControlButtons:
        return t(
          'notifications:Do you really want to assign this User to your company?'
        );
      case isUserAlreadyAssigned:
        return t('notifications:This user already assigned to the company');
      case isAssignedRequestProcessed:
        return t('notifications:You have already declined this request');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered size="2xl">
      <FormProvider {...form}>
        <ModalOverlay />
        <ModalContent p={5}>
          <Box as="form" onSubmit={form.handleSubmit(acceptHandler)}>
            <ModalHeader
              color="grey.600"
              fontWeight="500"
              fontSize="2xl"
              pb={6}
            >
              {t('notifications:Request assigning to your company')}
            </ModalHeader>
            <ModalBody>
              <UserInfo user={user} isLoading={isLoading || isFetching} />
              {!isModalWithoutControlButtons && (
                <Flex w="48%">
                  <SelectField
                    name={'role'}
                    label={t('notifications:Role')}
                    isRequired
                    controlProps={{ mb: '6' }}
                    options={[
                      ...USER_GROUPS.NP.map((role) => ({
                        value: role,
                        label: role,
                      })),
                    ]}
                  />
                </Flex>
              )}

              <Text
                fontSize="lg"
                color={isModalWithoutControlButtons ? 'gray.400' : 'grey.600'}
              >
                {getMessage()}
              </Text>
            </ModalBody>

            <ModalFooter
              justifyContent={
                isModalWithoutControlButtons ? 'flex-end' : 'space-between'
              }
            >
              <Button
                onClick={() => {
                  closeHandler();
                  if (!isModalWithoutControlButtons) {
                    declineHandler();
                  }
                }}
                variant={isModalWithoutControlButtons ? 'ghost' : 'solid'}
                fontWeight="500"
                bg={isModalWithoutControlButtons ? 'grey.100' : 'red.500'}
                colorScheme={isModalWithoutControlButtons ? 'grey' : 'red'}
                h="40px"
                w="120px"
                isDisabled={isFetching || isLoading || isUserDeclining}
                isLoading={isUserDeclining}
              >
                {isModalWithoutControlButtons ? t('Close') : t('Decline')}
              </Button>

              {!isModalWithoutControlButtons && (
                <Button
                  type="submit"
                  fontWeight="500"
                  colorScheme="primary"
                  h="40px"
                  w="120px"
                  isDisabled={
                    isFetching ||
                    isLoading ||
                    isUserDeclining ||
                    !form.formState.isValid
                  }
                  isLoading={isFetching || isLoading || isUserAssigning}
                >
                  {t('Yes')}
                </Button>
              )}
            </ModalFooter>
          </Box>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
}

export default UserRequestToAssignModal;

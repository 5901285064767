import { createSlice } from '@reduxjs/toolkit';
import { remove } from 'lodash';

import { STORE_PATH_CRM } from '@loop/constants/store-constatns';

import {
  ContactForm,
  ContactFormField,
  ContactFormStructure,
  ContactFormStyles,
} from '@loop-crm/features/settings/types/embedded-form-types';

const sliceName = STORE_PATH_CRM.CRM_EMBEDDED_FORM;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as ContactForm)
  : null;

const defaultState = {
  title: 'Contact Us',
  fields: [],
  styles: {},
};

let initialState: ContactForm = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveContactForm: (state, action: { payload: ContactForm }) => {
      return action.payload;
    },
    saveContactFormStructure: (
      state,
      action: { payload: ContactFormStructure }
    ) => {
      const { title, fields } = action.payload;
      state.title = title;
      state.fields = fields;
    },
    saveContactFormStyles: (state, action: { payload: ContactFormStyles }) => {
      state.styles = action.payload;
    },
    addNewContactFormField: (state, action: { payload: ContactFormField }) => {
      state.fields.push(action.payload);
    },
    removeContactFormField: (state, action: { payload: string }) => {
      remove(state.fields, (field) => field.general.modelId === action.payload);
    },
  },
});

export const {
  saveContactForm,
  saveContactFormStructure,
  saveContactFormStyles,
  addNewContactFormField,
  removeContactFormField,
} = slice.actions;

export default slice.reducer;

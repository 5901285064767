import { LEAD_TYPE } from '@loop/config/constants';

export const ROUTES = {
  LOOP: {
    BASE_PATH: 'loop',
    DASHBOARD: 'dashboard',
    SETTINGS: {
      BASE_PATH: 'settings',
      USER_PROFILE: 'loop-user-profile',
      TERMS_AND_CONDITIONS: 'terms-and-conditions',
      USER_ACCOUNTS: 'user-accounts',
      LOOP_ADMIN_USERS: 'loop-admin-users',
      LOOP_USER_PROFILE: 'loop-user-profile',
      LOOP_COMPANY_INFORMATION: 'loop-company-information',
      NOTIFICATIONS: 'notifications',
    },
    NOTIFICATIONS: 'notifications',
  },
  NP: {
    BASE_PATH: 'network-partner',
    DASHBOARD: 'dashboard',
    INVOICES: {
      BASE_PATH: 'invoices',
      NEW: 'new',
      EDIT: 'edit',
      PREVIEW: 'preview',
    },
    QUOTES: {
      BASE_PATH: 'quotes',
      NEW: 'new',
      EDIT: 'edit',
      PREVIEW: 'preview',
    },
    PURCHASE_ORDERS: {
      BASE_PATH: 'purchase-orders',
      NEW: 'new',
      PREVIEW: 'preview',
    },
    SERVICES: {
      BASE_PATH: 'services',
    },
    PROPOSALS: {
      BASE_PATH: 'proposals',
      VERSIONS: 'versions',
      NEW: 'new',
      EDIT: 'edit',
      PREVIEW: 'preview',
    },
    PROJECTS: {
      BASE_PATH: 'projects',
      COMPANY_PROJECTS: 'my-company-projects',
      BIDDING_PROJECTS: 'my-bidding-projects',
      NEW: 'new',
      EDIT: 'edit',
      PREVIEW: 'preview',
      BIDDED_PROJECT_PREVIEW: 'preview-bided-project',
      BIDDED_PROJECT_ATTACHMENTS_PREVIEW:
        'preview-bided-project/manage-attachements',
      PROJECT_PREVIEW: 'my-project-preview',
      COMPANY_PROJECT_PREVIEW: 'my-company-project/preview',
      BIDDING_PROJECT_PREVIEW: 'biding-projects/preview',
      MY_PROJECTS: 'my-projects',
      BID: {
        BASE_PATH: 'bid',
        EDIT: 'edit',
      },
    },
    SETTINGS: {
      BASE_PATH: 'settings',
      EDIT: 'edit',
      CREATE: 'create',
      USER_PROFILE: 'user-profile',
      COMPANY_USERS: 'company-users',
      COMPANY_PRODUCTS: 'company-products',
      COMPANY_LABORS: 'company-labors',
      COMPANY_INFORMATION: 'company-information',
      NP_PRODUCTS: 'np-products',
      PRODUCTS: 'products',
      NETWORK_PARTNERS: 'network-partners',
      PREVIEW_SPEC: 'preview-spec',
      LABOR_ITEMS: {
        BASE_PATH: 'labor-items',
        CUSTOM: 'custom',
        DEFAULT: 'default',
      },
      LANDING_PAGE: 'landing-page',
      PROPOSAL_ASSUMPTION: 'proposal-assumption',
      DISTRIBUTORS: 'distributors',
      NOTIFICATIONS: 'notifications',
      NP_CATALOG: 'np-catalog',
      LOOP_CATALOG: 'loop-catalog',
    },
    MARKETING: 'marketing',
    MARKETING_IMAGES: 'marketing-images',
    VIDEOS: 'videos',
    TRAINING_IMAGES: 'training-images',
    DOCUMENTS: 'documents',
    FAQ: 'faq',
    NOTIFICATIONS: 'notifications',
  },
  DISTRIBUTOR: {
    BASE_PATH: 'distributor',
    DASHBOARD: 'dashboard',
    DISTRIBUTORS: {
      BASE_PATH: 'distributors',
    },
    ORGANIZATIONS: {
      BASE_PATH: 'organizations',
      PREVIEW: 'preview',
    },
    WAREHOUSES: 'warehouses',
    ORDERS: 'orders',
    NOTIFICATIONS: 'notifications',
  },
  CRM: {
    BASE_PATH: 'crm',
    DASHBOARD: 'dashboard',
    LEADS: {
      BASE_PATH: 'leads',
      PREVIEW: 'preview',
      COMMERCIAL: 'commercial',
      RESIDENTIAL: 'residential',
    },
    CUSTOMERS: {
      BASE_PATH: 'customers',
      PREVIEW: 'preview',
      COMMERCIAL: 'commercial',
      RESIDENTIAL: 'residential',
    },
    SITES: {
      BASE_PATH: 'sites',
      PREVIEW: 'preview',
    },
    SETTINGS: {
      BASE_PATH: 'settings',
      ACTIVITY_CONFIG: 'activity-configuration',
    },
    NOTIFICATIONS: 'notifications',
  },
  AUTHENTICATE: 'authenticate',
};

export const FEATURES_ROUTES = {
  DISTRIBUTOR: '/distributor',
  NP: '/network-partner',
  LOOP: '/loop',
  CRM: '/crm',
};

export const LOOP_ROUTES = {
  BASE_PATH: FEATURES_ROUTES.LOOP,
  DASHBOARD: `${FEATURES_ROUTES.LOOP}/dashboard`,
  SETTINGS: `${FEATURES_ROUTES.LOOP}/settings`,
  NOTIFICATIONS: `${FEATURES_ROUTES.LOOP}/notifications`,
};

export const NP_ROUTES = {
  BASE_PATH: FEATURES_ROUTES.NP,
  DASHBOARD: `${FEATURES_ROUTES.NP}/dashboard`,
  INVOICES: `${FEATURES_ROUTES.NP}/invoices`,
  QUOTES: `${FEATURES_ROUTES.NP}/quotes`,
  PURCHASE_ORDERS: `${FEATURES_ROUTES.NP}/purchase-orders`,
  SERVICES: `${FEATURES_ROUTES.NP}/services`,
  PROPOSALS: `${FEATURES_ROUTES.NP}/proposals`,
  PROJECTS: `${FEATURES_ROUTES.NP}/projects`,
  SETTINGS: `${FEATURES_ROUTES.NP}/settings`,
  MARKETING: `${FEATURES_ROUTES.NP}/marketing`,
  MARKETING_IMAGES: `${FEATURES_ROUTES.NP}/marketing-images`,
  VIDEOS: `${FEATURES_ROUTES.NP}/videos`,
  TRAINING_IMAGES: `${FEATURES_ROUTES.NP}/training-images`,
  DOCUMENTS: `${FEATURES_ROUTES.NP}/documents`,
  FAQ: `${FEATURES_ROUTES.NP}/faq`,
  NOTIFICATIONS: `${FEATURES_ROUTES.NP}/notifications`,
};

export const CRM_ROUTES = {
  BASE_PATH: FEATURES_ROUTES.CRM,
  DASHBOARD: `${FEATURES_ROUTES.CRM}/dashboard`,
  LEADS: `${FEATURES_ROUTES.CRM}/leads`,
  CUSTOMERS: `${FEATURES_ROUTES.CRM}/customers`,
  SITES: `${FEATURES_ROUTES.CRM}/sites`,
  SETTINGS: `${FEATURES_ROUTES.CRM}/settings`,
  NOTIFICATIONS: `${FEATURES_ROUTES.DISTRIBUTOR}/notifications`,
};

export const LEAD_ROUTES = {
  BASE_PATH: CRM_ROUTES.LEADS,
  PREVIEW: `${CRM_ROUTES.LEADS}/preview`,
  COMMERCIAL: `${CRM_ROUTES.LEADS}/commercial`,
  RESIDENTIAL: `${CRM_ROUTES.LEADS}/residential`,
};

export const CUSTOMERS_ROUTES = {
  BASE_PATH: CRM_ROUTES.CUSTOMERS,
  PREVIEW: `${CRM_ROUTES.CUSTOMERS}/preview`,
  COMMERCIAL: `${CRM_ROUTES.CUSTOMERS}/commercial`,
  RESIDENTIAL: `${CRM_ROUTES.CUSTOMERS}/residential`,
};

export const SITES_ROUTES = {
  BASE_PATH: CRM_ROUTES.SITES,
  PREVIEW: `${CRM_ROUTES.SITES}/preview`,
};

export const getLeadRouteByType = (leadType: LEAD_TYPE | undefined): string =>
  leadType && leadType === LEAD_TYPE.COMMERCIAL
    ? LEAD_ROUTES.COMMERCIAL
    : LEAD_ROUTES.RESIDENTIAL;

export const DIST_ROUTES = {
  BASE_PATH: FEATURES_ROUTES.DISTRIBUTOR,
  DASHBOARD: `${FEATURES_ROUTES.DISTRIBUTOR}/dashboard`,
  DISTRIBUTORS: `${FEATURES_ROUTES.DISTRIBUTOR}/distributors`,
  ORGANIZATIONS: `${FEATURES_ROUTES.DISTRIBUTOR}/organizations`,
  WAREHOUSES: `${FEATURES_ROUTES.DISTRIBUTOR}/warehouses`,
  ORDERS: `${FEATURES_ROUTES.DISTRIBUTOR}/orders`,
  NOTIFICATIONS: `${FEATURES_ROUTES.DISTRIBUTOR}/notifications`,
};

export const DASHBOARD_URL = {
  LOOP: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.DASHBOARD}`,
  NP: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.DASHBOARD}`,
  CRM: `/${ROUTES.CRM.BASE_PATH}/${ROUTES.CRM.DASHBOARD}`,
  DISTRIBUTOR: `/${ROUTES.DISTRIBUTOR.BASE_PATH}/${ROUTES.DISTRIBUTOR.DASHBOARD}`,
  ORGANIZATION: '/',
  WAREHOUSE: '/',
};

export const NOTIFICATION_URL = {
  LOOP: `/${ROUTES.LOOP.BASE_PATH}/${ROUTES.LOOP.NOTIFICATIONS}`,
  NP: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.NOTIFICATIONS}`,
  CRM: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.NOTIFICATIONS}`,
  DISTRIBUTOR: `/${ROUTES.DISTRIBUTOR.BASE_PATH}/${ROUTES.DISTRIBUTOR.NOTIFICATIONS}`,
  ORGANIZATION: '/',
  WAREHOUSE: '/',
};

export const USER_PROFILE_URL = {
  LOOP: `/${ROUTES.LOOP.BASE_PATH}/${ROUTES.LOOP.SETTINGS.BASE_PATH}/${ROUTES.LOOP.SETTINGS.USER_PROFILE}`,
  NP: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.SETTINGS.BASE_PATH}/${ROUTES.NP.SETTINGS.USER_PROFILE}`,
  CRM: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.SETTINGS.BASE_PATH}/${ROUTES.NP.SETTINGS.USER_PROFILE}`,
  DISTRIBUTOR: '/',
  ORGANIZATION: '/',
  WAREHOUSE: '/',
};

export const LOOP_SETTINGS_ROUTES = {
  BASE_PATH: LOOP_ROUTES.SETTINGS,
};

export const DISTRIBUTORS_ROUTES = {
  BASE_PATH: DIST_ROUTES.DISTRIBUTORS,
};

export const ORGANIZATIONS_ROUTES = {
  BASE_PATH: DIST_ROUTES.ORGANIZATIONS,
  PREVIEW: `${DIST_ROUTES.ORGANIZATIONS}/preview`,
};

export const PROPOSAL_ROUTES = {
  BASE_PATH: NP_ROUTES.PROPOSALS,
  VERSIONS: `${NP_ROUTES.PROPOSALS}/versions`,
  NEW: `${NP_ROUTES.PROPOSALS}/new`,
  EDIT: `${NP_ROUTES.PROPOSALS}/edit`,
  PREVIEW: `${NP_ROUTES.PROPOSALS}/preview`,
};

export const QUOTES_ROUTES = {
  BASE_PATH: NP_ROUTES.QUOTES,
  NEW: `${NP_ROUTES.QUOTES}/new`,
  EDIT: `${NP_ROUTES.QUOTES}/edit`,
  PREVIEW: `${NP_ROUTES.QUOTES}/preview`,
};

export const INVOICES_ROUTES = {
  BASE_PATH: NP_ROUTES.INVOICES,
  NEW: `${NP_ROUTES.INVOICES}/new`,
  EDIT: `${NP_ROUTES.INVOICES}/edit`,
  PREVIEW: `${NP_ROUTES.INVOICES}/preview`,
};

export const PURCHASE_ORDERS_ROUTES = {
  BASE_PATH: NP_ROUTES.PURCHASE_ORDERS,
  NEW: `${NP_ROUTES.PURCHASE_ORDERS}/new`,
  PREVIEW: `${NP_ROUTES.PURCHASE_ORDERS}/preview`,
};

export const SERVICES_ROUTES = {
  BASE_PATH: NP_ROUTES.SERVICES,
};

export const PROJECTS_ROUTES = {
  BASE_PATH: NP_ROUTES.PROJECTS,
  COMPANY_PROJECTS: `${NP_ROUTES.PROJECTS}/my-company-projects`,
  BIDDING_PROJECTS: `${NP_ROUTES.PROJECTS}/my-bidding-projects`,
  NEW: `${NP_ROUTES.PROJECTS}/new`,
  EDIT: `${NP_ROUTES.PROJECTS}/edit`,
  PREVIEW: `${NP_ROUTES.PROJECTS}/preview`,
  BIDDED_PROJECT_PREVIEW: `${NP_ROUTES.PROJECTS}/preview-bided-project`,
  BIDDED_PROJECT_ATTACHMENTS_PREVIEW: `${NP_ROUTES.PROJECTS}/preview-bided-project/manage-attachements`,
  PROJECT_PREVIEW: `${NP_ROUTES.PROJECTS}/my-project-preview`,
  COMPANY_PROJECT_PREVIEW: `${NP_ROUTES.PROJECTS}/my-company-project/preview`,
  BIDDING_PROJECT_PREVIEW: `${NP_ROUTES.PROJECTS}/biding-projects/preview`,
  PROJECTS: `${NP_ROUTES.PROJECTS}/my-projects`,
  EDIT_BID: `${NP_ROUTES.PROJECTS}/projects/bid/edit`,
  BID: `${NP_ROUTES.PROJECTS}/projects/bid`,
};

export const NP_SETTINGS_ROUTES = {
  BASE_PATH: NP_ROUTES.SETTINGS,
};

import { useTranslation } from 'react-i18next';

import { Box, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';

import { ReactComponent as ChevronDown } from '@loop/assets/img/icons/chevron-down-thin.svg';

import {
  ContactFormField,
  ContactFormStyles,
} from '@loop-crm/features/settings/types/embedded-form-types';

interface Props {
  field: ContactFormField;
  styles: ContactFormStyles;
}

const ContactFormInputAddressPreview = ({ field, styles }: Props) => {
  const { t } = useTranslation();

  return (
    <Box mt={8}>
      <FormControl variant={'wide'} isRequired={field.fieldProperties.required}>
        <FormLabel requiredIndicator={<> *</>} mb={0}>
          {field.fieldProperties.label}
        </FormLabel>
        <Box>
          <Select
            icon={<ChevronDown />}
            color={styles.placeholderTextColor}
            variant={styles.formFieldStyle}
            borderColor={styles.formFieldBorderColor}
            placeholder={t('crm:Country')}
            isDisabled
          />
        </Box>
        <Box mt={3}>
          <Select
            icon={<ChevronDown />}
            color={styles.placeholderTextColor}
            variant={styles.formFieldStyle}
            borderColor={styles.formFieldBorderColor}
            placeholder={t('crm:State')}
            isDisabled
          />
        </Box>
        <Box mt={3}>
          <Select
            icon={<ChevronDown />}
            color={styles.placeholderTextColor}
            variant={styles.formFieldStyle}
            borderColor={styles.formFieldBorderColor}
            placeholder={t('crm:City')}
            isDisabled
          />
        </Box>
        <Box mt={3}>
          <Input
            color={styles.placeholderTextColor}
            variant={styles.formFieldStyle}
            borderColor={styles.formFieldBorderColor}
            placeholder={t('crm:Zip Code')}
            isDisabled
          />
        </Box>
        <Box mt={3}>
          <Input
            color={styles.placeholderTextColor}
            variant={styles.formFieldStyle}
            borderColor={styles.formFieldBorderColor}
            placeholder={t('crm:Street Address')}
            isDisabled
          />
        </Box>
      </FormControl>
    </Box>
  );
};

export default ContactFormInputAddressPreview;

import {
  LoopNpRole,
  RoleCheck,
  USER_GROUPS,
  UserGroup,
  UserRole,
} from '@loop/constants/user-roles';
import { useAppSelector } from '@loop/hooks/redux';

function useRoleCheck(roles: RoleCheck) {
  const activeRoles = useAppSelector(
    (state) => state.authModule.auth.decodedToken?.roles
  );
  if (!activeRoles) return;
  return !!roles.find((role) => activeRoles.includes(role));
}

export function useCurrentRole(serviceRoles: RoleCheck) {
  const activeRoles = useAppSelector(
    (state) => state.authModule.auth.decodedToken?.roles
  );
  if (!activeRoles) return;

  const currentRole: UserRole | undefined =
    activeRoles.find((role) =>
      ['loopAdmin', 'loopSuperAdmin'].includes(role)
    ) || activeRoles.find((role) => serviceRoles.includes(role));

  return currentRole;
}

export function useNpRole() {
  return useCurrentRole(USER_GROUPS.NP) as LoopNpRole;
}

export function useCurrentGroupOfRoles() {
  const activeRoles = useAppSelector(
    (state) => state.authModule.auth.decodedToken?.roles
  );
  if (!activeRoles) return;
  const activeGroup = Object.entries(USER_GROUPS).find((el) =>
    el[1].some((groupRole) => activeRoles.includes(groupRole))
  )?.[0];

  return activeGroup ? (activeGroup as UserGroup) : undefined;
}

export default useRoleCheck;

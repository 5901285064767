import { useContext } from 'react';
import {
  Controller,
  ControllerRenderProps,
  get,
  useFormContext,
} from 'react-hook-form';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
  SkeletonText,
  Text,
} from '@chakra-ui/react';

import { EditFormContext } from '@loop/components/common/inputs/editable-form';

interface Props extends InputProps {
  name: string;
  label: React.ReactNode;
  isRequired?: boolean;
  controlProps?: FormControlProps;
  InputComponent?: string | React.ComponentType;
  trim?: boolean;
}
function TextField({
  name,
  label,
  isRequired,
  controlProps,
  InputComponent = Input,
  trim = false,
  ...inputProps
}: Props) {
  const form = useFormContext();
  const { editable, isLoading } = useContext(EditFormContext);

  const error = get(form.formState.errors, name);

  const blurHandler = (event: any, field: ControllerRenderProps) => {
    if (!trim) {
      return;
    }
    field.onChange(event.target.value.trim());
  };

  return (
    <>
      <Controller
        name={name}
        render={({ field, fieldState }) => (
          <FormControl
            id={name}
            isInvalid={error}
            isRequired={isRequired}
            onBlur={(event) => blurHandler(event, field)}
            {...controlProps}
          >
            {label && (
              <FormLabel fontWeight={editable ? 400 : 500}>{label}</FormLabel>
            )}
            {editable ? (
              <InputComponent
                value={field.value}
                {...form.register(name)}
                {...inputProps}
              />
            ) : (
              <SkeletonText isLoaded={!isLoading} noOfLines={1}>
                <Text>{form.watch(name)}</Text>
              </SkeletonText>
            )}

            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </>
  );
}
export default TextField;

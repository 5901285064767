import { Button, ButtonProps } from '@chakra-ui/react';

interface TabButtonProps extends ButtonProps {
  isActive: boolean;
  children: React.ReactNode;
}

const TabButton = ({ isActive, children, ...rest }: TabButtonProps) => (
  <Button
    variant="link"
    pb="8px"
    borderRadius="0"
    _hover={{ textDecoration: 'none' }}
    color={isActive ? 'primary.600' : 'grey.600'}
    borderBottom="none"
    fontWeight={isActive ? 500 : 400}
    {...rest}
  >
    {children}
  </Button>
);

export default TabButton;

import { Api } from '@reduxjs/toolkit/dist/query';
import {
  EndpointDefinitions,
  MutationLifecycleApi,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { find, findIndex } from 'lodash';

import { PaginatedData, PaginatedRequest } from '@loop/types/paginated-data';
import getQueryCacheEntries from '@loop/utils/get-query-cache-entries';

function optimisticPaginatedDataUpdate<
  Response extends { id: string },
  Request extends { id: string }
>(
  api: Api<any, any, any, any, any>,
  queryName: keyof EndpointDefinitions,
  { id, ...patch }: Request,
  { dispatch, queryFulfilled }: MutationLifecycleApi<any, any, any, any>
) {
  const queries = getQueryCacheEntries(queryName as string);
  for (let query of queries) {
    const data = query?.data as PaginatedData<Response>;
    if (!query?.data || !find(data.records, { id })) return;
    const patchResult = dispatch(
      api.util.updateQueryData(
        'users',
        query.originalArgs as PaginatedRequest,
        (draft: any) => {
          const index = findIndex(draft.records, { id });
          draft.records[index] = {
            ...draft.records[index],
            ...patch,
            isActive: +patch,
          };
        }
      )
    );
    queryFulfilled.catch(patchResult.undo);
  }
}

export default optimisticPaginatedDataUpdate;

import styled from '@emotion/styled';
import { ReactComponent as ChevronDown } from 'assets/img/icons/chevron-down-thin.svg';
import { find } from 'lodash';
import { CSSProperties, useContext } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { SelectComponentsProps } from 'react-select/base';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  SkeletonText,
  Text,
} from '@chakra-ui/react';

import { EditFormContext } from '@loop/components/common/inputs/editable-form';
import useSelectStyles from '@loop/hooks/use-select-styles';

const StyledChevronDown = styled(ChevronDown)`
  [data-disabled='true'] & {
    display: none;
  }
`;

interface Props extends SelectComponentsProps {
  name: string;
  label?: React.ReactNode;
  isRequired?: boolean;
  isClearable?: boolean;
  controlProps?: FormControlProps;
  styles?: CSSProperties;
  onCustomChange?: () => void;
}

function SelectField({
  name,
  label,
  controlProps,
  isRequired,
  options,
  styles,
  isClearable,
  onCustomChange,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error, invalid },
  } = useController({ name });
  const { editable, isLoading } = useContext(EditFormContext);
  const { getSelectStyles } = useSelectStyles();

  const mapFieldValue = (value: any) => {
    if (typeof value !== 'undefined') {
      return {
        value,
        label: find(options, { value })?.label,
      };
    }
    return null;
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired} {...controlProps}>
      {!!label && (
        <FormLabel fontWeight={editable ? 400 : 500}>{label}</FormLabel>
      )}
      {editable ? (
        <Select
          placeholder={t('Select...')}
          icon={<StyledChevronDown />}
          {...field}
          {...rest}
          options={options}
          isClearable={isClearable}
          onChange={(values) => {
            field.onChange(values?.value ?? null);
            if (onCustomChange) {
              onCustomChange();
            }
          }}
          clearValue={null}
          value={mapFieldValue(field.value)}
          styles={getSelectStyles({ styles, error: error?.message })}
        />
      ) : (
        <SkeletonText isLoaded={!isLoading} noOfLines={1}>
          <Text>{field.value}</Text>
        </SkeletonText>
      )}

      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

export default SelectField;

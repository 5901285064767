import { get } from 'lodash';
import { Middleware } from 'redux';

import {
  STORE_PATH_AUTH,
  STORE_PATH_CORE,
  STORE_PATH_NP,
} from '@loop/constants/store-constatns';

const persistActions = [
  `${STORE_PATH_AUTH.AUTH}/setCredentials`,
  `${STORE_PATH_NP.QUOTE}/saveCompanyId`,
  `${STORE_PATH_NP.QUOTE}/saveClientInfo`,
  `${STORE_PATH_NP.QUOTE}/addQuoteProduct`,
  `${STORE_PATH_NP.QUOTE}/addQuoteLabor`,
  `${STORE_PATH_NP.QUOTE}/addQuoteOtherCost`,
  `${STORE_PATH_NP.QUOTE}/editQuoteProduct`,
  `${STORE_PATH_NP.QUOTE}/editQuoteLabor`,
  `${STORE_PATH_NP.QUOTE}/editQuoteOtherCost`,
  `${STORE_PATH_NP.QUOTE}/removeQuoteItem`,
  `${STORE_PATH_NP.QUOTE}/setQuoteErrors`,
  `${STORE_PATH_NP.QUOTE}/saveSummaryInfo`,
  `${STORE_PATH_NP.QUOTE}/resetQuoteState`,
  `${STORE_PATH_NP.QUOTE}/saveProductSalesTaxSum`,
  `${STORE_PATH_NP.QUOTE}/saveQuoteSummary`,
  `${STORE_PATH_NP.QUOTE}/saveDraftQuoteId`,
  `${STORE_PATH_NP.QUOTE}/saveQuoteEntries`,
  `${STORE_PATH_NP.PURCHASE_REQUEST}/saveGeneralInformation`,
  `${STORE_PATH_NP.PURCHASE_REQUEST}/addPurchaseRequestProduct`,
  `${STORE_PATH_NP.PURCHASE_REQUEST}/removePurchaseRequestProduct`,
  `${STORE_PATH_NP.PURCHASE_REQUEST}/resetPurchaseRequestState`,
  `${STORE_PATH_NP.PURCHASE_REQUEST}/setPurchaseRequestState`,
  `${STORE_PATH_NP.PURCHASE_REQUEST}/setPurchaseRequestErrors`,
  `${STORE_PATH_NP.PROJECT}/saveCompanyId`,
  `${STORE_PATH_NP.PROJECT}/saveProjectBaseInformation`,
  `${STORE_PATH_NP.PROJECT}/resetProjectState`,
  `${STORE_PATH_NP.PROJECT}/setProjectState`,
  `${STORE_PATH_NP.PROJECT}/saveProjectContactInformation`,
  `${STORE_PATH_NP.PROJECT}/addProjectFile`,
  `${STORE_PATH_NP.PROJECT}/removeProjectFile`,
  `${STORE_PATH_NP.PROJECT}/updateProjectFile`,
  `${STORE_PATH_NP.PROJECT}/addProjectId`,
  `${STORE_PATH_NP.BID}/updateBidFile`,
  `${STORE_PATH_NP.BID}/addBidFile`,
  `${STORE_PATH_NP.BID}/removeBidFile`,
  `${STORE_PATH_NP.BID}/resetBidState`,
  `${STORE_PATH_NP.BID}/setBidState`,
  `${STORE_PATH_NP.PROPOSAL}/saveCompanyId`,
  `${STORE_PATH_NP.PROPOSAL}/setProposalState`,
  `${STORE_PATH_NP.PROPOSAL}/saveProposalSiteInformation`,
  `${STORE_PATH_NP.PROPOSAL}/saveProposalCountry`,
  `${STORE_PATH_NP.PROPOSAL}/saveProposalScopeOfWork`,
  `${STORE_PATH_NP.PROPOSAL}/saveRecommendedScopeOfWork`,
  `${STORE_PATH_NP.PROPOSAL}/saveAssumptions`,
  `${STORE_PATH_NP.PROPOSAL}/saveScopeReview`,
  `${STORE_PATH_NP.PROPOSAL}/resetProposalState`,
  `${STORE_PATH_NP.PROPOSAL}/saveProposalId`,
  `${STORE_PATH_NP.PROPOSAL}/saveProposalDraftId`,
  `${STORE_PATH_NP.PROPOSAL}/saveIncludedTaxIncentives`,
  `${STORE_PATH_NP.PROPOSAL}/saveProposalScopeOfWorkProducts`,
  `${STORE_PATH_NP.PROPOSAL}/saveProjectSummary`,
  `${STORE_PATH_CORE.UTILS}/setIsSidebarCollapsed`,
] as const;

const localStorageMiddleware: Middleware = (store) => {
  return (next) => (action) => {
    const result = next(action);
    if (persistActions.includes(result.type)) {
      const pathArray = result.type.split('/').slice(0, -1);
      const key = pathArray.join('.');
      const localStorageKey = pathArray.join('/');

      localStorage.setItem(
        localStorageKey,
        JSON.stringify(get(store.getState(), key))
      );
    } else if (result.type === `${STORE_PATH_AUTH.AUTH}/resetAllState`) {
      const language = localStorage.getItem('language');
      localStorage.clear();
      if (language) {
        localStorage.setItem('language', language);
      }
      return;
    }
    return result;
  };
};

export default localStorageMiddleware;

import { createSlice } from '@reduxjs/toolkit';

import { STORE_PATH_CRM } from '@loop/constants/store-constatns';

import { CreateLeadForm } from '@loop-crm/types/contact-type';
import { AboutLead, CreateLead, GeneralInfo } from '@loop-crm/types/leads-type';

const sliceName = STORE_PATH_CRM.LEADS;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as CreateLead)
  : null;

const defaultState = {
  aboutLead: null,
  generalInfo: null,
  contactPerson: null,
  installationSite: null,
};

let initialState: CreateLead = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveCompanyId: (state, action: { payload: string }) => {
      state.companyId = action.payload;
    },
    saveAboutLead: (state, action: { payload: AboutLead }) => {
      state.aboutLead = action.payload;
    },
    saveContactPerson: (
      state,
      action: { payload: CreateLeadForm | undefined }
    ) => {
      state.contactPerson = action.payload;
    },
    saveGeneralInfo: (state, action: { payload: GeneralInfo }) => {
      state.generalInfo = action.payload;
    },
    resetLeadState: (state) => {
      state.aboutLead = null;
      state.contactPerson = null;
      state.generalInfo = null;
    },
    resetCompanyId: (state) => {
      state.companyId = undefined;
    },
  },
});

export const {
  saveCompanyId,
  saveAboutLead,
  saveContactPerson,
  saveGeneralInfo,
  resetLeadState,
  resetCompanyId,
} = slice.actions;

export default slice.reducer;

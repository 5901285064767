import { createContext, useContext, useState } from 'react';

import { Portal } from '@chakra-ui/react';

import SuccessModal from '@loop/components/common/modals/succes-modal';

export const SuccessPopupContext = createContext<{
  showPopup: (text: string) => void;
}>({ showPopup: () => ({}) });

interface ProviderProps {
  children: React.ReactNode;
}

export const SuccessPopupProvider = ({ children }: ProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [text, setText] = useState('');
  const showPopup = (text: string) => {
    setIsOpen(true);
    setText(text);
  };
  return (
    <SuccessPopupContext.Provider
      value={{
        showPopup: showPopup,
      }}
    >
      {children}
      {isOpen && (
        <Portal>
          <SuccessModal
            isOpen={isOpen}
            text={text}
            onClose={() => setIsOpen(false)}
          />
        </Portal>
      )}
    </SuccessPopupContext.Provider>
  );
};

export const useSuccessPopupShow = () => {
  const context = useContext(SuccessPopupContext);
  const showPopup = (text: string) => {
    context.showPopup(text);
  };
  return showPopup;
};

import { createSlice } from '@reduxjs/toolkit';

import { STORE_PATH_CRM } from '@loop/constants/store-constatns';
import { Address } from '@loop/types/address-types';

import {
  InstallationSiteLeadContact,
  InstallationSiteLeadStore,
  InstallationSiteRequest,
} from '@loop-crm/features/sites/types/sites-types';

const sliceName = STORE_PATH_CRM.SITES;
const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as InstallationSiteLeadStore)
  : null;

const defaultState = {
  details: null,
  contact: null,
};

let initialState: InstallationSiteLeadStore = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveCompanyId: (state, action: { payload: string }) => {
      state.companyId = action.payload;
    },
    saveLeadId: (state, action: { payload: string }) => {
      state.leadId = action.payload;
    },
    saveLeadAddress: (state, action: { payload: Address }) => {
      state.leadAddress = action.payload;
    },
    saveSiteDetails: (state, action: { payload: InstallationSiteRequest }) => {
      state.details = action.payload;
    },
    saveSiteContactPerson: (
      state,
      action: { payload: InstallationSiteLeadContact }
    ) => {
      state.contact = action.payload;
    },
    resetSitesState: (state) => {
      state.details = null;
      state.contact = null;
      state.leadAddress = undefined;
    },
    resetCompanyId: (state) => {
      state.companyId = undefined;
    },
    resetLeadId: (state) => {
      state.leadId = undefined;
    },
  },
});

export const {
  saveCompanyId,
  saveLeadId,
  saveLeadAddress,
  saveSiteDetails,
  saveSiteContactPerson,
  resetSitesState,
  resetCompanyId,
  resetLeadId,
} = slice.actions;

export default slice.reducer;

import { lazy } from 'react';

import { ROUTES } from '@loop/constants/routes-constants';
import { USER_GROUPS } from '@loop/constants/user-roles';
import { RouteConfig } from '@loop/types/routes-types';

const FEATURES_ROUTES_CONFIG: RouteConfig[] = [
  {
    path: `/${ROUTES.LOOP.BASE_PATH}`,
    exact: false,
    component: lazy(() => import('features/loop/routes/loop-routes')),
    roles: [...USER_GROUPS.LOOP],
  },
  {
    path: `/${ROUTES.NP.BASE_PATH}`,
    exact: false,
    component: lazy(
      () => import('features/network-partner/routes/network-partner-routes')
    ),
  },
  {
    path: `/${ROUTES.CRM.BASE_PATH}`,
    exact: false,
    component: lazy(() => import('features/crm/routes/crm-feature-routes')),
  },
];

export default FEATURES_ROUTES_CONFIG;

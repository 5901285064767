import { useRef, useState } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useToast,
} from '@chakra-ui/react';

export const CONFIRM_BUTTON_TYPES = {
  SUCCESS: 'success',
  CANCEL: 'cancel',
};

export const CONFIRM_BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'ghost',
};

interface ButtonDescription {
  type: string;
  text: string;
  variant: string;
}

interface SuccessButtonDescription extends ButtonDescription {
  successMessage?: string;
  action: () => void;
}

interface Props {
  isOpen: boolean;
  onClose: (isSuccess?: boolean) => void;
  title: string;
  messages: string[];
  buttons: (ButtonDescription | SuccessButtonDescription)[];
}

interface SuccessButtonProps {
  buttonDescription: SuccessButtonDescription;
}

interface CancelButtonProps {
  buttonDescription: ButtonDescription;
}

const ConfirmModal = ({ isOpen, onClose, title, messages, buttons }: Props) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const SuccessButton = ({ buttonDescription }: SuccessButtonProps) => {
    const { action, text, successMessage, variant } = buttonDescription;
    const isPrimary = variant === CONFIRM_BUTTON_VARIANT.PRIMARY;

    const successButtonClickHandler = async () => {
      setIsLoading(true);
      try {
        await action();
        if (successMessage) {
          toast({
            status: 'success',
            description: successMessage,
          });
        }
        onClose(true);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Button
        colorScheme={isPrimary ? 'primary' : undefined}
        variant={!isPrimary ? 'ghost' : undefined}
        bg={!isPrimary ? 'grey.100' : undefined}
        color={!isPrimary ? 'grey.400' : undefined}
        w="150px"
        type="submit"
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={successButtonClickHandler}
      >
        {text}
      </Button>
    );
  };

  const CancelButton = ({ buttonDescription }: CancelButtonProps) => {
    const { text, variant } = buttonDescription;
    const isPrimary = variant === CONFIRM_BUTTON_VARIANT.PRIMARY;

    return (
      <Button
        colorScheme={isPrimary ? 'primary' : undefined}
        variant={!isPrimary ? 'ghost' : undefined}
        bg={!isPrimary ? 'grey.100' : undefined}
        color={!isPrimary ? 'grey.400' : undefined}
        onClick={() => onClose()}
        isDisabled={isLoading}
        fontWeight="500"
        w="150px"
        ref={cancelRef}
      >
        {text}
      </Button>
    );
  };

  const constructButtons = () =>
    buttons.map((button) => {
      switch (button.type) {
        case CONFIRM_BUTTON_TYPES.SUCCESS:
          return (
            <SuccessButton
              key={button.text}
              buttonDescription={button as SuccessButtonDescription}
            />
          );
        case CONFIRM_BUTTON_TYPES.CANCEL:
          return (
            <CancelButton
              key={button.text}
              buttonDescription={button as ButtonDescription}
            />
          );
        default:
          return <></>;
      }
    });

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader
          color="grey.600"
          fontWeight="500"
          fontSize="xl"
          p={6}
        >
          {title}
        </AlertDialogHeader>

        <AlertDialogBody px={6} pb={6} pt={0}>
          {messages.map((message) => (
            <Text key={message}>{message}</Text>
          ))}
        </AlertDialogBody>
        <AlertDialogFooter p={6} justifyContent={'space-between'}>
          {constructButtons()}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmModal;

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';

import CurrencyField from '@loop/components/common/inputs/currency-field';
import DropdownField from '@loop/components/common/inputs/dropdown-field';
import TextField from '@loop/components/common/inputs/text-field';
import config from '@loop/config/config';
import { IMoney } from '@loop/utils/money';

import {
  useCreateCompanyLaborMutation,
  useNpLaborQuery,
  useUpdateCompanyLaborMutation,
} from '@loop-np/api/labors';
import companyLaborCreateSchema from '@loop-np/features/settings/company-labors/schemes/company-labor-create-schema';

interface CreateLaborItemForm {
  name: string;
  unitType: string;
  price: IMoney;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  laborId?: string;
  companyId?: string;
}

function CreateCompanyLaborModal({ isOpen, onClose, laborId }: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const isForEdit = !!laborId;
  const unitTypes = ['hr', 'total', 'unit'];

  const [createLabor, { isLoading: isLaborCreating }] =
    useCreateCompanyLaborMutation();
  const [updateLabor, { isLoading: isLaborUpdating }] =
    useUpdateCompanyLaborMutation();

  const {
    data: editLabor,
    isFetching: isEditLaborFetching,
    isLoading: isEditLaborLoading,
  } = useNpLaborQuery(
    {
      id: laborId || '',
    },
    { refetchOnMountOrArgChange: true, skip: !isForEdit }
  );

  const isLaborLoading = isEditLaborFetching || isEditLaborLoading;

  const defaultCreateValues = {
    name: '',
    unitType: 'hr',
    price: {
      value: 0,
      currency: config.preferredCurrency,
    },
  };

  const closeHandler = () => {
    onClose();
    form.reset(defaultCreateValues);
  };

  const isLoading = isLaborCreating || isLaborUpdating || isLaborLoading;
  const defaultValues: DeepPartial<CreateLaborItemForm> =
    isForEdit && editLabor
      ? {
          name: editLabor.name,
          unitType: editLabor.unitType,
          price: editLabor.price,
        }
      : defaultCreateValues;

  const form = useForm<CreateLaborItemForm>({
    resolver: yupResolver<yup.AnyObjectSchema>(companyLaborCreateSchema(t)),
    mode: 'onChange',
    defaultValues,
  });

  const createNpLaborByNp = async (data: CreateLaborItemForm) => {
    await createLabor(data).unwrap();
    toast({
      status: 'success',
      description: t('settings:Labor Item created successfully'),
    });
    closeHandler();
  };

  const updateNpLaborByNp = async (data: CreateLaborItemForm, id: string) => {
    await updateLabor({ id, data }).unwrap();
    toast({
      status: 'success',
      description: t('settings:Labor Item updated successfully'),
    });
    closeHandler();
  };

  const onSubmit = (data: CreateLaborItemForm) => {
    if (laborId) {
      updateNpLaborByNp(data, laborId);
    } else {
      createNpLaborByNp(data);
    }
  };

  useEffect(() => {
    if (isForEdit && editLabor) {
      form.reset(defaultValues);
    } else if (!isForEdit) {
      form.reset(defaultValues);
    }

    // eslint-disable-next-line
  }, [isOpen, editLabor]);

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent p={5}>
        <FormProvider {...form}>
          <Box as="form" onSubmit={form.handleSubmit(onSubmit)}>
            <ModalHeader color="grey.600" fontWeight="500" fontSize="xl" pb={8}>
              {isForEdit
                ? t('settings:Edit Labor')
                : t('settings:Labor Creation')}
            </ModalHeader>
            <ModalBody opacity={isLaborLoading ? '0.2' : 1}>
              <TextField
                name="name"
                label={t('settings:Labor Name')}
                controlProps={{
                  mb: '26px',
                }}
                isRequired
              />

              <Flex justify="space-between" w="100%">
                <Box w="43%">
                  <CurrencyField
                    name={`price`}
                    label={t('settings:Price Value')}
                    showCurrencySelect
                    isRequired
                  />
                </Box>
                <Box w="22px" pt="44px">
                  <Text>{t('settings:per')}</Text>
                </Box>

                <Box w="43%">
                  <DropdownField
                    name={`unitType`}
                    label={t('settings:Unit')}
                    isRequired={true}
                  >
                    {unitTypes.map((unitType) => (
                      <option value={unitType} key={unitType}>
                        {t(`settings:${unitType}`)}
                      </option>
                    ))}
                  </DropdownField>
                </Box>
              </Flex>

              {isForEdit && (
                <Text color="grey.300" fontWeight="400" pt="44px">
                  {t('settings:Note')}:{'  '}
                  {t(
                    'settings:any modification will affect the list of labors for Quotes or Invoices but not existing ones.'
                  )}
                </Text>
              )}
            </ModalBody>

            <ModalFooter
              justifyContent="space-between"
              pt={isForEdit ? '16px' : '100px'}
            >
              <Button
                onClick={() => {
                  closeHandler();
                }}
                variant="ghost"
                fontWeight="500"
                bg="grey.100"
                color="grey.400"
                isDisabled={isLoading}
                w="120px"
              >
                {t('Close')}
              </Button>
              <HStack spacing={6}>
                <Button
                  w="120px"
                  colorScheme="primary"
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={isLoading || !form.formState.isValid}
                >
                  {isForEdit ? t('Save') : t('Create')}
                </Button>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}

export default CreateCompanyLaborModal;

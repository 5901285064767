import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCallback } from 'react';

import exchangeRateApi, {
  useExchangeRatesQuery,
} from '@loop/api/common/exchange-rate';
import { CurrencyCode, currencies } from '@loop/config/config';
import store from '@loop/store/store';
import Money, { IMoney } from '@loop/utils/money';

interface Props {
  currency?: string;
  maximumFractionDigits?: number;
}

export const getProperCurrency = ({
  currency,
  maximumFractionDigits = 2,
}: Props) => {
  return Intl.NumberFormat('en-US', {
    // TODO: detect locales for different formatting
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: currency || 'USD',
    maximumFractionDigits,
  });
};

export const getCurrencyBySymbol = (symbol: string) =>
  currencies.find((currency) => currency.symbol === symbol)?.code;

export const getCurrencyByCode = (code: string) =>
  currencies.find((currency) => currency.code === code)?.symbol;

export const useMoney = (
  targetCurrency: CurrencyCode = 'USD',
  skipRates: boolean = false
) => {
  const { data: rates } = useExchangeRatesQuery(
    skipRates ? skipToken : targetCurrency,
    { skip: skipRates }
  );
  const m = useCallback(
    (value: Partial<IMoney> | Money | null, currency?: CurrencyCode) =>
      new Money(value, rates, currency || targetCurrency),
    [rates, targetCurrency]
  );
  return m;
};

// Make sure to call the `useMoney` hook on every page that is using this function to load rates
export const m = (
  value: Partial<IMoney> | Money | null,
  targetCurrency: CurrencyCode = 'USD'
) => {
  const rates = exchangeRateApi.endpoints.exchangeRates.select(targetCurrency)(
    store.getState()
  ).data;
  return new Money(value, rates, targetCurrency);
};

export const getCurrencyByCountry = (country?: string) => {
  switch (country) {
    case 'US':
      return 'USD';
    case 'GR':
      return 'EUR';
    default:
      return 'USD';
  }
};

import { TFunction } from 'i18next';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import { currencies } from '@loop/config/config';
import { emailRegex } from '@loop/config/constants';

export const yupMoney = (requiredMessage?: string) =>
  yup.object({
    value: requiredMessage
      ? yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .positive('Should be greater that 0')
          .required(requiredMessage)
      : yup.number().transform((value) => (isNaN(value) ? undefined : value)),
    currency: yup.string().oneOf(currencies.map(({ code }) => code)),
  });

export const yupAddress = (t: TFunction, requiredMessage?: string) =>
  yup
    .object({
      id: yup
        .number()
        .required(t('City is Required'))
        .typeError(t('City is Required')),
      country: yup.string().required(t('Country is Required')),
      countryLabel: yup.string().required(t('Country is Required')),
      adminName: yup.string().required(t('Region is Required')),
      zipCode: yup
        .string()
        .matches(
          /^([0-9]{5}|[0-9]{5}-[0-9]{4}|\d{3}[ ]?\d{2})$/,
          t('Postal Code is Wrong')
        )
        .required(t('Postal Code is Required')),
      city: yup.string().required(t('City is Required')),
      adminCode: yup.string().required(t('Region is Required')),
      streetAddress: yup.string().required(requiredMessage),
    })
    .required(requiredMessage);

export const yupEmail = (t: TFunction) =>
  yup
    .string()
    .trim()
    .required(t('Email is required'))
    .matches(emailRegex, t('Not a valid email'))
    .max(50, t('Email is too long'));

export const yupPhone = (t: TFunction, requiredMessage?: string) => {
  const yupSchema = requiredMessage
    ? yup.string().trim().required(requiredMessage).nullable()
    : yup.string().nullable();

  return yupSchema.test('isPossible', t('Incorrect phone number'), (val) =>
    val ? isPossiblePhoneNumber(val) : true
  );
};

import { CSSProperties } from 'react';
import { StylesConfig } from 'react-select/src/styles';

import { useToken } from '@chakra-ui/react';

interface StylesProps {
  styles?: CSSProperties;
  error?: string;
}

const useSelectStyles = () => {
  const [
    grey100,
    grey150,
    grey200,
    greyAlpha500,
    grey600,
    white,
    primary500,
    primaryAlpha500,
    red400,
  ] = useToken('colors', [
    'grey.100',
    'grey.150',
    'grey.200',
    'grey.alpha.500',
    'grey.600',
    'white',
    'primary.500',
    'primary.alpha.500',
    'red.400',
  ]);

  const getBorderStyle = (color: string, error?: string) => {
    return error ? `2px solid ${red400}` : `1px solid  ${color}`;
  };

  const getSelectStyles = ({
    styles,
    error,
  }: StylesProps): StylesConfig<any, boolean, any> => {
    const { width } = styles || {};
    return {
      control: (base, props) => ({
        ...base,
        fontSize: '14px',
        borderRadius: '6px',
        border: getBorderStyle(grey150, error),
        backgroundColor: props.isDisabled ? grey100 : white,
        '&:hover': {
          borderColor: getBorderStyle(grey200, error),
        },
        ...styles,
        ...(width && { width }),
      }),

      dropdownIndicator: (base) => ({
        ...base,
        color: greyAlpha500,
        width: '28px',
        height: '18px',
        padding: '0 10px 0 0',
        '&:hover': {
          color: greyAlpha500,
        },
      }),
      input: (base) => ({
        ...base,
        color: greyAlpha500,
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none',
      }),
      loadingIndicator: (base) => ({
        ...base,
        color: primary500,
      }),
      menu: (base) => ({
        ...base,
        backgroundColor: grey100,
        ...(width && { width }),
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
        fontSize: '14px',
      }),
      noOptionsMessage: (base) => ({
        ...base,
        backgroundColor: grey100,
      }),
      placeholder: (base) => ({
        ...base,
        color: greyAlpha500,
        opacity: 0.5,
      }),
      singleValue: (base) => ({
        ...base,
        color: greyAlpha500,
      }),
      option: (base, props) => ({
        ...base,
        color: props.isSelected ? white : grey600,
        fontWeight: props.isSelected ? 500 : 400,
        backgroundColor: props.isSelected ? primary500 : white,
        minHeight: '35px',
        '&:hover': {
          color: props.isSelected ? white : grey600,
          backgroundColor: props.isSelected ? primary500 : primaryAlpha500,
        },
      }),
      valueContainer: (base) => ({
        ...base,
        padding: '3px 16px',
      }),
    };
  };

  const getMultiSelectCheckboxStyles = ({
    styles,
    error,
  }: StylesProps): StylesConfig<any, boolean, any> => {
    return {
      ...getSelectStyles({ styles, error }),
      control: (base) => ({
        ...base,
        fontSize: '14px',
        borderRadius: '8px',
        border: getBorderStyle(grey150, error),
        backgroundColor: grey100,
        '&:hover': {
          borderColor: getBorderStyle(grey200, error),
        },
        ...styles,
      }),
      option: (base) => ({
        ...base,
        color: grey600,
        fontWeight: 400,
        backgroundColor: grey100,
        minHeight: '35px',
        padding: '0',
        '&:hover': {
          color: grey600,
          backgroundColor: primaryAlpha500,
          cursor: 'pointer',
        },
      }),
      valueContainer: (base) => ({
        ...base,
        padding: '0 16px',
      }),
      input: (base) => ({
        ...base,
        margin: 0,
      }),
      indicatorsContainer: (base) => ({
        ...base,
        height: '22px',
      }),
    };
  };

  const getCRMSelectStyles = ({
    styles,
    error,
  }: StylesProps): StylesConfig<any, boolean, any> => {
    return {
      ...getSelectStyles({ styles, error }),
      control: (base) => ({
        ...base,
        fontSize: '14px',
        borderRadius: '8px',
        border: getBorderStyle(grey150, error),
        backgroundColor: grey100,
        '&:hover': {
          borderColor: getBorderStyle(grey200, error),
        },
        ...styles,
      }),
      option: (base) => ({
        ...base,
        color: grey600,
        fontWeight: 400,
        backgroundColor: grey100,
        minHeight: '35px',
        '&:hover': {
          color: grey600,
          backgroundColor: primaryAlpha500,
          cursor: 'pointer',
        },
      }),
      valueContainer: (base) => ({
        ...base,
        padding: '0 16px',
      }),
      input: (base) => ({
        ...base,
        margin: 0,
      }),
      indicatorsContainer: (base) => ({
        ...base,
        height: '22px',
      }),
    };
  };

  return { getSelectStyles, getMultiSelectCheckboxStyles, getCRMSelectStyles };
};

export default useSelectStyles;

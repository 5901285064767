import api from '@loop/api/api';
import { CurrencyCode, currencies } from '@loop/config/config';
import { BASE_URL } from '@loop/config/constants';

export interface ExchangeRates {
  timestamp: number;
  [key: string]: number;
}

export interface ApiExchangeRates {
  timestamp: number;
  quotes: {
    [key: string]: number;
  };
}

const exchangeRateApi = api.injectEndpoints({
  endpoints: (build) => ({
    exchangeRates: build.query<ApiExchangeRates, CurrencyCode>({
      query: (source: CurrencyCode = 'USD') => ({
        url: `${BASE_URL.CURRENCY}/rate`,
        method: 'POST',
        body: {
          source,
          currencies: currencies.map(({ code }) => code),
        },
      }),
    }),
  }),
});

export const { useExchangeRatesQuery, usePrefetch } = exchangeRateApi;

export default exchangeRateApi;

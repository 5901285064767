import { Box, Checkbox, FormControl } from '@chakra-ui/react';

import {
  ContactFormField,
  ContactFormStyles,
} from '@loop-crm/features/settings/types/embedded-form-types';

interface Props {
  field: ContactFormField;
  styles: ContactFormStyles;
}

const ContactFormInputConsentPreview = ({ field, styles }: Props) => {
  return (
    <Box mt={8}>
      <FormControl variant={'wide'} isRequired={field.fieldProperties.required}>
        <Checkbox
          alignItems={'flex-start'}
          iconColor={styles.buttonColor}
          borderColor={styles.buttonColor}
          variant={'contactForm'}
        >
          {field.fieldProperties.description}
        </Checkbox>
      </FormControl>
    </Box>
  );
};

export default ContactFormInputConsentPreview;

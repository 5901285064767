import { combineReducers } from '@reduxjs/toolkit';

import { CORE_SLICES } from '@loop/constants/store-constatns';
import notificationReducer from '@loop/store/slices/notification-slice';
import utilsReducer from '@loop/store/slices/utils-slice';

export const coreReducer = combineReducers({
  [CORE_SLICES.NOTIFICATIONS]: notificationReducer,
  [CORE_SLICES.UTILS]: utilsReducer,
})!;

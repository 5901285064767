import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import i18next from 'i18next';
import produce from 'immer';

import config from '@loop/config/config';
import { BASE_URL } from '@loop/config/constants';
import { resetNotificationState } from '@loop/store/slices/notification-slice';
import store, { RootState } from '@loop/store/store';
import history from '@loop/utils/history';
import toast from '@loop/utils/toast';

import httpClient, { refreshToken } from '@loop-libs/http-client';

import { resetAllState } from '@loop-auth/store/slices/auth-slice';

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.apiUrl}/api/`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).authModule.auth.tokens.accessToken;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export interface IQueryArgs extends FetchArgs {
  showToast?: boolean;
  redirectOnNotFoundError?: boolean;
}

const mutex = new Mutex();

export const logout = async () => {
  await httpClient.get(`${config.apiUrl}/api/${BASE_URL.AUTH}/decline`);
  store.dispatch(resetAllState());
  store.dispatch(api.util.resetApiState());
  store.dispatch(resetNotificationState());
  sessionStorage.clear();
  history.push('/login');
  mutex.cancel();
  mutex.release();
};

export const showErrorMessage = (
  message: string,
  variablesValues?: { [key: string]: string }
) => {
  toast({
    status: 'error',
    title: i18next.t('Error'),
    description: message
      ? i18next.t(message, variablesValues) /* i18next-extract-disable-line */
      : i18next.t('Oh no, there was an error!'),
    isClosable: true,
  });
};

const baseQueryWithToast: BaseQueryFn<IQueryArgs> = async (
  { showToast = true, redirectOnNotFoundError = false, ...args }: IQueryArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  await mutex.waitForUnlock();
  let transformedResponse;
  const response = await baseQuery(args, api, extraOptions);
  transformedResponse = produce(response, (draft) => {
    if (draft.data) {
      draft.data = (draft.data as any).result as typeof response.data;
    }
  });

  if (!mutex.isLocked()) {
    if (transformedResponse.error) {
      const err = (transformedResponse.error as any).data?.error;
      if (err.code === 401) {
        if (err.companyStatus === false) {
          history.push('/login');
        } else if (err.message === 'jwt expired') {
          const release = await mutex.acquire();
          try {
            await refreshToken();

            const response = await baseQuery(args, api, extraOptions);
            transformedResponse = produce(response, (draft) => {
              if (draft.data) {
                draft.data = (draft.data as any).result as typeof response.data;
              }
            });
            release();
          } catch {
            mutex.cancel();
            release();
          }
        } else {
          logout();
        }
      } else if (redirectOnNotFoundError && err.code === 404) {
        history.replace('/error404');
      } else if (showToast) {
        const message =
          err?.message && typeof err?.message === 'string'
            ? err.message
            : err.message.message;
        const hasVariable = /{{[^)]*}}/.test(message);
        const variablesValues = hasVariable
          ? {
              ...err.metaData,
            }
          : undefined;

        showErrorMessage(message, variablesValues);
      }
    }
  } else {
    await mutex.waitForUnlock();
    const response = await baseQuery(args, api, extraOptions);
    transformedResponse = produce(response, (draft) => {
      if (draft.data) {
        draft.data = (draft.data as any).result as typeof response.data;
      }
    });
  }

  return transformedResponse;
};

const api = createApi({
  baseQuery: baseQueryWithToast,
  endpoints: () => ({}),
  tagTypes: [
    'RefreshToken',
    'Bid',
    'NpProducts',
    'NpProduct',
    'MyBids',
    'ProjectBids',
    'Notifications',
    'Quotes',
    'Quote',
    'Distributors',
    'LoopUser',
    'LoopCompany',
    'User',
    'LoopUsers',
    'AllUsers',
    'Users',
    'Company',
    'Proposals',
    'ProposalRecommendations',
    'ProposalDefaultScopeReview',
    'Product',
    'ProductByCompany',
    'proposalAssumptions',
    'Labor',
    'IsQuoteActive',
    'NpLabor',
    'Service',
    'PurchaseRequests',
    'NotificationsSettings',
    'Projects',
    'Products',
    'LandingProducts',
    'LandingContactForm',
    'TrainingImages',
    'MarketingImages',
    'NpLabors',
    'CustomLabors',
    'DefaultLabors',
    'PurchaseRequestByQuote',
    'RequestToAssignUsers',
    'NpProductsForLoop',
    'Invoices',
    'Invoice',
    'TermsConditions',
    'TermsConditionsHistory',
    'ChargerTypes',
    'Organization',
    'DraftProposal',
    'Lead',
    'LeadContactForm',
    'Leads',
    'LeadProposal',
    'LeadQuote',
    'LeadInvoice',
    'LeadActivity',
    'LeadHistory',
    'LeadInstallationSite',
    'ResidentialLead',
    'ResidentialLeadContactForm',
    'ResidentialLeads',
    'ResidentialLeadProposal',
    'ResidentialLeadQuote',
    'ResidentialLeadInvoice',
    'ResidentialLeadActivity',
    'ResidentialLeadHistory',
    'ResidentialLeadInstallationSite',
    'Contact',
    'Sites',
    'Site',
    'SiteHistory',
    'SiteAttachment',
    'SiteActivity',
    'Activity',
    'Source',
    'LeadAttachment',
    'Videos',
    'CommercialCustomers',
    'CommercialCustomer',
    'CommercialCustomerHistory',
    'ResidentialCustomers',
  ],
});

export default api;

import { omit } from 'lodash';

import api from '@loop/api/api';
import { BASE_URL } from '@loop/config/constants';
import { LoopNpRole, LoopUser, NpUser } from '@loop/constants/user-roles';
import store from '@loop/store/store';
import { StateOperatingIn, UserCompany } from '@loop/types/company-type';
import { PaginatedData, PaginatedRequest } from '@loop/types/paginated-data';
import {
  LoopUserResponse,
  RequestedToAssignUserResponse,
  UserResponse,
  UserWithMultiRoles,
} from '@loop/types/user-type';
import optimisticPaginatedDataUpdate from '@loop/utils/optimistic-paginated-data-update';

export interface Tokens {
  accessToken: string | null;
  refreshToken: string | null;
}

export interface LoginResponse {
  tokens: Tokens;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginSocialRequest {
  code: string;
  redirectUri: string;
}

export interface TokensSSO {
  ssoTokens: Tokens;
}

export interface LoginSocialResponse {
  user: UserResponse;
  tokens: Tokens;
}

export interface AuthenticateResponse {
  nppTokens: Tokens;
  ssoTokens: Tokens;
}

export interface SignupRequest {
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  password: string;
}

export interface AdditionalSocialSignupRequest {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  signupType: string;
}

export interface VerifyRequest {
  email: string;
  code: string;
}

export interface CompanyLoginResponse {
  company: {
    id: string;
    companyName: string;
    country: string;
    postalCode: string;
    address: string;
    stateOperatingIn: StateOperatingIn[];
    phone: string;
    email: string;
    timeZone: string;
    logo: string;
    lastLogin: string;
  };
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

export interface CreateUserRequest {
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  role: NpUser;
  companyId?: string;
}

export interface CreateLoopUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  role: LoopUser;
}

export interface UpdateOwnUserInfoRequest {
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export interface UpdateLoopUserThemselves {
  firstName?: string;
  lastName?: string;
}

interface UpdateUserRequest {
  id: string;
  firstName?: string;
  lastName?: string;
  phone?: string | null;
  role?: LoopNpRole;
  isActive?: boolean;
}

interface UserPassword {
  oldPassword: string;
  newPassword: string;
}

export interface NewUserSetPasswordRequest {
  email: string;
  temporaryPassword: string;
  newPassword: string;
}

interface UnassignUserRequest {
  companyId: string;
  userId: string;
}

export interface ReSendCodeRequest {
  email: string;
}

interface GetUserById {
  id: string | undefined;
}

interface GetCompanyById {
  id: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface SetForgottenPasswordRequest {
  email: string;
  code: string;
  newPassword: string;
}

export interface AssignUserToCompanyRequest {
  userId: string;
  companyId: string;
}

export interface AssignUserToCompanyRequestByUserId {
  userId: string;
  role: NpUser;
}

export interface AssignUserToCompanyRequestByAdmin {
  userId: string;
  companyId: string;
  role: NpUser;
}

export interface CheckIsAssignedRequestSent {
  isAssignedRequestSent: boolean;
}

export interface CheckIsAssignedRequestProcessed {
  isAssignedRequestProcessed: boolean;
}

interface AgreeTermsCOnditionsRequest {
  email: string;
}

interface UpdateUserAccountStatus {
  userId: string;
  isActive: boolean;
}

interface CheckLastUserRequest {
  userId: string;
  companyId: string;
  role: NpUser;
}

interface CheckLastUserResponse {
  isLast: boolean;
}

const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    user: build.query<UserWithMultiRoles, void>({
      query: () => ({ url: `${BASE_URL.USER}/user`, showToast: false }),
      providesTags: ['User'],
    }),
    userById: build.query<UserResponse, GetUserById>({
      query: (values: GetUserById) => ({
        url: `${BASE_URL.USER}/user/${values.id}`,
      }),
    }),
    unassignedUserById: build.query<UserResponse, GetUserById>({
      query: (values: GetUserById) => ({
        url: `${BASE_URL.USER}/user/unassigned/${values.id}`,
      }),
    }),
    unassigneUserById: build.mutation<void, GetUserById>({
      query: (values: GetUserById) => ({
        url: `${BASE_URL.USER}/user/unassigned/${values.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllUsers', 'Users', 'RequestToAssignUsers'],
    }),
    deleteUser: build.mutation<void, GetUserById>({
      query: (values: GetUserById) => ({
        url: `${BASE_URL.USER}/user/delete-np-user/by-admin/${values.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllUsers'],
    }),
    loopUser: build.query<LoopUserResponse, void>({
      query: () => ({ url: `${BASE_URL.USER}/loop-user` }),
      providesTags: ['LoopUser'],
    }),
    users: build.query<PaginatedData<UserResponse>, PaginatedRequest>({
      query: (body) => ({
        url: `${BASE_URL.USER}/user/filtered`,
        method: 'POST',
        body,
      }),
      providesTags: ['Users'],
    }),
    requestToAssignUsers: build.query<
      PaginatedData<RequestedToAssignUserResponse>,
      PaginatedRequest
    >({
      query: (body) => ({
        url: `${BASE_URL.USER}/user/assigned-requests/filtered`,
        method: 'POST',
        body,
      }),
      providesTags: ['RequestToAssignUsers'],
    }),
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/login`,
        method: 'POST',
        body: credentials,
        showToast: false,
      }),
    }),
    agreeTermsAndConditions: build.mutation<void, AgreeTermsCOnditionsRequest>({
      query: (body: AgreeTermsCOnditionsRequest) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/agree-terms-and-conditions`,
        method: 'PUT',
        body,
        showToast: false,
      }),
    }),
    signup: build.mutation<null, SignupRequest>({
      query: (values) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/registration/np-admin`,
        method: 'POST',
        body: values,
      }),
    }),
    assignUserToCompanyByUserId: build.mutation<
      void,
      AssignUserToCompanyRequestByUserId
    >({
      query: (values: AssignUserToCompanyRequestByUserId) => ({
        url: `${BASE_URL.USER}/user/assign-to-company`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['AllUsers', 'Users', 'RequestToAssignUsers'],
    }),
    assignUserToCompanByAdmin: build.mutation<
      void,
      AssignUserToCompanyRequestByAdmin
    >({
      query: (values: AssignUserToCompanyRequestByAdmin) => ({
        url: `${BASE_URL.USER}/user/assign-to-company/by-admin/${values.userId}`,
        method: 'PUT',
        body: omit(values, 'userId'),
      }),
      invalidatesTags: ['AllUsers', 'Users', 'RequestToAssignUsers'],
    }),
    additionalInfoRegistration: build.mutation<
      null,
      AdditionalSocialSignupRequest
    >({
      query: (values) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/registration/social`,
        method: 'POST',
        body: values,
      }),
    }),
    verify: build.mutation<Tokens, VerifyRequest>({
      query: (credentials) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/verify`,
        method: 'POST',
        body: credentials,
      }),
    }),
    loginSocial: build.mutation<LoginSocialResponse, LoginSocialRequest>({
      query: (cognitoCode) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/login/social`,
        method: 'POST',
        body: cognitoCode,
        showToast: false,
      }),
    }),
    loginSSOSocial: build.mutation<AuthenticateResponse, LoginSocialRequest>({
      query: (cognitoCode) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/login/sso/social`,
        method: 'POST',
        body: cognitoCode,
        showToast: false,
      }),
    }),
    createUser: build.mutation<void, CreateUserRequest>({
      query: (values) => ({
        url: `${BASE_URL.USER}/user/create-np-user`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['Users'],
    }),
    createLoopUser: build.mutation<void, CreateLoopUserRequest>({
      query: (values) => ({
        url: `${BASE_URL.USER}/loop-user`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['LoopUsers'],
    }),
    checkLastUser: build.query<CheckLastUserResponse, CheckLastUserRequest>({
      query: (values) => ({
        url: `${BASE_URL.USER}/user/check-last-user`,
        method: 'POST',
        body: values,
      }),
    }),
    deleteLoopUser: build.mutation<void, string>({
      query: (id: string) => ({
        url: `${BASE_URL.USER}/loop-user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LoopUsers'],
    }),
    updateOwnUserInfo: build.mutation<void, UpdateOwnUserInfoRequest>({
      query: (values: UpdateOwnUserInfoRequest) => ({
        url: `${BASE_URL.USER}/user`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['User'],
      onQueryStarted: (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData('user', undefined, (draft) => {
            Object.assign(draft, patch);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    updateUser: build.mutation<void, UpdateUserRequest>({
      query: (values: UpdateUserRequest) => ({
        url: `${BASE_URL.USER}/user/${values.id}`,
        method: 'PUT',
        body: omit(values, 'id'),
      }),
      invalidatesTags: (_, __, { id }) => {
        const userId = store.getState().authModule.auth.decodedToken?.userId;
        return userId === id ? ['User', 'Users'] : ['Users'];
      },
      onQueryStarted: (patch: UpdateUserRequest, mutationApi) => {
        optimisticPaginatedDataUpdate<UserResponse, UpdateUserRequest>(
          userApi,
          'users',
          patch,
          mutationApi
        );
      },
    }),
    updateUserStatus: build.mutation<void, UpdateUserRequest>({
      query: (values: UpdateUserRequest) => ({
        url: `${BASE_URL.USER}/user/active/${values.id}`,
        method: 'PUT',
        body: omit(values, 'id'),
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    updateUserAccountStatus: build.mutation<void, UpdateUserAccountStatus>({
      query: (values: UpdateUserAccountStatus) => ({
        url: `${BASE_URL.USER}/user/user-account/active`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['AllUsers'],
    }),
    updateUserByAdmin: build.mutation<void, UpdateUserRequest>({
      query: (values: UpdateUserRequest) => ({
        url: `${BASE_URL.USER}/user/by-admin/${values.id}`,
        method: 'PUT',
        body: omit(values, 'id'),
      }),
    }),
    updateUserStatusByAdmin: build.mutation<void, UpdateUserRequest>({
      query: (values: UpdateUserRequest) => ({
        url: `${BASE_URL.USER}/user/active/by-admin/${values.id}`,
        method: 'PUT',
        body: omit(values, 'id'),
      }),
      invalidatesTags: ['AllUsers'],
    }),
    editLoopUserByLoopSuperAdmin: build.mutation<void, UpdateUserRequest>({
      query: (values: UpdateUserRequest) => ({
        url: `${BASE_URL.USER}/loop-user/${values.id}`,
        method: 'PUT',
        body: omit(values, 'id'),
      }),
    }),
    editLoopUserThemselves: build.mutation<void, UpdateLoopUserThemselves>({
      query: (values: UpdateLoopUserThemselves) => ({
        url: `${BASE_URL.USER}/loop-user`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['LoopUser', 'User'],
      onQueryStarted: (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData('loopUser', undefined, (draft) => {
            Object.assign(draft, patch);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    changeUserPassword: build.mutation<void, UserPassword>({
      query: (values: UserPassword) => ({
        url: `${BASE_URL.USER}/user/password`,
        method: 'PUT',
        body: values,
      }),
    }),
    newUserSetPassword: build.mutation<void, NewUserSetPasswordRequest>({
      query: (credentials: NewUserSetPasswordRequest) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/password`,
        method: 'POST',
        body: credentials,
      }),
    }),
    reSendPassword: build.mutation<void, { email: string }>({
      query: (values: { email: string }) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/password/resend`,
        method: 'POST',
        body: values,
      }),
    }),
    reSendCode: build.mutation<void, ReSendCodeRequest>({
      query: (values: ReSendCodeRequest) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/resend-code`,
        method: 'POST',
        body: values,
      }),
    }),
    forgotPassword: build.mutation<void, ForgotPasswordRequest>({
      query: (values: ForgotPasswordRequest) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/password/forgot`,
        method: 'POST',
        body: values,
        showToast: false,
      }),
    }),
    setForgottenPassword: build.mutation<void, SetForgottenPasswordRequest>({
      query: (values: SetForgottenPasswordRequest) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/password/confirm`,
        method: 'POST',
        body: values,
      }),
    }),
    allUsers: build.query<PaginatedData<UserResponse>, PaginatedRequest>({
      query: (params) => ({
        url: `${BASE_URL.USER}/user/all/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['AllUsers'],
    }),
    allNpUsers: build.query<PaginatedData<UserResponse>, PaginatedRequest>({
      query: (params) => ({
        url: `${BASE_URL.USER}/user/all/user-company/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['AllUsers'],
    }),

    allUsersAccounts: build.query<
      PaginatedData<UserResponse>,
      PaginatedRequest
    >({
      query: (params) => ({
        url: `${BASE_URL.USER}/user/all/user-company/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['AllUsers'],
    }),

    loopUsers: build.query<PaginatedData<UserResponse>, PaginatedRequest>({
      query: (params) => ({
        url: `${BASE_URL.USER}/loop-user/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['LoopUsers'],
    }),
    userCompanyById: build.query<UserCompany, GetCompanyById>({
      query: (values) => ({
        url: `${BASE_URL.USER}/user/company/${values.id}`,
        showToast: false,
      }),
      providesTags: ['LoopCompany'],
    }),
    unassignNpUserByAdmin: build.mutation<void, UnassignUserRequest>({
      query: (values: UnassignUserRequest) => ({
        url: `${BASE_URL.USER}/loop-user/unassigned/by-admin`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['AllUsers'],
    }),
    createNpUserByAdmin: build.mutation<{ userId: string }, CreateUserRequest>({
      query: (values: CreateUserRequest) => ({
        url: `${BASE_URL.USER}/user/create-np-user/by-admin`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['AllUsers'],
    }),

    declineUserRequest: build.mutation<void, string>({
      query: (id: string) => ({
        url: `${BASE_URL.USER}/user/assigned-requests/decline/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllUsers', 'RequestToAssignUsers'],
    }),
    checkAssignedRequest: build.query<CheckIsAssignedRequestSent, void>({
      query: () => ({
        url: `${BASE_URL.USER}/user/check-assigned-request`,
        showToast: false,
      }),
    }),
    checkIsAssignedRequestProcessed: build.query<
      CheckIsAssignedRequestProcessed,
      string
    >({
      query: (id: string) => ({
        url: `${BASE_URL.USER}/user/is-processed-assigned-request/${id}`,
        showToast: false,
      }),
    }),
    sendRequestToCompany: build.mutation<void, string>({
      query: (id: string) => ({
        url: `${BASE_URL.USER}/user/send-assign-request/${id}`,
        method: 'GET',
      }),
    }),
    assignToList: build.query<PaginatedData<UserResponse>, PaginatedRequest>({
      query: (params: PaginatedRequest) => ({
        url: `${BASE_URL.USER}/user/company/user-info/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['AllUsers'],
    }),
    authenticate: build.mutation<AuthenticateResponse, LoginRequest>({
      query: (body) => ({
        url: `${BASE_URL.USER_UNAUTORIZED}/login/sso`,
        method: 'POST',
        body,
        showToast: false,
      }),
    }),
    tokenSSO: build.query<TokensSSO, void>({
      query: () => ({
        url: `${BASE_URL.USER}/user/login/sso/token`,
      }),
    }),
  }),
});

export const {
  useUnassignNpUserByAdminMutation,
  useCheckIsAssignedRequestProcessedQuery,
  useCheckAssignedRequestQuery,
  useUserQuery,
  useUnassignedUserByIdQuery,
  useUserByIdQuery,
  useLoopUserQuery,
  useDeleteLoopUserMutation,
  useDeleteUserMutation,
  useUpdateUserByAdminMutation,
  useAllUsersQuery,
  useEditLoopUserThemselvesMutation,
  useLoopUsersQuery,
  useLazyUserQuery,
  useEditLoopUserByLoopSuperAdminMutation,
  useUsersQuery,
  useLoginMutation,
  useLoginSSOSocialMutation,
  useLoginSocialMutation,
  useSignupMutation,
  useVerifyMutation,
  useCreateLoopUserMutation,
  useCreateUserMutation,
  useUpdateOwnUserInfoMutation,
  useUpdateUserMutation,
  useChangeUserPasswordMutation,
  useNewUserSetPasswordMutation,
  useReSendCodeMutation,
  useForgotPasswordMutation,
  useSetForgottenPasswordMutation,
  useAdditionalInfoRegistrationMutation,
  useUserCompanyByIdQuery,
  useCreateNpUserByAdminMutation,
  useReSendPasswordMutation,
  useAssignUserToCompanyByUserIdMutation,
  useRequestToAssignUsersQuery,
  useDeclineUserRequestMutation,
  useAgreeTermsAndConditionsMutation,
  useSendRequestToCompanyMutation,
  useAssignToListQuery,
  useUpdateUserStatusByAdminMutation,
  useUpdateUserStatusMutation,
  useAllNpUsersQuery,
  useUnassigneUserByIdMutation,
  useAssignUserToCompanByAdminMutation,
  useAllUsersAccountsQuery,
  useUpdateUserAccountStatusMutation,
  useCheckLastUserQuery,
  useAuthenticateMutation,
  useTokenSSOQuery,
} = userApi;

export default userApi;

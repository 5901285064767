import api from '@loop/api/api';
import { BASE_URL } from '@loop/config/constants';
import { PaginatedData, PaginatedRequest } from '@loop/types/paginated-data';

interface Iservice {
  id: string;
  title: string;
  description: string;
  price: string;
  isOrdered: boolean;
}

interface ServiceRequest {
  serviceId: string;
  userInfo: UserInfo;
}

interface UpdateServiceStatusRequest {
  serviceId: string;
  status: string;
}

type Company = {
  companyName: string;
};
type User = {
  firstName: string;
  lastName: string;
};
type Service = {
  title: string;
};

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  description?: string;
}

export interface ServiceResponse {
  id: string;
  serviceId: string;
  npUserId: string;
  companyId: string;
  loopUserId: string;
  status: string;
  company: Company;
  user: User;
  loopUser: User;
  service: Service;
  createdAt: string;
  updatedAt: string;
  userInfo: UserInfo;
}

interface AssignOrderToMeRequest {
  orderId: string;
}

export const serviceApi = api.injectEndpoints({
  endpoints: (build) => ({
    services: build.query<Iservice[], void>({
      query: () => ({
        url: BASE_URL.SERVICE,
      }),
    }),
    servicesStatuses: build.query<Iservice[], void>({
      query: () => ({
        url: `${BASE_URL.SERVICE_ORDERS}/statuses`,
      }),
    }),
    orderService: build.mutation<void, ServiceRequest>({
      query: (values) => ({
        url: `${BASE_URL.SERVICE}/order`,
        method: 'POST',
        body: values,
      }),
    }),
    loopServices: build.query<PaginatedData<ServiceResponse>, PaginatedRequest>(
      {
        query: (params) => ({
          url: `${BASE_URL.SERVICE_ORDERS}/filtered`,
          method: 'POST',
          body: params,
        }),
      }
    ),
    serviceById: build.query<ServiceResponse, string>({
      query: (id) => ({
        url: `${BASE_URL.SERVICE_ORDERS}/${id}`,
      }),
      providesTags: ['Service'],
    }),
    updateServiceStatus: build.mutation<void, UpdateServiceStatusRequest>({
      query: (values: UpdateServiceStatusRequest) => ({
        url: `${BASE_URL.SERVICE_ORDERS}/status/${values.serviceId}`,
        method: 'PUT',
        body: {
          status: values.status,
        },
        invalidatesTags: ['Service'],
      }),
    }),
    assignOrderToMe: build.mutation<void, AssignOrderToMeRequest>({
      query: (values: AssignOrderToMeRequest) => ({
        url: `${BASE_URL.SERVICE_ORDERS}/assign/${values.orderId}`,
        method: 'PUT',
        body: {},
      }),
    }),
  }),
});

export const {
  useServicesQuery,
  useOrderServiceMutation,
  useLoopServicesQuery,
  useUpdateServiceStatusMutation,
  useServiceByIdQuery,
  useAssignOrderToMeMutation,
} = serviceApi;

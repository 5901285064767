import { useTranslation } from 'react-i18next';

import { TextProps } from '@chakra-ui/layout/dist/types/text';
import { Text } from '@chakra-ui/react';

import {
  COUNTRIES,
  getDateFormat,
  getTimeFormat,
} from '@loop/utils/date-format';

interface Props extends TextProps {
  value?: string | Date | number;
  country?: COUNTRIES;
  isTimeShown?: boolean;
}

const DateText = ({ value, country, isTimeShown, ...rest }: Props) => {
  const { t } = useTranslation();

  return (
    <Text {...rest} wordBreak="normal">
      {(typeof value === 'string' || typeof value === 'number') && (
        <>
          {getDateFormat(country).format(new Date(value))}
          {isTimeShown && (
            <>
              {' '}
              {t('at')} {getTimeFormat(country).format(new Date(value))}
            </>
          )}
        </>
      )}
    </Text>
  );
};

export default DateText;

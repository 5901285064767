import { createSlice } from '@reduxjs/toolkit';

import { STORE_PATH_NP } from '@loop/constants/store-constatns';
import { Address } from '@loop/types/address-types';
import { Option, Region } from '@loop/types/general-types';

import {
  ProjectAttachment,
  ProjectFileType,
  ProjectState,
  TradesRequired,
} from '@loop-np/types/projects-types';

const defaultState: ProjectState = {
  project: {
    planUrl: [],
    imgUrl: [],
    bidUrl: [],
    otherUrl: [],
  },
};

const sliceName = STORE_PATH_NP.PROJECT;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as ProjectState)
  : defaultState;

let initialState: ProjectState = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveCompanyId: (state, action: { payload: string }) => {
      state.project = {
        ...state.project,
        companyId: action.payload,
      };
    },
    saveProjectContactInformation: (
      state,
      action: {
        payload: {
          title?: string;
          hostOrganization?: string;
          projectContact?: string;
          contactNumber?: string;
          contactEmail?: string;
          address?: Address;
          country?: Option;
          region?: Region;
        };
      }
    ) => {
      state.project = {
        ...state.project,
        ...action.payload,
      };
    },
    saveProjectBaseInformation: (
      state,
      action: {
        payload: {
          summary?: string;
          description?: string;
          highlightDuties?: string[];
          requirements?: string[];
          bidExpirationDate?: number;
          bidStartDate?: number;
          projectStartDate?: number;
          minValue?: number;
          maxValue?: number;
          paymentTerms?: string;
          currency?: string;
          trade?: TradesRequired[];
        };
      }
    ) => {
      state.project = {
        ...state.project,
        ...action.payload,
      };
    },
    resetProjectState: () => {
      initialState = defaultState;
      return defaultState;
    },
    setProjectState: (_, action: { payload: ProjectState }) => {
      return {
        project: {
          ...defaultState.project,
          ...action.payload.project,
        },
      };
    },
    addProjectFile: (
      state,
      action: {
        payload: {
          attachment: ProjectAttachment;
          type: ProjectFileType;
        };
      }
    ) => {
      state.project[action.payload.type]
        ? state.project[action.payload.type].push(action.payload.attachment)
        : (state.project = {
            ...state.project,
            [action.payload.type]: [action.payload.attachment],
          });
    },
    removeProjectFile: (
      state,
      action: {
        payload: { index: number; type: ProjectFileType };
      }
    ) => {
      state.project[action.payload.type].splice(action.payload.index, 1);
    },
    addProjectId: (
      state,
      action: {
        payload: { id?: string };
      }
    ) => {
      if (!action.payload.id) return;
      state.project.projectId = action.payload.id;
    },
    updateProjectFile: (
      state,
      action: {
        payload: {
          index: number;
          type: ProjectFileType;
          file: ProjectAttachment;
          projectId: string;
        };
      }
    ) => {
      if (action.payload.projectId === state.project.projectId) {
        if (
          action.payload.file.name ===
          state.project[action.payload.type][action.payload.index]?.name
        ) {
          state.project[action.payload.type][action.payload.index] =
            action.payload.file;
        } else {
          state.project[action.payload.type].splice(
            action.payload.index,
            0,
            action.payload.file
          );
        }
      }
    },
  },
});

export const {
  saveCompanyId,
  saveProjectContactInformation,
  saveProjectBaseInformation,
  resetProjectState,
  setProjectState,
  addProjectFile,
  removeProjectFile,
  updateProjectFile,
  addProjectId,
} = slice.actions;

export default slice.reducer;

import api from '@loop/api/api';
import { BASE_URL } from '@loop/config/constants';

const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    logOut: build.mutation<void, void>({
      query: () => ({
        url: `${BASE_URL.AUTH}/decline`,
      }),
    }),
  }),
});

export const { useLogOutMutation } = authApi;

import { omit } from 'lodash';

import api from '@loop/api/api';
import { BASE_URL } from '@loop/config/constants';
import { Address } from '@loop/types/address-types';
import {
  CompanyResponse,
  CreateCompanyByAdmin,
  CreateCompanyRequest,
  StateOperatingIn,
} from '@loop/types/company-type';
import { PaginatedData, PaginatedRequest } from '@loop/types/paginated-data';

export interface CompanyLoginResponse {
  company: {
    id: string;
    companyName: string;
    country: string;
    domainName: string;
    postalCode: string;
    address: string;
    stateOperatingIn: StateOperatingIn[];
    phone: string;
    email: string;
    timeZone: string;
    logo: string;
    lastLogin: string;
  };
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

interface UpdateCompanyVarificationRequest {
  id: string;
  isVerified: boolean;
}

interface CompanyUnauthorized {
  id: string;
  companyName: string;
}

interface UpdateCompanyStatusRequest {
  id: string;
  isActive: boolean;
}
interface GetCompanyById {
  id?: string;
}

export interface UpdateCompanyRequest {
  id?: string;
  companyName?: string;
  address?: Address;
  stateOperatingIn?: StateOperatingIn[];
  phone?: string;
  email?: string;
  timeZone?: string;
  logo?: string;
}

export interface CompanyForCheckError {
  error: {
    status: number;
  };
}

export interface CompanyLogoRequest {
  formData: FormData;
  id?: string;
}

const companyApi = api.injectEndpoints({
  endpoints: (build) => ({
    company: build.query<CompanyResponse, void>({
      query: () => ({
        url: `${BASE_URL.COMPANY}/info`,
      }),

      providesTags: ['Company'],
    }),
    companyForCheck: build.mutation<CompanyResponse, void>({
      query: () => ({
        url: `${BASE_URL.COMPANY}/info`,
        params: { by: 'user-id' },
        showToast: false,
      }),
    }),
    companyById: build.query<CompanyResponse, GetCompanyById>({
      query: (values: GetCompanyById) => ({
        url: `${BASE_URL.COMPANY}/info/${values.id}`,
      }),
      providesTags: ['LoopCompany'],
    }),
    loginCompany: build.mutation<CompanyLoginResponse, void>({
      query: () => ({
        url: `${BASE_URL.COMPANY}/login`,
        method: 'GET',
        showToast: false,
      }),
    }),

    createCompany: build.mutation<void, CreateCompanyRequest>({
      query: (values) => ({
        url: `${BASE_URL.COMPANY}/create`,
        method: 'POST',
        body: values,
      }),
    }),
    updateCompany: build.mutation<void, UpdateCompanyRequest>({
      query: (values: UpdateCompanyRequest) => ({
        url: `${BASE_URL.COMPANY}/update`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['Company'],
      onQueryStarted: (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          companyApi.util.updateQueryData('company', undefined, (draft) => {
            Object.assign(draft, patch);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    updateCompanyById: build.mutation<void, UpdateCompanyRequest>({
      query: (values: UpdateCompanyRequest) => ({
        url: `${BASE_URL.COMPANY}/update/${values.id}`,
        method: 'PUT',
        body: omit(values, 'id'),
      }),
      invalidatesTags: ['LoopCompany'],
    }),
    companyLogo: build.mutation<void, CompanyLogoRequest>({
      query: (values: CompanyLogoRequest) => ({
        url: `${BASE_URL.COMPANY}/logo`,
        method: 'POST',
        body: values.formData,
      }),
      invalidatesTags: ['LoopCompany', 'Company'],
    }),
    loopCompanyLogo: build.mutation<void, CompanyLogoRequest>({
      query: (values: CompanyLogoRequest) => ({
        url: `${BASE_URL.COMPANY}/loop/logo`,
        method: 'POST',
        body: values.formData,
      }),
      invalidatesTags: ['LoopCompany', 'Company'],
    }),
    updateCompanyLogoById: build.mutation<void, CompanyLogoRequest>({
      query: (values: CompanyLogoRequest) => ({
        url: `${BASE_URL.COMPANY}/logo/${values.id}`,
        method: 'POST',
        body: values.formData,
      }),
      invalidatesTags: ['LoopCompany', 'Company'],
    }),
    updateCompanyStatus: build.mutation<void, UpdateCompanyStatusRequest>({
      query: (values: UpdateCompanyStatusRequest) => ({
        url: `${BASE_URL.COMPANY}/status/${values.id}`,
        method: 'PUT',
        body: {
          isActive: values.isActive,
        },
      }),
      invalidatesTags: ['LoopCompany'],
    }),
    companyVarification: build.mutation<void, UpdateCompanyVarificationRequest>(
      {
        query: (values: UpdateCompanyVarificationRequest) => ({
          url: `${BASE_URL.COMPANY}/verification/${values.id}`,
          method: 'PUT',
          body: {
            isVerified: values.isVerified,
          },
        }),
        invalidatesTags: ['LoopCompany'],
      }
    ),
    companies: build.query<PaginatedData<CompanyResponse>, PaginatedRequest>({
      query: (params) => ({
        url: `${BASE_URL.COMPANY}/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['LoopCompany'],
    }),
    companiesUnauthorized: build.query<
      PaginatedData<CompanyUnauthorized>,
      PaginatedRequest
    >({
      query: (params) => ({
        url: `${BASE_URL.COMPANY}/unauthorized/all`,
        method: 'POST',
        body: params,
      }),
    }),
    createCompanyByAdmin: build.mutation<void, CreateCompanyByAdmin>({
      query: (values: CreateCompanyByAdmin) => ({
        url: `${BASE_URL.COMPANY}/create/admin`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['LoopCompany'],
    }),
  }),
});

export const {
  useUpdateCompanyLogoByIdMutation,
  useUpdateCompanyByIdMutation,
  useCompanyByIdQuery,
  useCompanyQuery,
  useLoopCompanyLogoMutation,
  useCompanyVarificationMutation,
  useCompaniesQuery,
  useUpdateCompanyStatusMutation,
  useLoginCompanyMutation,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useCompanyLogoMutation,
  useCreateCompanyByAdminMutation,
  useCompanyForCheckMutation,
} = companyApi;

export default companyApi;

import { useHistory } from 'react-router';

import {
  DASHBOARD_URL,
  NOTIFICATION_URL,
  USER_PROFILE_URL,
} from '@loop/constants/routes-constants';
import { useCurrentGroupOfRoles } from '@loop/hooks/role-check';

function useRedirectToDashboard() {
  const history = useHistory();
  const activeGroup = useCurrentGroupOfRoles();

  return () => {
    if (activeGroup) {
      history.push(DASHBOARD_URL[activeGroup]);
    }
  };
}

export function useRouterRedirectToDashboard() {
  const activeGroup = useCurrentGroupOfRoles();

  return activeGroup ? DASHBOARD_URL[activeGroup] : '/login';
}

export function useRedirectToUserProfile() {
  const history = useHistory();
  const activeGroup = useCurrentGroupOfRoles();

  return () => {
    if (activeGroup) {
      history.push(USER_PROFILE_URL[activeGroup]);
    }
  };
}

export function useRedirectToNotificationPage() {
  const history = useHistory();
  const activeGroup = useCurrentGroupOfRoles();

  return () => {
    if (activeGroup) {
      history.push(NOTIFICATION_URL[activeGroup]);
    }
  };
}

export default useRedirectToDashboard;

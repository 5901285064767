export enum COUNTRIES {
  US = 'US',
  GR = 'GR',
}

const COUNTRIES_LOCALIZED_DATE_FORMATS = {
  [COUNTRIES.US]: 'en-US',
  [COUNTRIES.GR]: 'el-GR',
};

export const getDateFormat = (country?: COUNTRIES) => {
  return Intl.DateTimeFormat(
    country
      ? COUNTRIES_LOCALIZED_DATE_FORMATS[country]
      : COUNTRIES_LOCALIZED_DATE_FORMATS[COUNTRIES.US],
    { year: 'numeric', month: 'numeric', day: 'numeric' }
  );
};

export const getTimeFormat = (country?: COUNTRIES) => {
  return Intl.DateTimeFormat(
    country
      ? COUNTRIES_LOCALIZED_DATE_FORMATS[country]
      : COUNTRIES_LOCALIZED_DATE_FORMATS[COUNTRIES.US],
    { timeStyle: 'short' }
  );
};

import { useState } from 'react';

import { PaginatedRequest } from '@loop/types/paginated-data';

export type SetFilters = (
  newFilters:
    | Partial<PaginatedRequest>
    | ((oldFilters: PaginatedRequest) => PaginatedRequest)
) => void;

function usePaginationFilters(defaultFilters: Partial<PaginatedRequest> = {}) {
  const [filters, setFilters] = useState<PaginatedRequest>(defaultFilters);

  const changeFilters: SetFilters = (newFilters) => {
    return typeof newFilters === 'function'
      ? setFilters(newFilters)
      : setFilters((prevFilters: PaginatedRequest) => ({
          ...prevFilters,
          ...newFilters,
        }));
  };

  return [filters, changeFilters] as const;
}

export default usePaginationFilters;

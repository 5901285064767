import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import store from 'store/store';

import { ChakraProvider } from '@chakra-ui/react';

import FullPageLoading from '@loop/components/loading/full-page-loading';
import ConfirmPageLeave from '@loop/context/confirm-page-leave';
import { FullPageLoadingProvider } from '@loop/context/page-loading-context';
import { SuccessPopupProvider } from '@loop/context/success-popup';
import Routes from '@loop/routes/routes';
import theme from '@loop/theme/theme';
import history from '@loop/utils/history';

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <ConfirmPageLeave>
        <Router history={history}>
          <Provider store={store}>
            <SuccessPopupProvider>
              <FullPageLoadingProvider>
                <Suspense fallback={<FullPageLoading />}>
                  <Routes />
                </Suspense>
              </FullPageLoadingProvider>
            </SuccessPopupProvider>
          </Provider>
        </Router>
      </ConfirmPageLeave>
    </ChakraProvider>
  );
}

import { createSlice } from '@reduxjs/toolkit';

import config, { CurrencyCode } from '@loop/config/config';
import { STORE_PATH_NP } from '@loop/constants/store-constatns';
import { IMoney } from '@loop/utils/money';

import {
  QuoteContactInformation,
  QuoteData,
  QuoteErrors,
  QuoteLabor,
  QuoteLabors,
  QuoteLaborsEntries,
  QuoteMaterial,
  QuoteMaterials,
  QuoteMaterialsEntries,
  QuoteOtherCost,
  QuoteOtherCosts,
  QuoteOtherCostsEntries,
  QuotePayment,
  QuoteSiteAssets,
  QuoteSummary,
} from '@loop-np/types/quote-types';

const sliceName = STORE_PATH_NP.QUOTE;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as QuoteData)
  : null;

const defaultState = {
  companyId: '',
  currency: config.preferredCurrency,
  contactInformation: null,
  siteAssets: null,
  summary: null,
  payment: null,
};

let initialState: QuoteData = parsedSavedState || defaultState;

interface QuoteSummaryTotalAndProfitValues {
  totalInvestment: IMoney;
  estimatedProfit: IMoney;
  estimatedProfitInPercent: number;
}

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveCompanyId: (state, action: { payload: string }) => {
      state.companyId = action.payload;
    },
    saveDraftQuoteId: (state, action: { payload: string }) => {
      state.draftQuoteId = action.payload;
    },
    saveCurrency: (
      state,
      action: {
        payload: CurrencyCode;
      }
    ) => {
      state.currency = action.payload;
    },
    saveContactInfo: (
      state,
      action: {
        payload: QuoteContactInformation;
      }
    ) => {
      state.contactInformation = action.payload;
    },
    saveSiteAssets: (state, action: { payload: QuoteSiteAssets }) => {
      state.siteAssets = action.payload;
    },
    saveSiteAssetsMaterials: (
      state,
      action: { payload: QuoteMaterialsEntries | undefined }
    ) => {
      const hasAnyItems =
        action.payload ||
        state.siteAssets?.labors ||
        state.siteAssets?.otherCosts;
      state.siteAssets = hasAnyItems
        ? { ...state.siteAssets, materials: action.payload }
        : null;
    },
    saveSiteAssetsLabors: (
      state,
      action: { payload: QuoteLaborsEntries | undefined }
    ) => {
      const hasAnyItems =
        state.siteAssets?.materials ||
        action.payload ||
        state.siteAssets?.otherCosts;
      state.siteAssets = hasAnyItems
        ? { ...state.siteAssets, labors: action.payload }
        : null;
    },
    saveSiteAssetsOtherCosts: (
      state,
      action: { payload: QuoteOtherCostsEntries | undefined }
    ) => {
      const hasAnyItems =
        state.siteAssets?.materials ||
        state.siteAssets?.labors ||
        action.payload;
      state.siteAssets = hasAnyItems
        ? { ...state.siteAssets, otherCosts: action.payload }
        : null;
    },
    addCategoryForMaterial: (state, action: { payload: QuoteMaterials }) => {
      if (state.siteAssets?.materials) {
        state.siteAssets.materials.entries.push(action.payload);
      } else {
        state.siteAssets = {
          ...state.siteAssets,
          materials: {
            entries: [action.payload],
          },
        };
      }
    },
    addCategoryMaterial: (
      state,
      action: { payload: { material: QuoteMaterial; index: number } }
    ) => {
      if (!!state.siteAssets?.materials) {
        state.siteAssets.materials.entries[action.payload.index].entries.push(
          action.payload.material
        );
      }
    },
    removeQuoteMaterials: (
      state,
      action: { payload: { idsToRemove: string[]; index: number } }
    ) => {
      if (!!state.siteAssets?.materials) {
        state.siteAssets.materials.entries[action.payload.index].entries =
          state.siteAssets.materials.entries[
            action.payload.index
          ].entries.filter((entry) => {
            return !action.payload.idsToRemove.some(
              (idToRemove) => idToRemove === entry.id
            );
          });
      }
    },
    removeQuoteMaterialCategory: (state, action: { payload: number }) => {
      if (!!state.siteAssets?.materials) {
        state.siteAssets.materials.entries.splice(action.payload, 1);
      }
    },

    addCategoryForLabor: (state, action: { payload: QuoteLabors }) => {
      if (state.siteAssets?.labors) {
        state.siteAssets.labors.entries.push(action.payload);
      } else {
        state.siteAssets = {
          ...state.siteAssets,
          labors: {
            entries: [action.payload],
          },
        };
      }
    },
    addCategoryLabor: (
      state,
      action: { payload: { labor: QuoteLabor; index: number } }
    ) => {
      if (!!state.siteAssets?.labors) {
        state.siteAssets.labors.entries[action.payload.index].entries.push(
          action.payload.labor
        );
      }
    },
    removeQuoteLabor: (
      state,
      action: { payload: { idsToRemove: string[]; index: number } }
    ) => {
      if (!!state.siteAssets?.labors) {
        state.siteAssets.labors.entries[action.payload.index].entries =
          state.siteAssets.labors.entries[action.payload.index].entries.filter(
            (entry) => {
              return !action.payload.idsToRemove.some(
                (idToRemove) => idToRemove === entry.id
              );
            }
          );
      }
    },
    removeQuoteLaborCategory: (state, action: { payload: number }) => {
      if (!!state.siteAssets?.labors) {
        state.siteAssets.labors.entries.splice(action.payload, 1);
      }
    },
    addCategoryForOtherCosts: (state, action: { payload: QuoteOtherCosts }) => {
      if (state.siteAssets?.otherCosts) {
        state.siteAssets.otherCosts.entries.push(action.payload);
      } else {
        state.siteAssets = {
          ...state.siteAssets,
          otherCosts: {
            entries: [action.payload],
          },
        };
      }
    },
    addCategoryOtherCosts: (
      state,
      action: { payload: { otherCost: QuoteOtherCost; index: number } }
    ) => {
      if (!!state.siteAssets?.otherCosts) {
        state.siteAssets.otherCosts.entries[action.payload.index].entries.push(
          action.payload.otherCost
        );
      }
    },
    removeQuoteOtherCosts: (
      state,
      action: { payload: { idsToRemove: string[]; index: number } }
    ) => {
      if (!!state.siteAssets?.otherCosts) {
        state.siteAssets.otherCosts.entries[action.payload.index].entries =
          state.siteAssets.otherCosts.entries[
            action.payload.index
          ].entries.filter((entry) => {
            return !action.payload.idsToRemove.some(
              (idToRemove) => idToRemove === entry.id
            );
          });
      }
    },
    removeQuoteOtherCostsCategory: (state, action: { payload: number }) => {
      if (!!state.siteAssets?.otherCosts) {
        state.siteAssets.otherCosts.entries.splice(action.payload, 1);
      }
    },

    setQuoteSummary: (state, action: { payload: QuoteSummary | null }) => {
      state.summary = action.payload;
    },
    setQuoteSummaryRebate: (state, action: { payload: IMoney }) => {
      if (state.summary) {
        state.summary.additionalCost.rebate = action.payload;
      }
    },
    setQuoteSummaryShippingCost: (state, action: { payload: IMoney }) => {
      if (state.summary) {
        state.summary.additionalCost.shippingCost = action.payload;
      }
    },
    setQuoteSummaryTotalAndProfitValues: (
      state,
      action: { payload: QuoteSummaryTotalAndProfitValues }
    ) => {
      if (state.summary) {
        state.summary = {
          ...state.summary,
          ...action.payload,
        };
      }
    },
    savePaymentInfo: (state, action: { payload: QuotePayment }) => {
      state.payment = action.payload;
    },

    setQuoteErrors: (state, action: { payload: QuoteErrors }) => {
      state.errors = action.payload;
    },
    resetQuoteState: () => {
      initialState = defaultState;
      return defaultState;
    },
    setQuoteState: (state, action: { payload: QuoteData }) => ({
      companyId: state.companyId,
      ...action.payload,
    }),
    saveQuoteLeadId: (state, action: { payload: string | undefined }) => ({
      ...state,
      leadId: action.payload,
    }),
    setQuoteFromLeadState: (state, action: { payload: any }) => ({
      ...state,
      companyId: state.companyId,
      ...action.payload,
      leadId: state.leadId,
    }),
  },
});

export const {
  saveCompanyId,
  saveDraftQuoteId,
  saveContactInfo,
  setQuoteSummaryTotalAndProfitValues,
  setQuoteSummaryRebate,
  setQuoteSummaryShippingCost,
  setQuoteSummary,
  setQuoteErrors,
  resetQuoteState,
  setQuoteState,
  setQuoteFromLeadState,
  saveQuoteLeadId,
  savePaymentInfo,
  saveCurrency,
  saveSiteAssets,
  addCategoryForMaterial,
  addCategoryMaterial,
  removeQuoteMaterials,
  removeQuoteMaterialCategory,
  addCategoryForLabor,
  addCategoryLabor,
  removeQuoteLaborCategory,
  removeQuoteLabor,
  saveSiteAssetsMaterials,
  addCategoryForOtherCosts,
  addCategoryOtherCosts,
  removeQuoteOtherCostsCategory,
  removeQuoteOtherCosts,
  saveSiteAssetsLabors,
  saveSiteAssetsOtherCosts,
} = slice.actions;

export default slice.reducer;

import { useTranslation } from 'react-i18next';

import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { ReactComponent as XCircle } from '@loop/assets/img/icons/x-circle.svg';

interface Props {
  isOpen: boolean;
  text: string;
  onClose: (isCloseWithRedirect?: boolean) => void;
}
function SuccessModal({ isOpen, text, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
        <ModalOverlay />
        <ModalContent p={2} pb={5}>
          <ModalHeader color="grey.600" fontWeight="500" fontSize="xl" pb={4}>
            <Flex justifyContent="space-between">
              {t('Success!')}
              <IconButton
                icon={<XCircle />}
                aria-label={'Close'}
                backgroundColor="white"
                w={7}
                h={7}
                minW={6}
                onClick={() => {
                  onClose();
                }}
              />
            </Flex>
          </ModalHeader>

          <ModalBody>{text}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
export default SuccessModal;

import { useTranslation } from 'react-i18next';

import { StatusDescription } from '@loop/components/common/ui/status-handler';

export const SERVICE_STATUSES = {
  PENDING: 'pending',
  IN_PROGRESS: 'in progress',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const useServicesConstants = () => {
  const { t } = useTranslation();

  const statusesList: StatusDescription[] = [
    {
      name: t('services:Pending'),
      value: SERVICE_STATUSES.PENDING,
      color: 'yellow',
    },

    {
      name: t('services:In Progress'),
      value: SERVICE_STATUSES.IN_PROGRESS,
      color: 'blue.400',
    },
    {
      name: t('services:Rejected'),
      value: SERVICE_STATUSES.REJECTED,
      color: 'red',
    },

    {
      name: t('services:Canceled'),
      value: SERVICE_STATUSES.CANCELED,
      color: 'red',
    },

    {
      name: t('services:Completed'),
      value: SERVICE_STATUSES.COMPLETED,
      color: 'primary.500',
    },
  ];

  const getListOfStatusesByCurrentStatusServices = (status: string) => {
    switch (status) {
      case 'pending':
        return [
          {
            name: t('services:Pending'),
            value: SERVICE_STATUSES.PENDING,
            color: 'yellow',
          },

          {
            name: t('services:In Progress'),
            value: SERVICE_STATUSES.IN_PROGRESS,
            color: 'blue.400',
          },
          {
            name: t('services:Rejected'),
            value: SERVICE_STATUSES.REJECTED,
            color: 'red',
          },
        ];
      case 'in progress':
        return [
          {
            name: t('services:In Progress'),
            value: SERVICE_STATUSES.IN_PROGRESS,
            color: 'blue.400',
          },
          {
            name: t('services:Canceled'),
            value: SERVICE_STATUSES.CANCELED,
            color: 'red',
          },

          {
            name: t('services:Completed'),
            value: SERVICE_STATUSES.COMPLETED,
            color: 'primary.500',
          },
        ];

      default:
        return statusesList;
    }
  };

  return {
    getListOfStatusesByCurrentStatusServices,
    statusesList,
  };
};

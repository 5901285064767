export const emailRegex =
  /(?=^.{0,50}$)(^(?!.*[\u0400-\u04FF])(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
export const routingNumberRegex = /^[012346789][0-9]{8}$/;

export const videoUrlRegex =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

export const landingProductUrlRegex =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

export const PASSWORD_PATTERN = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

interface Language {
  language: string;
  code: string;
  fontFamily: string;
}

export const languages: Language[] = [
  {
    language: 'English',
    code: 'en-GB',
    fontFamily: 'Rubik',
  },
  {
    language: 'Ελληνικά',
    code: 'el-GR',
    fontFamily: 'Manrope',
  },
];

const COMPANY_NAME_SPECIAL_CHARACTERS = {
  ampersand: '&',
};

const SPECIAL_CHARACTERS_LIST = Object.values(
  COMPANY_NAME_SPECIAL_CHARACTERS
).join('');

// min length 1, max 255, all letters and numbers, symbol &
export const COMPANY_NAME_PATTERN = new RegExp(
  `(?=^.{1,255}$)^([a-zA-Z0-9${SPECIAL_CHARACTERS_LIST}]+\\s)*[a-zA-Z0-9${SPECIAL_CHARACTERS_LIST}]+$`
);

export const loopCompanyId = '77777777-7777-7777-7777-777777777777';

export const countries: { [key: string]: string } = {
  US: 'USA',
  GR: 'Greece',
};

export const imageFormats = ['image/jpg', 'image/jpeg', 'image/png'];

export const PREVIEW_TYPES = {
  QUOTE: 'Quote',
  INVOICE: 'Invoice',
  ORDER: 'Order',
};

export const TERMS_AND_CONDITIONS_CATEGORIES_TYPES = {
  QUOTE: 'quote',
  INVOICE: 'invoice',
  PROPOSAL: 'proposal',
  GENERAL: 'general',
};

export const generalTermAndConditionId = 'dbf137d7-45d8-476f-88dd-7cfbf8353af2';

export enum LEAD_TYPE {
  COMMERCIAL = 'commercial',
  RESIDENTIAL = 'residential',
}

export const NOT_EDITABLE_LABORS_IDS = [
  '26284449-62d5-4a7c-9bfd-a097dbee70f0',
  'c18c69ba-35a0-4f67-89d2-b334aa24dd31',
];

export const BASE_URL = {
  ADDRESS: 'services/public/unauthorized/address',
  AUTH: 'authorization/public/token',
  CHARGER_TYPE: 'product/public/charger-type',
  COMPANY: 'company/public',
  CURRENCY: 'services/public/currency',
  DISTRIBUTOR: 'product/public/distributor',
  INVOICE: 'product/public/invoice',
  INVOICE_DRAFT: 'product/public/draft-invoice',
  LABOR: 'product/public/labor',
  LABOR_NP: 'product/public/np-labor',
  LANDING_PRODUCT: 'services/public/admin-panel/admin/product',
  LANDING_PRODUCT_UNAUTHORIZED:
    'services/public/unauthorized/admin-panel/product',
  MARKETING_IMAGES: 'trainings/public/marketing-image',
  NOTIFICATION: 'notification/public/notification',
  PRODUCT: 'product/public/product',
  PRODUCT_NP: 'product/public/np-product',
  PRODUCT_UNAUTHORIZED: 'product/public/unauthorized/product',
  PROJECT: 'services/public/project',
  PROJECT_BID: 'services/public/bid',
  PROPOSAL_ASSUMPTION: 'proposal/public/assumptions',
  PROPOSAL: 'proposal/public',
  PURCHASE_ORDER: 'product/public/purchase',
  QUOTE: 'product/public/quote',
  QUOTE_DRAFT: 'product/public/draft-quote',
  SERVICE: 'services/public/services',
  SERVICE_ORDERS: 'services/public/orders-service',
  TERMS_AND_CONDITIONS: 'services/public/terms-and-conditions',
  TERMS_AND_CONDITIONS_UNAUTHORIZED:
    'services/public/unauthorized/terms-and-conditions',
  TRAININGS_IMAGES: 'trainings/public/image',
  TRAININGS_CATEGORY: 'trainings/public/category',
  TRAININGS_DOCUMENT: 'trainings/public/document',
  TRAININGS_FAQ: 'trainings/public/faq',
  TRAININGS_MARKETING: 'trainings/public/marketing',
  TRAININGS_MARKETING_IMAGE: 'trainings/public/marketing-image',
  USER: 'user/public',
  ORGANIZATIONS: 'organization/public',
  LANDING_PRODUCT_NP: 'services/public/admin-panel/product',
  LANDING_PAGE_NP: 'services/public/admin-panel/landing-setting',
  LANDING_PAGE_NP_UNAUTHORIZED:
    'services/public/unauthorized/admin-panel/landing-setting',
  USER_UNAUTORIZED: 'user/public/unauthorized',
  LEAD: 'crm/public/lead',
  LEAD_COMMON: 'crm/public/lead-common',
  LOGS: 'crm/public/log',
  ACTIVITY: 'crm/public/activity',
  INSTALLATION_SITE: 'crm/public/installation-site',
  ATTACHMENT: 'crm/public/attachment/lead',
  INSTALLATION_SITE_ATTACHMENT: 'crm/public/attachment/installation-site',
  COMMERCIAL_LEAD: 'crm/public/lead-commercial',
  RESIDENTIAL_LEAD: 'crm/public/lead-residential',
  COMMERCIAL_CUSTOMER: 'crm/public/customer-commercial',
  RESIDENTIAL_CUSTOMER: 'crm/public/customer-residential',
  COMMON_CUSTOMER: 'crm/public/customer-common',
  CONTACT: 'crm/public/contact',
  EMBEDDED_FORM: 'crm/public/embedded-form',
};

export enum FILE_SIZE_LIMITS_MB {
  DEFAULT_DOC = 25,
  DEFAULT_IMAGE = 10,
  COMPANY_LOGO = 1,
  TRAININGS_MARKETING_IMAGE = 20,
  PROJECT_FILE = 30,
}

export enum REQUEST_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { USER_ROLES } from '@loop/constants/user-roles';
import useRoleCheck from '@loop/hooks/role-check';

import {
  useGetEmbeddedFormNPQuery,
  useGetEmbeddedFormQuery,
} from '@loop-crm/api/contact-form';
import ContactFormFieldsPreview from '@loop-crm/features/settings/embedded-form-config/components/contact-form-fields-preview';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LandingPageContactFormPreview = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const isLSA = useRoleCheck([USER_ROLES.LOOP_SUPER_ADMIN]);
  const isNPAdmin = useRoleCheck([USER_ROLES.NP_ADMIN]);

  const { data: contactFormLoop } = useGetEmbeddedFormQuery(undefined, {
    skip: !isLSA,
  });
  const { data: contactFormNP } = useGetEmbeddedFormNPQuery(undefined, {
    skip: !isNPAdmin,
  });

  const contactForm = contactFormLoop || contactFormNP;

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent p="32px" maxW={'700px'}>
        <ModalHeader pb={4}>
          <HStack justifyContent="space-between">
            <Text>{t('settings:Form Preview')}</Text>
            <Text
              cursor="pointer"
              color="primary.600"
              textDecoration="underline"
              onClick={onClose}
            >
              {t('settings:Close')}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalBody>
          {contactForm && (
            <Box
              borderWidth="1px"
              borderRadius="md"
              paddingX="44px"
              paddingY="36px"
              bg={contactForm.styles.backgroundColor}
              color={contactForm.styles.labelTextColor}
              fontFamily={contactForm.styles.fontFamily}
            >
              <Heading textAlign="center" fontSize="lg" mb="34px">
                {contactForm.title}
              </Heading>
              <ContactFormFieldsPreview
                fields={contactForm.fields}
                styles={contactForm.styles}
              />
              <Center mt={8}>
                <Button
                  bg={contactForm.styles.buttonColor}
                  color={contactForm.styles.buttonTextColor}
                  _hover={{
                    backGroundColor: contactForm.styles.buttonColor,
                  }}
                  _active={{
                    backGroundColor: contactForm.styles.buttonColor,
                  }}
                  width={'134px'}
                  isDisabled
                >
                  {t('Send')}
                </Button>
              </Center>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LandingPageContactFormPreview;

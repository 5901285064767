import api from '@loop/api/api';
import { BASE_URL } from '@loop/config/constants';
import { Notification } from '@loop/types/notifications';
import { PaginatedData, PaginatedRequest } from '@loop/types/paginated-data';

export interface NotificationsRequest extends PaginatedRequest {
  categories?: string[];
  isUnreadOnly: boolean;
}

type NotificationsResponse = PaginatedData<Notification>;

interface UpdateNotificationStatus {
  id: string;
  isRead: boolean;
}

export interface SettingsResponse {
  type: string;
  isActive: boolean;
  description: string;
}

export interface NotificationSettingsRequest {
  language: string;
}

const notificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    notifications: build.query<NotificationsResponse, NotificationsRequest>({
      query: (params: NotificationsRequest) => ({
        url: `${BASE_URL.NOTIFICATION}/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['Notifications'],
    }),
    refetchNotifications: build.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['Notifications'],
    }),
    markAllAsRead: build.mutation<void, void>({
      query: () => ({
        url: `${BASE_URL.NOTIFICATION}/mark-all-as-read`,
      }),
      invalidatesTags: ['Notifications'],
    }),

    allNotificationsCategories: build.query<string[], void>({
      query: () => ({
        url: `${BASE_URL.NOTIFICATION}/all-categories`,
      }),
    }),

    notificationsSettings: build.query<
      SettingsResponse[],
      NotificationSettingsRequest
    >({
      query: (params) => ({
        url: `${BASE_URL.NOTIFICATION}/settings`,
        params: params,
      }),
      providesTags: ['NotificationsSettings'],
    }),

    updateNotificationSettings: build.mutation<void, SettingsResponse[]>({
      query: (values) => ({
        url: `${BASE_URL.NOTIFICATION}/settings`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['NotificationsSettings'],
    }),

    updateNotificationStatus: build.mutation<void, UpdateNotificationStatus>({
      query: (values) => ({
        url: `${BASE_URL.NOTIFICATION}/${values.id}`,
        method: 'PUT',
        body: {
          isRead: values.isRead,
        },
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useNotificationsQuery,
  useUpdateNotificationStatusMutation,
  useMarkAllAsReadMutation,
  useAllNotificationsCategoriesQuery,
  useNotificationsSettingsQuery,
  useUpdateNotificationSettingsMutation,
  useRefetchNotificationsMutation,
} = notificationApi;

export default notificationApi;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { STORE_PATH_CORE } from '@loop/constants/store-constatns';

const initialState = 0;

const sliceName = STORE_PATH_CORE.NOTIFICATIONS;

export const notificationsSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: {
    'auth/resetAllState': () => 0,
  },
  reducers: {
    setNotificationsCount: (state, action: PayloadAction<number>) =>
      action.payload,
    increaseNotificationsCount: (state) => state + 1,
    decreaseNotificationsCount: (state) => state - 1,
    resetNotificationState: () => 0,
  },
});

export const {
  setNotificationsCount,
  increaseNotificationsCount,
  decreaseNotificationsCount,
  resetNotificationState,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import CompanyInfo from '@loop-np/features/services/components/сompany-info';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
}

function CompanyDetailsModal({ isOpen, onClose, companyId }: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent p={5}>
        <Box as="form">
          <ModalHeader color="grey.600" fontWeight="500" fontSize="2xl" pb={8}>
            {t('services:Company Details')}
          </ModalHeader>
          <ModalBody>
            <CompanyInfo companyId={companyId} />
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Button
              onClick={() => {
                onClose();
              }}
              fontWeight="500"
              colorScheme="primary"
              h="40px"
              w="120px"
            >
              {t('Close')}
            </Button>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default CompanyDetailsModal;

import { createSlice } from '@reduxjs/toolkit';

import config, { CurrencyCode } from '@loop/config/config';
import { STORE_PATH_NP } from '@loop/constants/store-constatns';
import { IMoney } from '@loop/utils/money';

import {
  InvoiceContactInformation,
  InvoiceData,
  InvoiceErrors,
  InvoiceLabor,
  InvoiceLabors,
  InvoiceLaborsEntries,
  InvoiceMaterial,
  InvoiceMaterials,
  InvoiceMaterialsEntries,
  InvoiceOtherCost,
  InvoiceOtherCosts,
  InvoiceOtherCostsEntries,
  InvoicePayment,
  InvoiceSiteAssets,
  InvoiceSummary,
} from '@loop-np/types/invoice-types';

const sliceName = STORE_PATH_NP.INVOICE;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as InvoiceData)
  : null;

const defaultState = {
  companyId: '',
  currency: config.preferredCurrency,
  contactInformation: null,
  siteAssets: null,
  summary: null,
  payment: null,
};

let initialState: InvoiceData = parsedSavedState || defaultState;

interface InvoiceSummaryTotalAndProfitValues {
  totalInvestment: IMoney;
  estimatedProfit: IMoney;
  estimatedProfitInPercent: number;
}

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveCompanyId: (state, action: { payload: string }) => {
      state.companyId = action.payload;
    },
    saveDraftInvoiceId: (state, action: { payload: string }) => {
      state.draftInvoiceId = action.payload;
    },
    saveCurrency: (
      state,
      action: {
        payload: CurrencyCode;
      }
    ) => {
      state.currency = action.payload;
    },
    saveContactInfo: (
      state,
      action: {
        payload: InvoiceContactInformation;
      }
    ) => {
      state.contactInformation = action.payload;
    },
    saveSiteAssets: (state, action: { payload: InvoiceSiteAssets }) => {
      state.siteAssets = action.payload;
    },
    saveSiteAssetsMaterials: (
      state,
      action: { payload: InvoiceMaterialsEntries | undefined }
    ) => {
      const hasAnyItems =
        action.payload ||
        state.siteAssets?.labors ||
        state.siteAssets?.otherCosts;
      state.siteAssets = hasAnyItems
        ? { ...state.siteAssets, materials: action.payload }
        : null;
    },
    saveSiteAssetsLabors: (
      state,
      action: { payload: InvoiceLaborsEntries | undefined }
    ) => {
      const hasAnyItems =
        state.siteAssets?.materials ||
        action.payload ||
        state.siteAssets?.otherCosts;
      state.siteAssets = hasAnyItems
        ? { ...state.siteAssets, labors: action.payload }
        : null;
    },
    saveSiteAssetsOtherCosts: (
      state,
      action: { payload: InvoiceOtherCostsEntries | undefined }
    ) => {
      const hasAnyItems =
        state.siteAssets?.materials ||
        state.siteAssets?.labors ||
        action.payload;
      state.siteAssets = hasAnyItems
        ? { ...state.siteAssets, otherCosts: action.payload }
        : null;
    },
    addCategoryForMaterial: (state, action: { payload: InvoiceMaterials }) => {
      if (state.siteAssets?.materials) {
        state.siteAssets.materials.entries.push(action.payload);
      } else {
        state.siteAssets = {
          ...state.siteAssets,
          materials: {
            entries: [action.payload],
          },
        };
      }
    },
    addCategoryMaterial: (
      state,
      action: { payload: { material: InvoiceMaterial; index: number } }
    ) => {
      if (!!state.siteAssets?.materials) {
        state.siteAssets.materials.entries[action.payload.index].entries.push(
          action.payload.material
        );
      }
    },
    removeInvoiceMaterials: (
      state,
      action: { payload: { idsToRemove: string[]; index: number } }
    ) => {
      if (!!state.siteAssets?.materials) {
        state.siteAssets.materials.entries[action.payload.index].entries =
          state.siteAssets.materials.entries[
            action.payload.index
          ].entries.filter((entry) => {
            return !action.payload.idsToRemove.some(
              (idToRemove) => idToRemove === entry.id
            );
          });
      }
    },
    removeInvoiceMaterialCategory: (state, action: { payload: number }) => {
      if (!!state.siteAssets?.materials) {
        state.siteAssets.materials.entries.splice(action.payload, 1);
      }
    },

    addCategoryForLabor: (state, action: { payload: InvoiceLabors }) => {
      if (state.siteAssets?.labors) {
        state.siteAssets.labors.entries.push(action.payload);
      } else {
        state.siteAssets = {
          ...state.siteAssets,
          labors: {
            entries: [action.payload],
          },
        };
      }
    },
    addCategoryLabor: (
      state,
      action: { payload: { labor: InvoiceLabor; index: number } }
    ) => {
      if (!!state.siteAssets?.labors) {
        state.siteAssets.labors.entries[action.payload.index].entries.push(
          action.payload.labor
        );
      }
    },
    removeInvoiceLabor: (
      state,
      action: { payload: { idsToRemove: string[]; index: number } }
    ) => {
      if (!!state.siteAssets?.labors) {
        state.siteAssets.labors.entries[action.payload.index].entries =
          state.siteAssets.labors.entries[action.payload.index].entries.filter(
            (entry) => {
              return !action.payload.idsToRemove.some(
                (idToRemove) => idToRemove === entry.id
              );
            }
          );
      }
    },
    removeInvoiceLaborCategory: (state, action: { payload: number }) => {
      if (!!state.siteAssets?.labors) {
        state.siteAssets.labors.entries.splice(action.payload, 1);
      }
    },
    addCategoryForOtherCosts: (
      state,
      action: { payload: InvoiceOtherCosts }
    ) => {
      if (state.siteAssets?.otherCosts) {
        state.siteAssets.otherCosts.entries.push(action.payload);
      } else {
        state.siteAssets = {
          ...state.siteAssets,
          otherCosts: {
            entries: [action.payload],
          },
        };
      }
    },
    addCategoryOtherCosts: (
      state,
      action: { payload: { otherCost: InvoiceOtherCost; index: number } }
    ) => {
      if (!!state.siteAssets?.otherCosts) {
        state.siteAssets.otherCosts.entries[action.payload.index].entries.push(
          action.payload.otherCost
        );
      }
    },
    removeInvoiceOtherCosts: (
      state,
      action: { payload: { idsToRemove: string[]; index: number } }
    ) => {
      if (!!state.siteAssets?.otherCosts) {
        state.siteAssets.otherCosts.entries[action.payload.index].entries =
          state.siteAssets.otherCosts.entries[
            action.payload.index
          ].entries.filter((entry) => {
            return !action.payload.idsToRemove.some(
              (idToRemove) => idToRemove === entry.id
            );
          });
      }
    },
    removeInvoiceOtherCostsCategory: (state, action: { payload: number }) => {
      if (!!state.siteAssets?.otherCosts) {
        state.siteAssets.otherCosts.entries.splice(action.payload, 1);
      }
    },

    setInvoiceSummary: (state, action: { payload: InvoiceSummary | null }) => {
      state.summary = action.payload;
    },
    setInvoiceSummaryRebate: (state, action: { payload: IMoney }) => {
      if (state.summary) {
        state.summary.additionalCost.rebate = action.payload;
      }
    },
    setInvoiceSummaryShippingCost: (state, action: { payload: IMoney }) => {
      if (state.summary) {
        state.summary.additionalCost.shippingCost = action.payload;
      }
    },
    setInvoiceSummaryTotalAndProfitValues: (
      state,
      action: { payload: InvoiceSummaryTotalAndProfitValues }
    ) => {
      if (state.summary) {
        state.summary = {
          ...state.summary,
          ...action.payload,
        };
      }
    },
    savePaymentInfo: (state, action: { payload: InvoicePayment }) => {
      state.payment = action.payload;
    },

    setInvoiceErrors: (state, action: { payload: InvoiceErrors }) => {
      state.errors = action.payload;
    },
    resetInvoiceState: () => {
      initialState = defaultState;
      return defaultState;
    },
    setInvoiceState: (state, action: { payload: InvoiceData }) => ({
      companyId: state.companyId,
      ...action.payload,
    }),
    saveInvoiceLeadId: (state, action: { payload: string | undefined }) => ({
      ...state,
      leadId: action.payload,
    }),
    setInvoiceFromLeadState: (state, action: { payload: any }) => ({
      ...state,
      companyId: state.companyId,
      ...action.payload,
      leadId: state.leadId,
    }),
  },
});

export const {
  saveCompanyId,
  saveDraftInvoiceId,
  saveContactInfo,
  setInvoiceSummaryTotalAndProfitValues,
  setInvoiceSummaryRebate,
  setInvoiceSummaryShippingCost,
  setInvoiceSummary,
  setInvoiceErrors,
  resetInvoiceState,
  setInvoiceState,
  setInvoiceFromLeadState,
  saveInvoiceLeadId,
  savePaymentInfo,
  saveCurrency,
  saveSiteAssets,
  addCategoryForMaterial,
  addCategoryMaterial,
  removeInvoiceMaterials,
  removeInvoiceMaterialCategory,
  addCategoryForLabor,
  addCategoryLabor,
  removeInvoiceLaborCategory,
  removeInvoiceLabor,
  saveSiteAssetsMaterials,
  addCategoryForOtherCosts,
  addCategoryOtherCosts,
  removeInvoiceOtherCostsCategory,
  removeInvoiceOtherCosts,
  saveSiteAssetsLabors,
  saveSiteAssetsOtherCosts,
} = slice.actions;

export default slice.reducer;

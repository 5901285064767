import { createSlice } from '@reduxjs/toolkit';

import { STORE_PATH_CRM } from '@loop/constants/store-constatns';

import {
  CreateResidentialLead,
  ResidentialAboutLeadForm,
} from '@loop-crm/features/residential-leads/types/residential-leads-type';
import { CreateLeadForm } from '@loop-crm/types/contact-type';
import { GeneralInfo, InstallationSite } from '@loop-crm/types/leads-type';

const sliceNameResidential = STORE_PATH_CRM.RESIDENTIAL_LEADS;

const savedState = localStorage.getItem(sliceNameResidential);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as CreateResidentialLead)
  : null;

const defaultState = {
  aboutLead: null,
  generalInfo: null,
  contactPerson: null,
  installationSite: null,
};

let initialState: CreateResidentialLead = parsedSavedState || defaultState;

const sliceResidential = createSlice({
  name: sliceNameResidential,
  initialState,
  reducers: {
    saveCompanyIdForResidential: (state, action: { payload: string }) => {
      state.companyId = action.payload;
    },
    saveAboutResidentialLead: (
      state,
      action: { payload: ResidentialAboutLeadForm }
    ) => {
      state.aboutLead = action.payload;
    },
    saveContactPersonResidential: (
      state,
      action: { payload: CreateLeadForm | undefined }
    ) => {
      state.contactPerson = action.payload;
    },
    saveInstallationSiteResidential: (
      state,
      action: { payload: InstallationSite }
    ) => {
      state.installationSite = action.payload;
    },
    saveGeneralInfoResidential: (state, action: { payload: GeneralInfo }) => {
      state.generalInfo = action.payload;
    },
    resetLeadStateResidential: (state) => {
      state.aboutLead = null;
      state.installationSite = null;
      state.generalInfo = null;
      state.contactPerson = null;
    },
    resetCompanyId: (state) => {
      state.companyId = undefined;
    },
  },
});
export const {
  saveCompanyIdForResidential,
  saveAboutResidentialLead,
  saveContactPersonResidential,
  saveInstallationSiteResidential,
  saveGeneralInfoResidential,
  resetLeadStateResidential,
  resetCompanyId,
} = sliceResidential.actions;
export default sliceResidential.reducer;

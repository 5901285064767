import exchangeRateApi, {
  ApiExchangeRates,
  ExchangeRates,
} from '@loop/api/common/exchange-rate';
import { CurrencyCode } from '@loop/config/config';
import store from '@loop/store/store';

export interface IMoney {
  value: number;
  currency: CurrencyCode;
}

export default class Money {
  current: IMoney | null;
  exchangeRates?: ApiExchangeRates;
  exchangeTo?: CurrencyCode;

  constructor(
    value: Partial<IMoney> | Money | null,
    exchangeRates?: ApiExchangeRates,
    exchangeTo?: CurrencyCode
  ) {
    this.exchangeRates = exchangeRates;
    this.exchangeTo = exchangeTo;
    const normalizedValue = this.normalizeValue(value);
    this.current = normalizedValue
      ? {
          currency: normalizedValue.currency,
          value: parseFloat(normalizedValue.value.toFixed(2)),
        }
      : null;
  }

  normalizeValue(value: Partial<IMoney> | Money | null): IMoney | null {
    if (
      value &&
      this.exchangeTo &&
      (value instanceof Money ||
        (value.value !== undefined && value.currency !== undefined))
    ) {
      const money =
        value instanceof Money
          ? value.toPrice()
          : ({
              value: parseFloat(Number(value.value).toFixed(2)),
              currency: value.currency,
            } as IMoney);
      return Money.exchange(money, this.exchangeTo);
    }
    return null;
  }

  static exchange(money: IMoney | null, to?: CurrencyCode) {
    if (!money) return null;

    const from = money.currency;

    if (from === to) return money;

    const rates = exchangeRateApi.endpoints.exchangeRates.select(
      to as CurrencyCode
    )(store.getState()).data;

    const rate = rates?.quotes?.[`${to as CurrencyCode}${from}`];

    if (!rates || !rate) return null;

    const value = parseFloat(((money.value * 100) / (rate * 100)).toFixed(2));
    return {
      value,
      currency: to as CurrencyCode,
    };
  }

  static exchangeByRate(
    money: IMoney | null,
    rates: ExchangeRates,
    to?: CurrencyCode
  ) {
    if (!money) return null;

    const from = money.currency;

    if (from === to) return money;

    const rate = rates?.[`${from}${to as CurrencyCode}`];

    if (!rate) return null;

    const value = parseFloat((money.value * rate).toFixed(2));

    return {
      value,
      currency: to as CurrencyCode,
    };
  }

  add(price: IMoney | Money | null) {
    const money = this.normalizeValue(price);
    const copy = this.clone();
    if (!money) return copy;
    if (!copy.current) {
      copy.current = null;
      return copy;
    }
    copy.current.value = parseFloat(
      (copy.current.value + money.value).toFixed(2)
    );
    return copy;
  }

  sub(price: IMoney | Money | null) {
    const money = this.normalizeValue(price);
    const copy = this.clone();
    if (!money) return copy;
    if (!copy.current) {
      copy.current = null;
      return copy;
    }
    copy.current.value = parseFloat(
      (copy.current.value - money.value).toFixed(2)
    );
    return copy;
  }

  percent(percent: number) {
    const copy = this.clone();
    if (!copy.current) return copy;
    const scaledValue = copy.current.value * 100 * percent;
    copy.current.value = parseFloat((scaledValue / 10000).toFixed(2));
    return copy;
  }

  addPercent(percent: number) {
    const copy = this.clone();
    if (!copy.current) return copy;
    const scaledValue = copy.current.value * 100 * (100 + percent);
    copy.current.value = parseFloat((scaledValue / 10000).toFixed(2));
    return copy;
  }

  subPercent(percent: number) {
    const copy = this.clone();
    if (!copy.current) return copy;
    const scaledValue = copy.current.value * 100 * (100 - percent);
    copy.current.value = parseFloat((scaledValue / 10000).toFixed(2));
    return copy;
  }

  percentOf(value: IMoney | Money | null) {
    const money = this.normalizeValue(value);

    if (!this.current || !money) return null;
    const scaledValue = (this.current.value * 100) / (money.value * 100);
    return parseFloat((scaledValue * 100).toFixed(2));
  }

  multiply(by: number) {
    const copy = this.clone();
    if (!copy.current) return copy;
    const scaledValue = copy.current.value * 100 * (by * 100);
    copy.current.value = parseFloat((scaledValue / 10000).toFixed(2));
    return copy;
  }

  divide(by: number) {
    const copy = this.clone();
    if (!copy.current) return copy;
    const scaledValue = (copy.current.value * 100) / (by * 100);
    copy.current.value = parseFloat(scaledValue.toFixed(2));
    return copy;
  }

  equalsTo(price: IMoney | Money | null) {
    const money = this.normalizeValue(price);
    if (!money) return null;

    if (!this.current || !money) return false;
    return this.current.value === money.value;
  }

  isGreaterThan(price: IMoney | Money | null) {
    const money = this.normalizeValue(price);
    if (!money) return null;

    if (!this.current || !money) return false;
    return this.current.value > money.value;
  }

  isLowerThan(price: IMoney | Money | null) {
    const money = this.normalizeValue(price);
    if (!money) return null;

    if (!this.current || !money) return false;
    return this.current.value < money.value;
  }

  isGreaterOrEqualsTo(value: IMoney | Money | null) {
    return this.equalsTo(value) || this.isGreaterThan(value);
  }

  isLowerOrEqualsTo(value: IMoney | Money | null) {
    return this.equalsTo(value) || this.isGreaterThan(value);
  }

  toPrice() {
    return this.current ? ({ ...this.current } as IMoney) : null;
  }

  clone() {
    return new Money(this, this.exchangeRates, this.exchangeTo);
  }
}

import { createSlice } from '@reduxjs/toolkit';
import { update } from 'lodash';

import { STORE_PATH_CRM } from '@loop/constants/store-constatns';

import { ContactFormConfigs } from '@loop-crm/features/settings/types/embedded-form-types';

const sliceName = STORE_PATH_CRM.CRM_EMBEDDED_FORM_CONFIG;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as ContactFormConfigs)
  : null;

const defaultState = {
  limits: {
    inputText: 5,
    textArea: 5,
    numberInput: 5,
    dropdownList: 5,
    agreementConsent: 1,
  },
  amountOfFields: {},
  totalFieldsAmount: 0,
};

let initialState: ContactFormConfigs = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setAmountsOfFields: (
      state,
      action: { payload: { [key: string]: number } }
    ) => {
      state.amountOfFields = action.payload;
    },
    increaseFieldAmount: (state, action: { payload: string }) => {
      state.amountOfFields = update(
        state.amountOfFields,
        action.payload,
        (amount) => (amount ? amount + 1 : 1)
      );
    },
    decreaseFieldAmount: (state, action: { payload: string }) => {
      update(state.amountOfFields, action.payload, (amount) => amount - 1);
    },
    setTotalFieldsAmount: (state, action: { payload: number }) => {
      state.totalFieldsAmount = action.payload;
    },
    increaseTotalFieldAmount: (state) => {
      state.totalFieldsAmount = state.totalFieldsAmount + 1;
    },
    decreaseTotalFieldAmount: (state) => {
      state.totalFieldsAmount = state.totalFieldsAmount - 1;
    },
  },
});

export const {
  setAmountsOfFields,
  increaseFieldAmount,
  decreaseFieldAmount,
  setTotalFieldsAmount,
  increaseTotalFieldAmount,
  decreaseTotalFieldAmount,
} = slice.actions;

export default slice.reducer;

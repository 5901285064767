import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';

import {
  useCompanyVarificationMutation,
  useUpdateCompanyStatusMutation,
} from '@loop-np/api/company';
import {
  ProgressButton,
  ProgressCircle,
} from '@loop-np/features/services/services-loop';
import { UserCompany } from '@loop-np/features/settings/network-partner-companies/modals/company-info';

interface StatusMenuProps {
  company: UserCompany;
  isDisabled?: boolean;
}

const MenuOptions = ({ company }: StatusMenuProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [updateStatus] = useUpdateCompanyStatusMutation();
  const [verifyCompany] = useCompanyVarificationMutation();

  const handleVerifyCompany = async (id: string) => {
    await verifyCompany({
      id,
      isVerified: true,
    }).unwrap();
    toast({
      status: 'success',
      description: t('Company was verified successfully'),
    });
  };

  const setCompanyStatus = useCallback(async (id: string, status: boolean) => {
    await updateStatus({
      id,
      isActive: status,
    }).unwrap();
    toast({
      status: 'success',
      description: t('Status was changed successfully'),
    });
  }, []);

  return (
    <MenuList minWidth="200px">
      {!!company?.isActive && (
        <>
          <MenuItem onClick={() => setCompanyStatus(company.id, false)}>
            {t('Move to Inactive')}
          </MenuItem>
        </>
      )}
      {!!(!company?.isActive && company?.isVerified) && (
        <MenuItem onClick={() => setCompanyStatus(company.id, true)}>
          {t('Move to Active')}
        </MenuItem>
      )}
      {!company?.isActive && !company?.isVerified && (
        <MenuItem onClick={() => setCompanyStatus(company.id, true)}>
          {t('Move to Pending')}
        </MenuItem>
      )}
      {!!(company?.isActive && !company?.isVerified) && (
        <MenuItem onClick={() => handleVerifyCompany(company.id)}>
          {t('Approve')}
        </MenuItem>
      )}
    </MenuList>
  );
};

const CompanyStatusMenu = ({ company, isDisabled }: StatusMenuProps) => {
  const { t } = useTranslation();
  switch (true) {
    case !company.isActive:
      return !isDisabled ? (
        <Menu
          closeOnSelect={true}
          variant="fixed"
          computePositionOnMount={true}
        >
          <MenuButton as={ProgressButton}>
            <ProgressCircle bg="red" />
            {t('Inactive')}
          </MenuButton>
          <MenuOptions company={company} />
        </Menu>
      ) : (
        <Box fontSize="14px">
          <ProgressCircle bg="red" />
          {t('Inactive')}
        </Box>
      );
    case !company.isVerified:
      return !isDisabled ? (
        <Menu closeOnSelect={true} variant="fixed" closeOnBlur={true}>
          <MenuButton as={ProgressButton}>
            <ProgressCircle bg="yellow" />
            {t('Pending')}
          </MenuButton>
          <MenuOptions company={company} />
        </Menu>
      ) : (
        <Box fontSize="14px">
          <ProgressCircle bg="yellow" />
          {t('Pending')}
        </Box>
      );
    case !!company.isVerified:
      return !isDisabled ? (
        <Menu closeOnSelect={true} variant="fixed">
          <MenuButton as={ProgressButton}>
            <ProgressCircle bg="primary.500" />
            {t('Active')}
          </MenuButton>
          <MenuOptions company={company} />
        </Menu>
      ) : (
        <Box fontSize="14px">
          <ProgressCircle bg="primary.500" />
          {t('Active')}
        </Box>
      );
    default:
      return <Box></Box>;
  }
};

export default CompanyStatusMenu;

import { useTranslation } from 'react-i18next';

import { Box, FormLabel, SimpleGrid, Text } from '@chakra-ui/react';

import AddressText from '@loop/components/common/text/address-text';
import { useCompanyByIdQuery } from '@loop/features/network-partner/api/company';

interface Props {
  companyId: string;
}

function CompanyInfo({ companyId }: Props) {
  const { t } = useTranslation();

  const {
    data: company,
    isLoading,
    isFetching,
  } = useCompanyByIdQuery(
    { id: companyId },
    {
      skip: !companyId,
    }
  );

  return (
    <SimpleGrid
      columns={2}
      spacing={4}
      spacingY={0}
      opacity={isFetching || isLoading ? '0.4' : '1'}
    >
      <Box mb={6}>
        <FormLabel fontWeight={500}>{t('services:Company Name')}</FormLabel>
        <Text>{company && company.companyName}</Text>
      </Box>
      <Box mb={6}>
        <FormLabel fontWeight={500}>{t('services:Company Address')}</FormLabel>
        {company ? (
          <AddressText serverAddress={company.address} />
        ) : (
          <Text>-</Text>
        )}
      </Box>
      <Box mb={6}>
        <FormLabel fontWeight={500}>{t('services:Phone Number')}</FormLabel>
        <Text>{company && company.phone}</Text>
      </Box>
      <Box mb={6}>
        <FormLabel fontWeight={500}>{t('services:Contact Email')}</FormLabel>
        <Text>{company && company.email}</Text>
      </Box>
      <Box mb={6}>
        <FormLabel fontWeight={500}>{t('services:Time Zone')}</FormLabel>
        <Text>{company && company.timeZone}</Text>
      </Box>
      <Box mb={6}>
        <FormLabel fontWeight={500}>
          {t('services:State Operating In')}
        </FormLabel>
        {company?.stateOperatingIn ? (
          <div />
        ) : (
          <Box mb={6}>
            <FormLabel fontWeight={500}>{t('Operating In')}</FormLabel>
            <Text>{'-'}</Text>
          </Box>
        )}
        {company?.stateOperatingIn?.map((state) => (
          <>
            <Box mb={6}>
              <FormLabel fontWeight={500}>
                {t('Country Operating In')}
              </FormLabel>
              <Text>{state.country.label}</Text>
            </Box>
            <Box mb={6}>
              <FormLabel fontWeight={500}>
                {state.country.value === 'US' ? t('State') : t('Region')}
              </FormLabel>
              <Text>
                {state.regions?.reduce(
                  (label, valueFromForm: any) =>
                    `${label ? label + ', ' : ''}${valueFromForm.label}`,
                  ''
                ) || '-'}
              </Text>
            </Box>
          </>
        ))}
      </Box>
    </SimpleGrid>
  );
}

export default CompanyInfo;

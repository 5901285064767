import { TextProps } from '@chakra-ui/layout/dist/types/text';
import { SkeletonText, Text } from '@chakra-ui/react';

import { Address } from '@loop/types/address-types';

interface Props extends TextProps {
  serverAddress: Address;
}

const AddressText = ({ serverAddress, ...rest }: Props) => {
  return serverAddress ? (
    <Text {...rest}>{serverAddress.addressText}</Text>
  ) : (
    <SkeletonText noOfLines={1} {...rest} />
  );
};

export default AddressText;

export const PRODUCT_TYPES = {
  CHARGER: 'charger',
  MOUNT: 'pedestal',
  PROGRAMMATIC_ADVERTISING: 'programmatic advertising',
  NETWORK_ACCESS_PACKAGE: 'network access package',
  OTHER: 'other',
} as const;

const ALL_PRODUCT_TYPES = Object.values(PRODUCT_TYPES);
export type ProductType = typeof ALL_PRODUCT_TYPES[0];

interface ProgrammaticAdvertisingType {
  type: string;
}

export const programmaticAdvertisingTypes: ProgrammaticAdvertisingType[] = [
  {
    type: 'EV-Focus',
  },
];

import { configureStore } from '@reduxjs/toolkit';

import api from '@loop/api/api';
import { STORE_MODULES } from '@loop/constants/store-constatns';
import { coreReducer } from '@loop/store/core-store';
import localStorageMiddleware from '@loop/store/middlewares/local-storage';

import { authFeatureReducer } from '@loop-auth/store/auth-feature-reducer';
import { crmFeatureReducer } from '@loop-crm/store/crm-feature-reducer';
import { npFeatureReducer } from '@loop-np/store/np-feature-reducer';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [STORE_MODULES.CORE]: coreReducer,
    [STORE_MODULES.AUTH]: authFeatureReducer,
    [STORE_MODULES.NP]: npFeatureReducer,
    [STORE_MODULES.CRM]: crmFeatureReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      api.middleware,
      localStorageMiddleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

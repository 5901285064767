import { StatusDescription } from '@loop/components/common/ui/status-handler';

interface Props {
  statusesList: StatusDescription[];
  currentStatus: string;
}

const useStatus = ({ statusesList, currentStatus }: Props) => {
  const emptyStatusColor = 'grey.300';

  const getCurrentStatusDescription = (statusName = currentStatus) => {
    return statusesList.find(
      (status) => status.value.toLowerCase() === statusName.toLowerCase()
    );
  };
  const getCurrentStatusColor = () => {
    const currentStatusDescription = getCurrentStatusDescription();
    return currentStatusDescription?.color || emptyStatusColor;
  };

  const getCurrentStatusName = (statusName = currentStatus) => {
    const currentStatusDescription = getCurrentStatusDescription(statusName);
    return currentStatusDescription?.name;
  };

  const getEmptyStatus = (value: string) => ({
    name: '',
    value,
    color: emptyStatusColor,
  });

  return {
    getCurrentStatusColor,
    getCurrentStatusName,
    getEmptyStatus,
  };
};

export default useStatus;

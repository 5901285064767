import { ReactComponent as ChevronDown } from 'assets/img/icons/chevron-down-thin.svg';
import Select from 'react-select';

import { Box, FormControl, FormLabel } from '@chakra-ui/react';

import {
  ContactFormField,
  ContactFormStyles,
} from '@loop-crm/features/settings/types/embedded-form-types';

interface Props {
  field: ContactFormField;
  styles: ContactFormStyles;
}

const ContactFormInputDropdownPreview = ({ field, styles }: Props) => {
  return (
    <Box mt={8}>
      <FormControl variant={'wide'} isRequired={field.fieldProperties.required}>
        <FormLabel requiredIndicator={<> *</>} mb={0}>
          {field.fieldProperties.label}
        </FormLabel>
        <Select
          icon={<ChevronDown />}
          color={styles.placeholderTextColor}
          variant={styles.formFieldStyle}
          borderColor={styles.formFieldBorderColor}
          options={field.fieldProperties.options}
        />
      </FormControl>
    </Box>
  );
};

export default ContactFormInputDropdownPreview;

import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import {
  Button,
  Circle,
  Flex,
  Heading,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { ReactComponent as EyeBallIcon } from '@loop/assets/img/icons/eyeball.svg';
import TabButton from '@loop/components/common/buttons/tab-button';
import Table, {
  Column,
  FilterOption,
} from '@loop/components/common/table/table';
import DateText from '@loop/components/common/text/date-text';
import StatusHandler from '@loop/components/common/ui/status-handler';
import { SERVICES_ROUTES } from '@loop/constants/routes-constants';
import usePaginationFilters from '@loop/hooks/paginationFilters';

import {
  ServiceResponse,
  UserInfo,
  serviceApi,
  useAssignOrderToMeMutation,
  useLoopServicesQuery,
  useUpdateServiceStatusMutation,
} from '@loop-np/api/services';
import {
  SERVICE_STATUSES,
  useServicesConstants,
} from '@loop-np/constants/services-constants';
import CompanyDetailsModal from '@loop-np/features/services/components/modals/company-details-modal';
import ServiceRequestDetailsModal from '@loop-np/features/services/components/modals/service-request-details-modal';
import UserDetailsModal from '@loop-np/features/services/components/modals/user-details-modal';

export const ProgressButton = styled(Button)`
  padding: var(--chakra-space-1) var(--chakra-space-2);
  height: 25px;
  line-height: 12px;
  font-size: var(--chakra-fontSizes-md);
  color: var(--chakra-colors-grey-800);
  font-weight: 400;
  white-space: nowrap;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.14);
`;

export const LinkButton = styled(Button)`
  text-decoration: underline;
  color: var(--chakra-colors-grey-500);
  font-weight: 500;
  &:hover {
    color: var(--chakra-colors-primary-500);
  }
`;

export const ProgressCircle = styled(Circle)`
  width: var(--chakra-space-2);
  height: var(--chakra-space-2);
  margin-right: var(--chakra-space-2);
  display: inline-block;
`;

const ServicesLoop = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { status } = useParams<{ status: string }>();
  const [isActiveTabSelected, setIsActiveTabSelected] = useState(
    status !== 'inactive'
  );
  const [filters, setFilters] = usePaginationFilters({
    orderBy: 'createdAt',
    orderDirection: 'desc',
    statuses: isActiveTabSelected
      ? [SERVICE_STATUSES.PENDING, SERVICE_STATUSES.IN_PROGRESS]
      : [
          SERVICE_STATUSES.REJECTED,
          SERVICE_STATUSES.CANCELED,
          SERVICE_STATUSES.COMPLETED,
        ],
  });

  const userModalDisclosure = useDisclosure();
  const companyModalDisclosure = useDisclosure();
  const serviceDetailsModalDisclosure = useDisclosure();
  const { getListOfStatusesByCurrentStatusServices } = useServicesConstants();

  useEffect(() => {
    setFilters({
      statuses: isActiveTabSelected
        ? [SERVICE_STATUSES.PENDING, SERVICE_STATUSES.IN_PROGRESS]
        : [
            SERVICE_STATUSES.REJECTED,
            SERVICE_STATUSES.CANCELED,
            SERVICE_STATUSES.COMPLETED,
          ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTabSelected]);

  const [updateStatus] = useUpdateServiceStatusMutation();
  const [assignOrderToMe] = useAssignOrderToMeMutation();

  const [chosenCompanyId, setChosenCompanyId] = useState<string>('');
  const [chosenUserInfo, setChosenUserInfo] = useState<UserInfo | null>(null);
  const { data, isLoading, isFetching, refetch } = useLoopServicesQuery(
    filters,
    { refetchOnMountOrArgChange: true }
  );
  const [isLoadingAssignOrder, setIsLoadingAssignOrder] = useState(false);

  const updateStatusHandler = async (id: string, status: string) => {
    await updateStatus({
      serviceId: id,
      status,
    });

    refetch();
  };

  const assignToMeHandler = async (orderId: string) => {
    setIsLoadingAssignOrder(true);

    await assignOrderToMe({ orderId });
    await refetch();

    setIsLoadingAssignOrder(false);
  };

  const assignToMeRender = (data: ServiceResponse) => {
    if (data.loopUserId) {
      return `${data.loopUser.firstName} ${data.loopUser.lastName}`;
    }

    return (
      <Button
        key={data.id}
        variant="outline"
        border="none"
        textDecoration="underline"
        colorScheme="primary"
        isLoading={isLoadingAssignOrder}
        isDisabled={isLoadingAssignOrder}
        onClick={() => assignToMeHandler(data.id)}
      >
        {t('services:Assign to me')}
      </Button>
    );
  };

  const columns: Column<ServiceResponse>[] = useMemo(
    () => [
      {
        path: 'service.title',
        header: 'view',
        isSortable: false,
        render: (row: any) => {
          return (
            <Flex>
              <IconButton
                onClick={() => {
                  serviceDetailsModalDisclosure.onOpen();
                  setChosenCompanyId(row.companyId);
                  setChosenUserInfo(row.userInfo);
                }}
                aria-label="view"
                icon={<EyeBallIcon />}
              />
            </Flex>
          );
        },
      },
      {
        path: 'service.title',
        header: t('Service Type'),
      },
      {
        path: 'company.companyName',
        header: t('Np Company'),
        maxWidth: '300px',
        render: (row: any) => (
          <LinkButton
            variant="link"
            onClick={() => {
              setChosenCompanyId(row.companyId);
              companyModalDisclosure.onOpen();
            }}
            textAlign="left"
          >
            <Text whiteSpace="pre-line" wordBreak="break-word" minWidth="160px">
              {row.company.companyName}
            </Text>
          </LinkButton>
        ),
      },
      {
        path: 'status',
        header: t('Status'),
        render: (row: any) => {
          return (
            <StatusHandler
              api={serviceApi}
              endpointName="servicesStatuses"
              isEdit={
                row.status === SERVICE_STATUSES.PENDING ||
                row.status === SERVICE_STATUSES.IN_PROGRESS
              }
              id={row.id}
              currentStatus={row.status}
              statusesList={getListOfStatusesByCurrentStatusServices(
                row.status
              )}
              onUpdateStatus={updateStatusHandler}
              isEmptyStatusesShown={false}
            />
          );
        },
      },
      {
        path: '{{userInfo.firstName}}',
        header: t('User Info'),
        render: (row: any) => (
          <LinkButton
            variant="link"
            onClick={() => {
              setChosenUserInfo(row.userInfo);
              userModalDisclosure.onOpen();
            }}
            textAlign="left"
            maxWidth="250px"
          >
            <Text whiteSpace="pre-line" wordBreak="break-word" minWidth="160px">
              {row.userInfo.firstName + ' ' + row.userInfo.lastName}
            </Text>
          </LinkButton>
        ),
      },
      {
        path: 'createdAt',
        header: t('Requested Date'),
        render: (row) => <DateText value={row.createdAt} />,
      },
      {
        path: 'updatedAt',
        header: t('Last Updated Date'),
        render: (row) => <DateText value={row.updatedAt} />,
      },
      {
        path: 'loopUser.firstName',
        header: t('Responsible Admin'),
        align: 'center',
        isFixed: true,
        render: (row) => assignToMeRender(row),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isLoadingAssignOrder]
  );

  const filterOptions: FilterOption<{
    statuses?: string[];
  }>[] = isActiveTabSelected
    ? [
        {
          name: t('services:All'),
          value: 'all',
          params: {
            statuses: [SERVICE_STATUSES.PENDING, SERVICE_STATUSES.IN_PROGRESS],
          },
        },
        {
          name: t('services:In Progress'),
          value: 'in progress',
          params: { statuses: [SERVICE_STATUSES.IN_PROGRESS] },
        },
        {
          name: t('services:Pending'),
          value: 'pending',
          params: { statuses: [SERVICE_STATUSES.PENDING] },
        },
      ]
    : [
        {
          name: t('services:All'),
          value: 'all',
          params: {
            statuses: [
              SERVICE_STATUSES.REJECTED,
              SERVICE_STATUSES.CANCELED,
              SERVICE_STATUSES.COMPLETED,
            ],
          },
        },
        {
          name: t('services:Rejected'),
          value: 'rejected',
          params: { statuses: [SERVICE_STATUSES.REJECTED] },
        },
        {
          name: t('services:Canceled'),
          value: 'canceled',
          params: { statuses: [SERVICE_STATUSES.CANCELED] },
        },
        {
          name: t('services:Completed'),
          value: 'completed',
          params: { statuses: [SERVICE_STATUSES.COMPLETED] },
        },
      ];

  return (
    <>
      <Heading fontSize="2xl" mb={6}>
        {t('services:Service Requests')}
      </Heading>
      <Flex mb="24px">
        <TabButton
          isActive={isActiveTabSelected}
          onClick={() => {
            setIsActiveTabSelected(true);
            history.push(`${SERVICES_ROUTES.BASE_PATH}/active`);
          }}
          mr="11px"
        >
          {t('services:Active')}
        </TabButton>
        <TabButton
          isActive={!isActiveTabSelected}
          onClick={() => {
            setIsActiveTabSelected(false);
            history.push(`${SERVICES_ROUTES.BASE_PATH}/inactive`);
          }}
        >
          {t('services:Inactive')}
        </TabButton>
      </Flex>

      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters}
        setFilters={setFilters}
        filterOptions={filterOptions}
      />
      <UserDetailsModal
        isOpen={userModalDisclosure.isOpen}
        userInfo={chosenUserInfo}
        onClose={() => {
          setChosenUserInfo(null);
          userModalDisclosure.onClose();
        }}
      />
      <CompanyDetailsModal
        companyId={chosenCompanyId}
        isOpen={companyModalDisclosure.isOpen}
        onClose={() => {
          setChosenCompanyId('');
          companyModalDisclosure.onClose();
        }}
      />
      <ServiceRequestDetailsModal
        userInfo={chosenUserInfo}
        companyId={chosenCompanyId}
        isOpen={serviceDetailsModalDisclosure.isOpen}
        onClose={() => {
          setChosenCompanyId('');
          setChosenUserInfo(null);
          serviceDetailsModalDisclosure.onClose();
        }}
      />
    </>
  );
};

export default ServicesLoop;

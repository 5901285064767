export const FIELD_TYPES = {
  INPUT_TEXT: 'inputText',
  TEXT_AREA: 'textArea',
  NUMBER_INPUT: 'numberInput',
  INPUT_EMAIL: 'inputEmail',
  INPUT_ADDRESS: 'inputAddress',
  INPUT_PHONE: 'inputPhone',
  DROPDOWN_LIST: 'dropdownList',
  AGREEMENT_CONSENT: 'agreementConsent',
};

export const FIELD_STYLE_TYPES = {
  OUTLINE: 'outline',
  UNDERLINE: 'flushed',
  FILLED: 'filled',
};

export const ContactFormFields = {
  inputText: {
    general: {
      fieldId: 'ff34947b-38eb-48b1-93f4-1920e69e90f4',
      type: FIELD_TYPES.INPUT_TEXT,
      modelId: null,
      required: false,
      isRequiredSwitchDisabled: false,
      isAdditionalInfo: true,
      limit: 5,
    },
    fieldProperties: {
      label: 'Regular/Short',
      required: false,
      maxLength: 100,
    },
  },
  textArea: {
    general: {
      fieldId: 'a8f8854a-5833-4053-b820-347d19ae0577',
      type: FIELD_TYPES.TEXT_AREA,
      modelId: null,
      required: false,
      isRequiredSwitchDisabled: false,
      isAdditionalInfo: true,
      limit: 5,
    },
    fieldProperties: {
      label: 'Regular/Long',
      required: false,
      maxLength: 255,
    },
  },
  numberInput: {
    general: {
      fieldId: '33e02ec5-03d1-4862-a26f-158eb8d10097',
      type: FIELD_TYPES.NUMBER_INPUT,
      modelId: null,
      required: false,
      isRequiredSwitchDisabled: false,
      isAdditionalInfo: true,
      limit: 5,
    },
    fieldProperties: {
      label: 'Numbers',
      required: false,
    },
  },
  inputEmail: {
    general: {
      fieldId: '9532a600-5f67-4271-9739-219be186499a',
      type: FIELD_TYPES.INPUT_EMAIL,
      modelId: 'clientEmail',
      required: true,
      isRequiredSwitchDisabled: true,
      isAdditionalInfo: false,
      limit: 1,
    },
    fieldProperties: {
      label: 'Email',
      required: true,
    },
  },
  inputAddress: {
    general: {
      fieldId: '1fc8659e-ddf0-4ea8-bac9-bc92d03245f0',
      type: FIELD_TYPES.INPUT_ADDRESS,
      modelId: 'clientAddress',
      required: true,
      isRequiredSwitchDisabled: true,
      isAdditionalInfo: false,
      limit: 5,
    },
    fieldProperties: {
      label: 'Address',
      required: true,
    },
  },
  inputPhone: {
    general: {
      fieldId: '998f376c-5044-48f9-b051-5a556988705b',
      type: FIELD_TYPES.INPUT_PHONE,
      modelId: 'phone',
      required: true,
      isRequiredSwitchDisabled: true,
      isAdditionalInfo: false,
      limit: 1,
    },
    fieldProperties: {
      label: 'Phone Number',
      required: true,
    },
  },
  dropdownList: {
    general: {
      fieldId: 'a5b20813-376d-45ca-84f0-d84780ddfa06',
      type: FIELD_TYPES.DROPDOWN_LIST,
      modelId: null,
      required: false,
      isRequiredSwitchDisabled: false,
      isAdditionalInfo: false,
      limit: 5,
    },
    fieldProperties: {
      label: 'Dropdown/List',
      required: false,
      options: [
        {
          value: 'Option',
          label: 'Option',
        },
      ],
    },
  },
  agreementConsent: {
    general: {
      fieldId: 'eec4398b-5def-4813-b4e1-f7222cbaead3',
      type: FIELD_TYPES.AGREEMENT_CONSENT,
      modelId: 'consent',
      required: false,
      isRequiredSwitchDisabled: true,
      isAdditionalInfo: true,
      limit: 1,
    },
    fieldProperties: {
      label: 'Agreement/Consent',
      required: true,
      description:
        'Example: By accepting this form, you hereby consent that [COMPANY NAME] can process your personal data for the purpose of management functions onboard the vessel on which you are employed.',
    },
  },
};

export const CONTACT_FORM_STYLES = {
  width: '600px',
  labelTextColor: '#3D454E',
  placeholderTextColor: '#8890A0',
  backgroundColor: '#ffffff',
  buttonColor: '#1DC7B8',
  buttonTextColor: '#ffffff',
  errorMessageColor: '#EB5757',
  formFieldBorderColor: '#DFE1E5',
  formFieldStyle: FIELD_STYLE_TYPES.UNDERLINE,
  fontFamily: 'Rubik', //+
};

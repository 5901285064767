import { isArray } from 'lodash';
import { lazy, useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from '@loop/components/protected-route/protected-route';
import { useAppDispatch } from '@loop/hooks/redux';
import FEATURES_ROUTES_CONFIG from '@loop/routes/features-routes-config';
import { setActiveModule } from '@loop/store/slices/utils-slice';

const SharedRoutes = lazy(() => import('routes/page-routes'));

function FeatureRoutes() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const topLevelPath = useRouteMatch<{ path: string }>('/:path')?.params.path;
  useTranslation(topLevelPath ? topLevelPath : undefined);

  useEffect(() => {
    if (!topLevelPath) {
      return;
    }
    dispatch(setActiveModule(topLevelPath));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLevelPath]);

  return (
    <Switch location={location}>
      {FEATURES_ROUTES_CONFIG.map((route) => (
        <ProtectedRoute
          key={isArray(route.path) ? route.path[0] : route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
          roles={route.roles}
        />
      ))}
      <SharedRoutes />
      <Redirect to="/error404" />
    </Switch>
  );
}

export default FeatureRoutes;

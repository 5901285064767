import { RoleCheck } from '@loop/constants/user-roles';
import { MenuItemConfig } from '@loop/types/general-types';

const useMenuItems = () => {
  const filterMenuItemsByRoles = (
    items: MenuItemConfig[],
    activeRoles?: RoleCheck | null
  ) => {
    if (!activeRoles) {
      return [];
    }

    return items.reduce(
      (
        filteredMenuItems: MenuItemConfig[],
        currentMenuItem: MenuItemConfig
      ) => {
        if (!!currentMenuItem?.children) {
          const itemWithChildren = {
            ...currentMenuItem,
            children: currentMenuItem.children.filter((currentItem) =>
              currentItem.roles
                ? currentItem.roles.some((role) => activeRoles.includes(role))
                : true
            ),
          };
          if (itemWithChildren.children.length) {
            filteredMenuItems.push(itemWithChildren);
          }
        } else {
          if (currentMenuItem.roles) {
            if (
              currentMenuItem.roles?.some((role) => activeRoles.includes(role))
            ) {
              filteredMenuItems.push(currentMenuItem);
            }
          } else {
            filteredMenuItems.push(currentMenuItem);
          }
        }
        return filteredMenuItems;
      },
      []
    );
  };

  return {
    filterMenuItemsByRoles,
  };
};

export default useMenuItems;

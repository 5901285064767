import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import api from '@loop/api/api';
import { useUserQuery } from '@loop/api/common/user';
import avatarUrl from '@loop/assets/img/common/avatar.png';
import { ROUTES } from '@loop/constants/routes-constants';
import { Role, USER_GROUPS, USER_ROLES } from '@loop/constants/user-roles';
import { useAppDispatch, useAppSelector } from '@loop/hooks/redux';
import useRoleCheck, { useNpRole } from '@loop/hooks/role-check';
import useMenuItems from '@loop/hooks/useMenuItems';
import { resetNotificationState } from '@loop/store/slices/notification-slice';
import { MenuItemConfig } from '@loop/types/general-types';

import { useLogOutMutation } from '@loop-auth/api/auth';
import { resetAllState } from '@loop-auth/store/slices/auth-slice';
import { useCompanyQuery } from '@loop-np/api/company';

interface Props {
  navigationNotAllowed?: boolean;
}

const menuItems = (t: TFunction): MenuItemConfig[] => [
  {
    path: `/${ROUTES.LOOP.BASE_PATH}/${ROUTES.LOOP.SETTINGS.BASE_PATH}/${ROUTES.LOOP.SETTINGS.LOOP_USER_PROFILE}`,
    name: t('Profile'),
    roles: USER_GROUPS.LOOP,
  },
  {
    path: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.SETTINGS.BASE_PATH}/${ROUTES.NP.SETTINGS.USER_PROFILE}`,
    name: t('Profile'),
    roles: USER_GROUPS.NP,
  },
  {
    path: `/${ROUTES.LOOP.BASE_PATH}/${ROUTES.LOOP.SETTINGS.BASE_PATH}/${ROUTES.LOOP.SETTINGS.LOOP_COMPANY_INFORMATION}`,
    name: t('Company Info'),
    roles: USER_GROUPS.LOOP,
  },
  {
    path: `/${ROUTES.LOOP.BASE_PATH}/${ROUTES.LOOP.SETTINGS.BASE_PATH}/${ROUTES.LOOP.SETTINGS.TERMS_AND_CONDITIONS}`,
    name: t('Terms&Conditions'),
    roles: USER_GROUPS.LOOP,
  },
  {
    name: t('Settings'),
    roles: USER_GROUPS.LOOP,
    children: [
      {
        path: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.SETTINGS.BASE_PATH}/${ROUTES.NP.SETTINGS.PRODUCTS}`,
        name: t('Products'),
        roles: USER_GROUPS.LOOP,
      },
      {
        path: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.SETTINGS.BASE_PATH}/${ROUTES.NP.SETTINGS.PROPOSAL_ASSUMPTION}`,
        name: t('Assumptions'),
        roles: [USER_ROLES.LOOP_SUPER_ADMIN],
      },
      {
        path: `/${ROUTES.LOOP.BASE_PATH}/${ROUTES.LOOP.SETTINGS.BASE_PATH}/${ROUTES.LOOP.SETTINGS.LOOP_ADMIN_USERS}`,
        name: t('Admin Users'),
        roles: USER_GROUPS.LOOP,
      },
      {
        path: `/${ROUTES.NP.BASE_PATH}/${ROUTES.NP.SETTINGS.BASE_PATH}/${ROUTES.NP.SETTINGS.LABOR_ITEMS.BASE_PATH}/${ROUTES.NP.SETTINGS.LABOR_ITEMS.CUSTOM}`,
        name: t('Labors'),
        roles: USER_GROUPS.LOOP,
      },
      {
        path: `/${ROUTES.LOOP.BASE_PATH}/${ROUTES.LOOP.SETTINGS.BASE_PATH}/${ROUTES.LOOP.SETTINGS.NOTIFICATIONS}`,
        name: t('Notifications'),
        roles: USER_GROUPS.LOOP,
      },
    ],
  },
];

const HeaderMenuAccordion = ({
  menuItem,
  onMenuClose,
}: {
  menuItem: MenuItemConfig;
  onMenuClose: () => void;
}) => {
  return (
    <Accordion allowToggle>
      <AccordionItem border={'none'}>
        <AccordionButton
          _hover={{ backgroundColor: 'primary.alpha.500' }}
          _focus={{ backgroundColor: 'primary.alpha.500' }}
        >
          <Box as="span" flex="1" textAlign="left" fontSize={'sm'}>
            {menuItem.name}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={2}>
          <List
            bg={'grey.100'}
            borderRadius={'8px'}
            display={'flex'}
            flexDirection={'column'}
            p={0}
            onClick={onMenuClose}
          >
            {menuItem.children!.map((item) => (
              <ListItem
                p={2}
                as={NavLink}
                to={item.path}
                key={item.path}
                _hover={{ backgroundColor: 'primary.alpha.500' }}
                _focus={{ backgroundColor: 'primary.alpha.500' }}
              >
                {item.name}
              </ListItem>
            ))}
          </List>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const HeaderMenu = ({ navigationNotAllowed }: Props) => {
  const { t } = useTranslation();
  const [logOut, { isLoading }] = useLogOutMutation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const npRole = useNpRole();

  const { filterMenuItemsByRoles } = useMenuItems();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const token = useAppSelector(
    (state) => state.authModule.auth.tokens.accessToken
  );
  const decodedToken = useAppSelector(
    (state) => state.authModule.auth.decodedToken
  );
  const activeRoles = decodedToken?.roles;

  let { data: company } = useCompanyQuery(undefined, {
    skip: !token || !decodedToken?.companyId,
    refetchOnMountOrArgChange: true,
  });

  let { data: user } = useUserQuery(undefined, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const isLoopUser = useRoleCheck(USER_GROUPS.LOOP);

  if (isLoopUser) {
    company = undefined;
  }

  const logOutHandler = useCallback(async () => {
    await logOut().unwrap();

    dispatch(resetAllState());
    dispatch(api.util.resetApiState());
    dispatch(resetNotificationState());
    sessionStorage.clear();
    history.push('/login');
  }, [dispatch, history, logOut]);

  const filteredMenuItems = useMemo(
    () => filterMenuItemsByRoles(menuItems(t), activeRoles),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeRoles]
  );

  return (
    <Menu
      placement="bottom-end"
      isOpen={isMenuOpen}
      onOpen={() => {
        setIsMenuOpen(true);
      }}
      onClose={() => {
        setIsMenuOpen(false);
      }}
    >
      <MenuButton
        isLoading={isLoading}
        as={Button}
        display="flex"
        variant="ghost"
        colorScheme="whiteAlpha"
        borderRadius="32px"
        pl={4}
        pr={2}
        h={12}
      >
        <Box textAlign="right" display="inline-block">
          <Text
            fontWeight="bold"
            color="white"
            lineHeight="20px"
            fontSize={'xs'}
          >
            {user?.firstName} {user?.lastName}
          </Text>
          <Text
            color="rgba(255, 255, 255, 0.6)"
            fontWeight="normal"
            overflowX="hidden"
            textOverflow="ellipsis"
            maxW="300px"
            fontSize={'xs'}
          >
            {npRole && t(Role[npRole])}
            {company && ' • '}
            {company?.companyName}
          </Text>
        </Box>
        <Avatar src={avatarUrl} alt="avatar" w="9" h="9" ml={4} />
      </MenuButton>
      <MenuList
        w={'170px'}
        minWidth={'170px'}
        color={'grey.500'}
        fontSize={'sm'}
        lineHeight={'shorter'}
      >
        {!navigationNotAllowed && (
          <>
            {filteredMenuItems.map((menuItem) =>
              menuItem?.path ? (
                <MenuItem
                  as={NavLink}
                  to={menuItem.path}
                  key={menuItem.path}
                  py={2}
                  px={4}
                  _hover={{ backgroundColor: 'primary.alpha.500' }}
                  _focus={{ backgroundColor: 'primary.alpha.500' }}
                >
                  {menuItem.name}
                </MenuItem>
              ) : (
                menuItem?.children && (
                  <HeaderMenuAccordion
                    menuItem={menuItem}
                    onMenuClose={() => {
                      setIsMenuOpen(false);
                    }}
                  />
                )
              )
            )}
            <MenuDivider />
          </>
        )}

        <MenuItem
          as="button"
          onClick={logOutHandler}
          _hover={{ backgroundColor: 'primary.alpha.500' }}
          _focus={{ backgroundColor: 'primary.alpha.500' }}
          lineHeight={'shorter'}
          px={4}
        >
          {t('Log Out')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default HeaderMenu;

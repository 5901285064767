import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';

import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import {
  useNotificationsQuery,
  useRefetchNotificationsMutation,
} from '@loop/api/common/notifications';
import { ReactComponent as BellIcon } from '@loop/assets/img/icons/bell.svg';
import InfiniteScrollComponent from '@loop/components/common/ui/infinite-scroll';
import NotificationWrapper from '@loop/components/common/ui/notification-wrapper';
import PopupNotification from '@loop/components/layout/popup-notification';
import config from '@loop/config/config';
import { USER_GROUPS } from '@loop/constants/user-roles';
import usePaginationFilters from '@loop/hooks/paginationFilters';
import { useRedirectToNotificationPage } from '@loop/hooks/redirect-to-page-by-role';
import { useAppDispatch, useAppSelector } from '@loop/hooks/redux';
import useRoleCheck from '@loop/hooks/role-check';
import {
  increaseNotificationsCount,
  resetNotificationState,
  setNotificationsCount,
} from '@loop/store/slices/notification-slice';
import store, { RootState } from '@loop/store/store';
import { Notification } from '@loop/types/notifications';

import { refreshToken } from '@loop-libs/http-client';

const NotificationsCount = styled(Flex)`
  position: absolute;
  top: -9px;
  right: -4px;
  background: var(--chakra-colors-red-500);
  color: white;
  text-align: center;
  font-size: 12px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-weight: normal;
`;

const NotificationsList = () => {
  const { t } = useTranslation();
  const redirectToNotificationPage = useRedirectToNotificationPage();
  const limit = 10;

  const dispatch = useAppDispatch();
  const token = useAppSelector(
    (state) => state.authModule.auth.tokens.accessToken
  );

  const decodedToken = useAppSelector(
    (state) => state.authModule.auth.decodedToken
  );

  const notificationsCount = useAppSelector(
    (state: RootState) => state.coreModule.notifications
  );

  const isNpUser = useRoleCheck(USER_GROUPS.NP);

  const [refetchNotifications] = useRefetchNotificationsMutation();

  const [filters, setFilters] = usePaginationFilters({
    limit,
  });

  const { data: notifications, isFetching } = useNotificationsQuery({
    ...filters,
    isUnreadOnly: true,
  });

  useEffect(() => {
    if (!token || (!decodedToken?.companyId && isNpUser)) return;

    const socket = io(config.apiUrl as string, {
      path: '/api/notification/public/notification/stream',
      auth: {
        token: `Bearer ${token}`,
      },
      reconnectionAttempts: 5,
      transports: ['websocket'],
    });
    socket.on('notification', (data: number | Notification) => {
      if (typeof data === 'number') {
        dispatch(setNotificationsCount(data));
      } else {
        dispatch(increaseNotificationsCount());
      }

      refetchNotifications();
    });
    socket.on('connect', () => {
      dispatch(resetNotificationState());
    });
    socket.on('connect_error', async (error) => {
      if (error.message === 'jwt expired') {
        await refreshToken();
        const newToken = (store.getState() as RootState).authModule.auth.tokens
          .accessToken;
        socket.auth = {
          token: `Bearer ${newToken}`,
        };
        socket.connect();
      }
    });
    return () => {
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton as={Button} variant="ghost" colorScheme="whiteAlpha" px={0}>
          <Box pos="relative">
            <IconButton
              icon={<BellIcon />}
              as="div"
              isRound={true}
              variant="ghost"
              minW={8}
              w={8}
              h={8}
              aria-label="Notifications: 3 unread notifications"
              colorScheme="whiteAlpha"
            />
            {notificationsCount > 0 && (
              <NotificationsCount w="5px" fontSize="5px">
                {notificationsCount <= 99 ? notificationsCount : '99+'}
              </NotificationsCount>
            )}
          </Box>
        </MenuButton>

        <MenuList pb="0" zIndex="10">
          {
            <>
              <InfiniteScrollComponent
                data={notifications}
                filters={filters}
                setFilters={setFilters}
                stepLimit={limit}
                isFetching={isFetching}
              >
                <Box maxHeight="500px">
                  {notifications?.records?.map((notification) => (
                    <NotificationWrapper
                      key={notification.id}
                      notification={notification}
                    >
                      {(props) => <PopupNotification {...props} />}
                    </NotificationWrapper>
                  ))}

                  {!notifications?.records?.length && (
                    <>
                      <MenuItem
                        as={Flex}
                        _hover={{ backgroundColor: 'white' }}
                        justifyContent="center"
                        borderBottom="1px grey"
                        py="40px"
                      >
                        {t('No notifications found')}
                      </MenuItem>
                      <Divider />
                    </>
                  )}
                </Box>
              </InfiniteScrollComponent>

              <MenuItem
                _hover={{ backgroundColor: 'grey.100' }}
                onClick={redirectToNotificationPage}
              >
                <Text
                  color="primary.600"
                  textAlign="center"
                  w="370px"
                  py={2}
                  onClick={redirectToNotificationPage}
                >
                  {t('notifications:See all notifications')}
                </Text>
              </MenuItem>
            </>
          }
        </MenuList>
      </Menu>
    </>
  );
};

export default NotificationsList;

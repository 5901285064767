import InfiniteScroll from 'react-infinite-scroll-component';

import { Spinner, Text } from '@chakra-ui/react';

import { SetFilters } from '@loop/hooks/paginationFilters';
import { PaginatedData, PaginatedRequest } from '@loop/types/paginated-data';

interface Props {
  data?: PaginatedData | undefined;
  setFilters: SetFilters;
  filters: PaginatedRequest;
  children: React.ReactNode;
  stepLimit: number;
  isFetching: boolean;
}

const InfiniteScrollComponent = ({
  data,
  setFilters,
  filters,
  children,
  stepLimit,
  isFetching,
}: Props) => {
  const dataLength: number = data?.records.length || 0;
  const total: number = data?.totalRecords || 0;
  const hasMoreItems = dataLength < total;
  const fetchMoreData = () => {
    const limit: number = data?.limit || 0;
    if (limit >= total) {
      return;
    }

    const newLimit: number = limit + stepLimit;
    const newFilters = {
      ...filters,
      limit: newLimit,
    };

    setFilters(newFilters);
  };

  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={fetchMoreData}
      loader={
        dataLength === 0 ? (
          ''
        ) : isFetching ? (
          <Text height="55px" textAlign="center">
            <Spinner color="primary.500" />
          </Text>
        ) : null
      }
      hasMore={hasMoreItems}
      height={dataLength < stepLimit ? 'auto' : '250'}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollComponent;

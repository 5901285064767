import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  FormLabel,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import AddressText from '@loop/components/common/text/address-text';
import StatusHandler from '@loop/components/common/ui/status-handler';

import { useCompanyByIdQuery } from '@loop-np/api/company';
import {
  serviceApi,
  useServiceByIdQuery,
  useUpdateServiceStatusMutation,
} from '@loop-np/api/services';
import {
  SERVICE_STATUSES,
  useServicesConstants,
} from '@loop-np/constants/services-constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
}

function ChangeServiceStatusModal({ isOpen, onClose, orderId }: Props) {
  const { t } = useTranslation();
  const [updateStatus] = useUpdateServiceStatusMutation();

  const {
    data: orderedService,
    refetch,
    isLoading: serviceIsLoading,
    isFetching: serviceIsFetching,
  } = useServiceByIdQuery(orderId, { skip: !orderId });

  const {
    data: company,
    isLoading: companyIsLoading,
    isFetching: companyIsFetching,
  } = useCompanyByIdQuery(
    { id: orderedService?.companyId },
    {
      skip: !orderedService,
    }
  );

  const updateStatusHandler = async (id: string, status: string) => {
    await updateStatus({
      serviceId: id,
      status,
    });
    refetch();
  };

  const smthIsLoading = serviceIsLoading || companyIsLoading;
  const smthIsFetching = serviceIsFetching || companyIsFetching;

  const { getListOfStatusesByCurrentStatusServices } = useServicesConstants();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent p={5}>
        <Box as="form">
          <ModalHeader color="grey.600" fontWeight="500" fontSize="2xl" pb={8}>
            {t('notifications:Change Service Status')}
          </ModalHeader>
          <ModalBody>
            <Text
              as="h3"
              color="grey.600"
              fontWeight="500"
              fontSize="xl"
              pb={2}
            >
              {t('notifications:Ordered Service')}
            </Text>
            <SimpleGrid
              columns={2}
              spacing={4}
              spacingY={0}
              opacity={smthIsFetching || smthIsLoading ? '0.4' : '1'}
            >
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('notifications:Service Type')}
                </FormLabel>
                <Text>{orderedService && orderedService.service.title}</Text>
              </Box>
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('notifications:Service Status')}
                </FormLabel>
                {orderedService && (
                  <StatusHandler
                    isEmptyStatusesShown={false}
                    api={serviceApi}
                    endpointName="servicesStatuses"
                    isEdit={
                      orderedService.status === SERVICE_STATUSES.PENDING ||
                      orderedService.status === SERVICE_STATUSES.IN_PROGRESS
                    }
                    id={orderedService.id}
                    currentStatus={orderedService.status}
                    statusesList={getListOfStatusesByCurrentStatusServices(
                      orderedService.status
                    )}
                    onUpdateStatus={updateStatusHandler}
                  />
                )}
              </Box>
            </SimpleGrid>

            <Text
              as="h3"
              color="grey.600"
              fontWeight="500"
              fontSize="xl"
              pb={2}
            >
              {t('notifications:Company Details')}
            </Text>
            <SimpleGrid
              columns={2}
              spacing={4}
              spacingY={0}
              opacity={smthIsFetching || smthIsLoading ? '0.4' : '1'}
            >
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('notifications:Company Name')}
                </FormLabel>
                <Text>{company?.companyName}</Text>
              </Box>
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('notifications:Company Address')}
                </FormLabel>
                {company?.address ? (
                  <AddressText serverAddress={company.address} />
                ) : (
                  <Text>-</Text>
                )}
              </Box>
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('notifications:Phone Number')}
                </FormLabel>
                <Text>{company?.phone}</Text>
              </Box>
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('notifications:Contact Email')}
                </FormLabel>
                <Text>{company?.email}</Text>
              </Box>
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('notifications:Time Zone')}
                </FormLabel>
                <Text>{company?.timeZone}</Text>
              </Box>
              {company?.stateOperatingIn ? (
                <div />
              ) : (
                <Box mb={6}>
                  <FormLabel fontWeight={500}>{t('Operating In')}</FormLabel>
                  <Text>{'-'}</Text>
                </Box>
              )}
              {company?.stateOperatingIn?.map((state) => (
                <>
                  <Box mb={6}>
                    <FormLabel fontWeight={500}>
                      {t('Country Operating In')}
                    </FormLabel>
                    <Text>{state.country.label}</Text>
                  </Box>
                  <Box mb={6}>
                    <FormLabel fontWeight={500}>
                      {state.type === 'city' ? t('City') : 'State'}
                    </FormLabel>
                    <Text>
                      {state.regions?.reduce(
                        (label, valueFromForm: any) =>
                          `${label ? label + ', ' : ''}${valueFromForm.label}`,
                        ''
                      ) || '-'}
                    </Text>
                  </Box>
                </>
              ))}
            </SimpleGrid>

            <Text
              as="h3"
              color="grey.600"
              fontWeight="500"
              fontSize="xl"
              pb={2}
            >
              {t('notifications:User Details')}
            </Text>

            <SimpleGrid
              columns={2}
              spacing={4}
              spacingY={0}
              opacity={smthIsFetching || smthIsLoading ? '0.4' : '1'}
            >
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('services:First Name')}
                </FormLabel>
                <Text>{orderedService?.userInfo.firstName}</Text>
              </Box>
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('services:Last Name')}
                </FormLabel>
                <Text>{orderedService?.userInfo.lastName}</Text>
              </Box>
              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('services:Phone Number')}
                </FormLabel>
                <Text>{orderedService?.userInfo.phone}</Text>
              </Box>

              <Box mb={6}>
                <FormLabel fontWeight={500}>
                  {t('services:Email Address')}
                </FormLabel>
                <Text>{orderedService?.userInfo.email}</Text>
              </Box>

              <GridItem colSpan={2}>
                <Box mb={6}>
                  <FormLabel fontWeight={500}>
                    {t('services:Description')}
                  </FormLabel>
                  <Text>{orderedService?.userInfo.description}</Text>
                </Box>
              </GridItem>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Button
              onClick={() => {
                onClose();
              }}
              fontWeight="500"
              colorScheme="primary"
              h="40px"
              w="120px"
            >
              {t('Close')}
            </Button>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default ChangeServiceStatusModal;

import { Box, FormControl, FormLabel, Textarea } from '@chakra-ui/react';

import {
  ContactFormField,
  ContactFormStyles,
} from '@loop-crm/features/settings/types/embedded-form-types';

interface Props {
  field: ContactFormField;
  styles: ContactFormStyles;
}

const ContactFormInputTextareaPreview = ({ field, styles }: Props) => {
  return (
    <Box mt={8}>
      <FormControl variant={'wide'} isRequired={field.fieldProperties.required}>
        <FormLabel requiredIndicator={<> *</>} mb={0}>
          {field.fieldProperties.label}
        </FormLabel>
        <Textarea
          minHeight={'40px'}
          color={styles.placeholderTextColor}
          variant={styles.formFieldStyle}
          borderColor={styles.formFieldBorderColor}
          isDisabled
        />
      </FormControl>
    </Box>
  );
};

export default ContactFormInputTextareaPreview;

import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  List,
  ListIcon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { ReactComponent as ArrowLeftIcon } from '@loop/assets/img/icons/arrow-left.svg';
import { ReactComponent as CaseCustomerIcon } from '@loop/assets/img/icons/case.svg';
import { ReactComponent as CrmManagementIcon } from '@loop/assets/img/icons/crm-management.svg';
import { ReactComponent as LogoIcon } from '@loop/assets/img/icons/loop-icon.svg';
import { ReactComponent as MarketingIcon } from '@loop/assets/img/icons/marketing.svg';
import { ReactComponent as DashboardIcon } from '@loop/assets/img/icons/menu-icons/common/dashboard.svg';
import { ReactComponent as SitesIcon } from '@loop/assets/img/icons/menu-icons/crm/site.svg';
import { ReactComponent as AdminUsersIcon } from '@loop/assets/img/icons/menu-icons/loop/admin-users.svg';
import { ReactComponent as AccountSettingsIcon } from '@loop/assets/img/icons/menu-icons/np/account-settings.svg';
import { ReactComponent as DocumentsIcon } from '@loop/assets/img/icons/menu-icons/np/documents.svg';
import { ReactComponent as LaborItemsIcon } from '@loop/assets/img/icons/menu-icons/np/labor-items.svg';
import { ReactComponent as LandingPageIcon } from '@loop/assets/img/icons/menu-icons/np/landing-page.svg';
import { ReactComponent as LoopProductsIcon } from '@loop/assets/img/icons/menu-icons/np/loop-products.svg';
import { ReactComponent as NpCompaniesIcon } from '@loop/assets/img/icons/menu-icons/np/np-companies.svg';
import { ReactComponent as ProjectsIcon } from '@loop/assets/img/icons/menu-icons/np/projects.svg';
import { ReactComponent as ProposalsIcon } from '@loop/assets/img/icons/menu-icons/np/proposals.svg';
import { ReactComponent as PurchaseOrdersIcon } from '@loop/assets/img/icons/menu-icons/np/purchase-orders.svg';
import { ReactComponent as QuotesIcon } from '@loop/assets/img/icons/menu-icons/np/quotes.svg';
import { ReactComponent as ServicesIcon } from '@loop/assets/img/icons/menu-icons/np/services.svg';
import { ReactComponent as TrainingsIcon } from '@loop/assets/img/icons/menu-icons/np/trainings.svg';
import {
  CRM_ROUTES,
  CUSTOMERS_ROUTES,
  LEAD_ROUTES,
  LOOP_ROUTES,
  NP_ROUTES,
} from '@loop/constants/routes-constants';
import { USER_GROUPS, USER_ROLES } from '@loop/constants/user-roles';
import { useAppDispatch, useAppSelector } from '@loop/hooks/redux';
import useMenuItems from '@loop/hooks/useMenuItems';
import { setIsSidebarCollapsed } from '@loop/store/slices/utils-slice';
import { MenuItemConfig } from '@loop/types/general-types';

const proDevMenuItems = (t: TFunction): MenuItemConfig[] => [
  { name: t('Pro dev'), isTitle: true },
  {
    name: t('Marketing'),
    icon: MarketingIcon,
    children: [
      {
        path: NP_ROUTES.MARKETING,
        name: t('Documents'),
      },
      {
        path: NP_ROUTES.MARKETING_IMAGES,
        name: t('Images'),
      },
    ],
  },

  {
    name: t('Training'),
    icon: TrainingsIcon,
    children: [
      {
        path: NP_ROUTES.VIDEOS,
        name: t('Videos'),
      },
      {
        path: NP_ROUTES.TRAINING_IMAGES,
        name: t('Images'),
      },
      {
        path: NP_ROUTES.DOCUMENTS,
        name: t('Documents'),
      },
      {
        path: NP_ROUTES.FAQ,
        name: t('FAQ'),
      },
    ],
  },
];

const crmMenuItems = (t: TFunction): MenuItemConfig[] => [
  { name: t('Crm'), isTitle: true },
  {
    name: t('Leads'),
    icon: CrmManagementIcon,
    children: [
      {
        path: LEAD_ROUTES.COMMERCIAL,
        name: t('Commercial'),
      },
      {
        path: LEAD_ROUTES.RESIDENTIAL,
        name: t('Residential'),
      },
    ],
  },
  // {
  //   name: t('Customers'),
  //   icon: CaseCustomerIcon,
  //   children: [
  //     {
  //       path: CUSTOMERS_ROUTES.COMMERCIAL,
  //       name: t('Commercial'),
  //     },
  //     {
  //       path: CUSTOMERS_ROUTES.RESIDENTIAL,
  //       name: t('Residential'),
  //     },
  //   ],
  // },
  {
    path: `${CRM_ROUTES.SITES}`,
    name: t('Sites'),
    icon: SitesIcon,
    roles: [...USER_GROUPS.NP, ...USER_GROUPS.LOOP],
  },
];

const salesMenuItems = (t: TFunction): MenuItemConfig[] => [
  { name: t('Sales'), isTitle: true },
  {
    path: `${NP_ROUTES.PROPOSALS}`,
    name: t('Proposals'),
    icon: ProposalsIcon,
    roles: [...USER_GROUPS.NP, ...USER_GROUPS.LOOP],
  },
  {
    path: `${NP_ROUTES.QUOTES}`,
    name: t('Quotes'),
    icon: QuotesIcon,
    roles: [...USER_GROUPS.NP, ...USER_GROUPS.LOOP],
  },
  {
    path: NP_ROUTES.PROJECTS,
    icon: ProjectsIcon,
    name: t('Bids'),
    roles: [...USER_GROUPS.NP, ...USER_GROUPS.LOOP],
  },
  {
    path: NP_ROUTES.INVOICES,
    icon: DocumentsIcon,
    name: t('Invoices'),
    roles: [...USER_GROUPS.NP, ...USER_GROUPS.LOOP],
  },
  {
    path: NP_ROUTES.PURCHASE_ORDERS,
    icon: PurchaseOrdersIcon,
    name: t('Purchase Orders'),
    roles: [...USER_GROUPS.NP, ...USER_GROUPS.LOOP],
  },
  {
    path: NP_ROUTES.SERVICES,
    icon: ServicesIcon,
    name: t('Services'),
  },
];

const settingsMenuItems = (t: TFunction): MenuItemConfig[] => [
  { name: t('Settings'), isTitle: true },

  {
    name: t('Account Settings'),
    icon: AccountSettingsIcon,
    children: [
      {
        path: `${NP_ROUTES.SETTINGS}/company-information`,
        name: t('Company Info'),
        roles: USER_GROUPS.NP,
      },
      {
        path: `${NP_ROUTES.SETTINGS}/company-users`,
        name: t('Users'),
        roles: USER_GROUPS.NP,
      },
      {
        path: `${NP_ROUTES.SETTINGS}/notifications`,
        name: t('Notifications'),
        roles: USER_GROUPS.NP,
      },
    ],
  },
  {
    icon: LoopProductsIcon,
    path: `${NP_ROUTES.SETTINGS}/company-products`,
    name: t('Materials'),
    roles: [USER_ROLES.NP_ADMIN],
  },

  //   {
  //     icon: NpCompaniesIcon,
  //     path: `${NP_ROUTES.SETTINGS}/network-partners`,
  //     name: t('NP Companies'),
  //     roles: USER_GROUPS.LOOP,
  //   },
  {
    icon: NpCompaniesIcon,
    name: t('Network Partners'),
    roles: USER_GROUPS.LOOP,
    children: [
      {
        path: `${NP_ROUTES.SETTINGS}/network-partners`,
        name: t('Companies'),
        roles: USER_GROUPS.LOOP,
      },
      {
        path: `${NP_ROUTES.SETTINGS}/np-catalog`,
        name: t('Catalog'),
        roles: USER_GROUPS.LOOP,
      },
    ],
  },

  {
    icon: LogoIcon,
    path: `${NP_ROUTES.SETTINGS}/loop-catalog`,
    name: t('Loop Catalog'),
    roles: USER_GROUPS.NP,
  },
  {
    icon: AdminUsersIcon,
    path: `${LOOP_ROUTES.SETTINGS}/user-accounts`,
    name: t('User Accounts'),
    roles: USER_GROUPS.LOOP,
  },
  //   {
  //     icon: NpProductsIcon,
  //     path: `${NP_ROUTES.SETTINGS}/np-products`,
  //     name: t('Materials'),
  //     roles: USER_GROUPS.LOOP,
  //   },
  //   {
  //     icon: NpProductsIcon,
  //     path: `${NP_ROUTES.SETTINGS}/np-products`,
  //     name: t('Materials'),
  //     roles: USER_GROUPS.LOOP,
  //   },

  {
    icon: LandingPageIcon,
    path: `${NP_ROUTES.SETTINGS}/landing-page`,
    name: t('Landing Page'),
    roles: [USER_ROLES.LOOP_SUPER_ADMIN, USER_ROLES.NP_ADMIN],
  },
  {
    icon: LaborItemsIcon,
    path: `${NP_ROUTES.SETTINGS}/company-labors`,
    name: t('Labors'),
    roles: [USER_ROLES.NP_ADMIN],
  },
];

const Aside = styled.aside`
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  top: 5.7rem;
  box-shadow: var(--chakra-shadows-md);
  background: white;
  border-radius: 12px;
  height: calc(95vh - 6rem);
  padding: 2rem 0.5rem;
  @media print {
    display: none;
  }
  &.collapsed {
    padding: 2rem 0.4rem;
  }
`;

const Container = styled(Box)`
  width: 12rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.2s;
  span,
  p {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }
  &.collapsed {
    width: 5.3rem;
    span {
      color: transparent !important; // forgive me LORD for I have sinned */
    }
  }
`;

const MenuItemWrapper = styled(Box)`
  padding: var(--chakra-space-2) var(--chakra-space-4);
  display: block;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  width: 100%;
  white-space: nowrap;
  color: var(--chakra-colors-grey-300);

  transition: background 0.2s, color 0.2s;
  &.active {
    background: var(--chakra-colors-primary-500);
    color: white;
    svg path {
      color: white;
    }
  }
  &:not(.active):hover {
    background: rgba(100, 211, 200, 0.2);
  }
  svg path {
    transition: color 0.05s;
  }
`;

const ArrowLeft = styled(ArrowLeftIcon)`
  transform: rotate(0deg);
  transition: transform 0.2s;
  .collapsed & {
    transform: rotate(180deg);
  }
`;

interface MenuLinkProps {
  to: string;
  linkText: React.ReactNode;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  collapsed: boolean;
}

function MenuLink({ to, linkText, icon, collapsed }: MenuLinkProps) {
  return (
    <Flex justify="center">
      <Tooltip
        placement="right"
        label={collapsed ? linkText : ''}
        bg="grey.120"
        color="grey.300"
        opacity="0.2"
      >
        <ListItem fontSize="md" w={collapsed ? '60%' : '100%'}>
          <MenuItemWrapper
            as={NavLink}
            to={to}
            exact={to === '/'}
            className="item-wrap"
          >
            {icon ? (
              <ListIcon
                as={icon}
                verticalAlign="middle"
                h="1.25rem"
                w="1.25rem"
              />
            ) : (
              <Box display="inline-block" w="1.25rem" mr="8px" />
            )}
            <span>{linkText}</span>
          </MenuItemWrapper>
        </ListItem>
      </Tooltip>
    </Flex>
  );
}

interface NestedLinksContainerProps {
  title: React.ReactNode;
  children: React.ReactNode;
  collapsed: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isOpen?: boolean;
  needCloseMenu: boolean;
}

function NestedLinksContainer({
  title,
  children,
  collapsed,
  icon,
  isOpen = false,
  needCloseMenu,
}: NestedLinksContainerProps) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  useEffect(() => {
    if (needCloseMenu) {
      setIsOpenMenu(false);
    }
  }, [needCloseMenu]);

  return collapsed ? (
    <Menu
      isOpen={isOpenMenu}
      onOpen={() => {
        setIsOpenMenu(true);
      }}
      onClose={() => {
        setIsOpenMenu(false);
      }}
    >
      <Flex justify="center" alignItems="center">
        <Tooltip
          placement="right"
          label={collapsed ? title : ''}
          bg="grey.120"
          color="grey.300"
          opacity="0.2"
        >
          <Box w="60%">
            <MenuButton as={MenuItemWrapper} cursor="pointer">
              <ListIcon
                color="grey.300"
                as={icon}
                verticalAlign="middle"
                h="1.25rem"
                w="1.25rem"
                mr="2"
              />
            </MenuButton>
          </Box>
        </Tooltip>
      </Flex>

      <Portal>
        <MenuList position="relative" pb="0" zIndex="1">
          {children}
        </MenuList>
      </Portal>
    </Menu>
  ) : (
    <Accordion defaultIndex={isOpen ? [0] : []} allowToggle>
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <>
            <Tooltip
              placement="right"
              label={collapsed ? title : ''}
              bg="grey.120"
              color="grey.300"
              opacity="0.2"
            >
              <AccordionButton
                as={MenuItemWrapper}
                fontSize="md"
                cursor="pointer"
              >
                <ListIcon
                  as={icon}
                  verticalAlign="middle"
                  h="1.25rem"
                  w="1.25rem"
                  mr="2"
                />

                {!collapsed && (
                  <Box
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    w="140px"
                  >
                    <span> {title}</span>
                  </Box>
                )}

                <Spacer />
                {!collapsed && (
                  <AccordionIcon
                    transform={`rotate(${isExpanded ? '0deg' : '-90deg'})`}
                  />
                )}
              </AccordionButton>
            </Tooltip>
            <AccordionPanel p={0} bg="grey.100" borderRadius="8">
              <List>{children}</List>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

function LeftMenu() {
  const activeRoles = useAppSelector(
    (state) => state.authModule.auth.decodedToken?.roles
  );
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { filterMenuItemsByRoles } = useMenuItems();

  const menu: MenuItemConfig[] = [
    {
      path: `${NP_ROUTES.DASHBOARD}`,
      name: t('Dashboard'),
      icon: DashboardIcon,
      roles: [...USER_GROUPS.LOOP, ...USER_GROUPS.NP],
    },

    ...proDevMenuItems(t),
    ...crmMenuItems(t),
    ...salesMenuItems(t),
    ...settingsMenuItems(t),
  ];

  const filteredMenuItems = useMemo(
    () => filterMenuItemsByRoles(menu, activeRoles),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeRoles]
  );

  const { isSidebarCollapsed: collapsed } = useAppSelector(
    (state) => state.coreModule.utils
  );

  const activePathname = useLocation().pathname;

  const [needCloseMenu, setNeedCloseMenu] = useState(false);

  return (
    <Aside
      className={collapsed ? 'collapsed' : ''}
      onScroll={() => {
        setNeedCloseMenu(true);
        setTimeout(() => {
          setNeedCloseMenu(false);
        }, 0);
      }}
    >
      <Container position="relative" className={collapsed ? 'collapsed' : ''}>
        <List>
          {filteredMenuItems.map((item) =>
            !!item?.isTitle ? (
              <Flex
                key={item.path}
                pl={collapsed ? 0 : '16px'}
                pb="20px"
                pt="12px"
                justify={collapsed ? 'center' : 'flex-start'}
              >
                <Text
                  fontWeight="500"
                  color="secondary.500"
                  textTransform="uppercase"
                  textAlign="center"
                >
                  {item.name}
                </Text>
              </Flex>
            ) : item.path ? (
              <MenuLink
                to={item.path}
                icon={item.icon}
                linkText={item.name}
                key={item.path}
                collapsed={collapsed}
              />
            ) : (
              <NestedLinksContainer
                title={item.name}
                key={item.name}
                collapsed={collapsed}
                needCloseMenu={needCloseMenu}
                icon={item.icon}
                isOpen={item.children?.some(
                  (menuItem) => menuItem.path === activePathname
                )}
              >
                {item.children?.map((child) =>
                  collapsed ? (
                    <MenuItem
                      key={child.path}
                      as={NavLink}
                      to={child.path}
                      exact={child.path === '/'}
                    >
                      {child.name}
                    </MenuItem>
                  ) : (
                    <MenuLink
                      to={child.path}
                      icon={child.icon}
                      linkText={child.name}
                      key={child.path}
                      collapsed={collapsed}
                    />
                  )
                )}
              </NestedLinksContainer>
            )
          )}
        </List>
        <Flex
          bg="white"
          position="sticky"
          bottom="-32px"
          justify={collapsed ? 'center' : 'flex-start'}
        >
          <Button
            onClick={() => dispatch(setIsSidebarCollapsed(!collapsed))}
            variant="ghost"
            leftIcon={<ArrowLeft />}
            fontWeight="normal"
            justifyContent="flex-start"
            py="2"
            mt="2"
            mb="1"
            w={collapsed ? '60%' : '100%'}
          >
            <span> {t('Collapse Menu')}</span>
          </Button>
        </Flex>
      </Container>
    </Aside>
  );
}

export default LeftMenu;

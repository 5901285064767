import commercialCustomers from './slices/crm-commercial-customers-slice';
import crmEmbeddedFormConfig from './slices/crm-embedded-form-config-slice';
import crmEmbeddedForm from './slices/crm-embedded-form-slice';
import leads from './slices/crm-leads-slice';
import residentialLeads from './slices/crm-residential-leads-slice';
import sites from './slices/crm-sites-slice';
import { combineReducers } from '@reduxjs/toolkit';

import { CRM_SLICES } from '@loop/constants/store-constatns';

export const crmFeatureReducer = combineReducers({
  [CRM_SLICES.CRM_EMBEDDED_FORM_CONFIG]: crmEmbeddedFormConfig,
  [CRM_SLICES.CRM_EMBEDDED_FORM]: crmEmbeddedForm,
  [CRM_SLICES.LEADS]: leads,
  [CRM_SLICES.RESIDENTIAL_LEADS]: residentialLeads,
  [CRM_SLICES.COMMERCIAL_CUSTOMERS]: commercialCustomers,
  [CRM_SLICES.SITES]: sites,
})!;

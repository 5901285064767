import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import ConfirmModal, {
  CONFIRM_BUTTON_TYPES,
  CONFIRM_BUTTON_VARIANT,
} from '@loop/components/common/modals/confirm-modal';

interface Props {
  children: React.ReactNode;
}

const ConfirmPageLeave = ({ children }: Props) => {
  const { t } = useTranslation();
  const callBackRef = useRef<any>(null);

  const [confirmMessage, setConfirmMessage] = useState<string>('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const getUserConfirmation = (
    message: string,
    callback: (ok: boolean) => void
  ) => {
    callBackRef.current = callback;
    setConfirmMessage(message);
    setIsConfirmModalOpen(true);
  };

  const confirmCloseHandler = (isSuccess?: boolean) => {
    callBackRef.current(!!isSuccess);
    setIsConfirmModalOpen(false);
  };

  return (
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      {children}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={confirmCloseHandler}
        title={t('Unsaved Changes')}
        messages={[confirmMessage]}
        buttons={[
          {
            text: t('No'),
            type: CONFIRM_BUTTON_TYPES.CANCEL,
            variant: CONFIRM_BUTTON_VARIANT.PRIMARY,
          },
          {
            text: t('Yes'),
            type: CONFIRM_BUTTON_TYPES.SUCCESS,
            variant: CONFIRM_BUTTON_VARIANT.SECONDARY,
            action: () => {},
          },
        ]}
      />
    </BrowserRouter>
  );
};

export default ConfirmPageLeave;

import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import { Box, Flex, Grid } from '@chakra-ui/react';

import Header from '@loop/components/layout/header';
import LeftMenu from '@loop/components/layout/menu';
import PageLoading from '@loop/components/loading/page-loading';
import { ROUTES } from '@loop/constants/routes-constants';
import { useRouterRedirectToDashboard } from '@loop/hooks/redirect-to-page-by-role';
import FeatureRoutes from '@loop/routes/features-routes';

const Login = lazy(() => import('features/auth/pages/login'));
const SignUp = lazy(() => import('features/auth/pages/signup'));
const SignUpCompany = lazy(() => import('features/auth/pages/signup-company'));
const Page404 = lazy(() => import('pages/page404'));
const SetPassword = lazy(() => import('features/auth/pages/set-password'));
const AuthenticatePage = lazy(() => import('@loop-auth/pages/authenticate'));

function Routes() {
  const topLevelPath = useRouteMatch<{ path: string }>('/:path')?.params.path;
  const nameSpacesToLoad = ['translation'];
  if (
    topLevelPath?.length &&
    ['login', 'signup', 'error404'].includes(topLevelPath)
  ) {
    nameSpacesToLoad.push(topLevelPath);
  }
  useTranslation(nameSpacesToLoad);
  const location = useLocation();
  // hack to remount header on login/signup page so that it reloads user data when needed
  const headerKey =
    topLevelPath && ['login', 'signup'].includes(topLevelPath)
      ? topLevelPath
      : '';

  const isAuthenticatePage = topLevelPath === ROUTES.AUTHENTICATE;

  return (
    <>
      {!isAuthenticatePage && <Header key={headerKey} />}

      {/* 100% - header height */}
      <Box h={isAuthenticatePage ? '100%' : 'calc(100% - 3.5rem)'}>
        <Switch location={location}>
          <Route path="/" exact>
            <Redirect to={useRouterRedirectToDashboard()} />
          </Route>
          <Route path="/signup" exact>
            <SignUp />
          </Route>
          <Route path={['/signup/company', '/signup/company/:status']} exact>
            <SignUpCompany />
          </Route>
          <Route path={['/login', '/login/:status']} exact>
            <Login />
          </Route>
          <Route path={'/authenticate'} exact>
            <AuthenticatePage />
          </Route>
          <Route path="/error404">
            <Page404 />
          </Route>
          <Route path="/password">
            <SetPassword />
          </Route>
          <Route>
            <Flex justifyContent="center" w="100%">
              <Grid
                templateColumns="max-content minmax(0, 1fr)"
                w={{ base: '90%', xl: 'container.xl' }}
                gap={12}
                pt={9}
              >
                <LeftMenu />
                <Box pb={7} w="100%">
                  <Suspense fallback={<PageLoading />}>
                    <FeatureRoutes />
                  </Suspense>
                </Box>
              </Grid>
            </Flex>
          </Route>
        </Switch>
      </Box>
    </>
  );
}

export default Routes;

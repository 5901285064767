import api from '@loop/api/api';
import { BASE_URL } from '@loop/config/constants';
import { PaginatedData, PaginatedRequest } from '@loop/types/paginated-data';
import { IMoney } from '@loop/utils/money';

interface SelectLabor {
  id: string;
  name: string;
  isLoopLabor: boolean;
}

export type LaborsResponse = PaginatedData<LaborResponse>;
type SelectLaborResponce = PaginatedData<SelectLabor>;

export interface LaborResponse {
  id: string;
  name: string;
  type: string;
  unitType: string;
  price: IMoney;
}

export interface LaborRequest {
  id: string;
}

export interface AddLaborRequest {
  name: string;
  unitType: string;
  price: IMoney;
}

interface AddCompanyLaborRequest {
  name: string;
  unitType: string;
  price: IMoney;
}

export interface CompanyLabor {
  id: string;
  name: string;
  unitType: string;
  price: IMoney;
  companyId: string;
  createdAt: string;
  updatedAt: string;
}

export interface NpLaborResponseForLoop extends CompanyLabor {
  price: IMoney;
  type: string;
  unitType: string;
  companyName: string;
}

interface DeleteLaborRequest {
  id: string;
}

interface UpdateCustomLaborRequest {
  id: string;
  data: AddLaborRequest;
}

interface UpdateDefaultLaborRequest {
  id: string;
  data: IMoney;
}

interface UpdateCompanyLaborRequest {
  id: string;
  data: AddCompanyLaborRequest;
}

const laborsApi = api.injectEndpoints({
  endpoints: (build) => ({
    labors: build.query<LaborsResponse, PaginatedRequest>({
      query: (params: PaginatedRequest) => ({
        url: `${BASE_URL.LABOR}/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['CustomLabors'],
    }),
    loopLabors: build.query<LaborsResponse, PaginatedRequest>({
      query: (params: PaginatedRequest) => ({
        url: `${BASE_URL.LABOR}/loop/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['CustomLabors'],
    }),
    defaultLabors: build.query<LaborsResponse, PaginatedRequest>({
      query: (params: PaginatedRequest) => ({
        url: `${BASE_URL.LABOR}-default/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['DefaultLabors'],
    }),
    laborsLoopNp: build.query<LaborsResponse, PaginatedRequest>({
      query: (params: PaginatedRequest) => ({
        url: `${BASE_URL.LABOR}/loop-np-labors/filtered`,
        method: 'POST',
        body: params,
      }),
    }),
    laborsLoopNpForAdmins: build.query<LaborsResponse, PaginatedRequest>({
      query: (params: PaginatedRequest) => ({
        url: `${BASE_URL.LABOR}/admin/loop-np-labors/filtered`,
        method: 'POST',
        body: params,
      }),
    }),
    labor: build.query<LaborResponse, LaborRequest>({
      query: ({ id }) => ({
        url: `${BASE_URL.LABOR}/${id}`,
      }),
      providesTags: (result) => [{ type: 'Labor', id: result?.id }],
    }),
    defaultLabor: build.query<LaborResponse, LaborRequest>({
      query: ({ id }) => ({
        url: `${BASE_URL.LABOR}-default/${id}`,
      }),
      providesTags: (result) => [{ type: 'Labor', id: result?.id }],
    }),
    createLabor: build.mutation<void, AddLaborRequest>({
      query: (values) => ({
        url: BASE_URL.LABOR,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['CustomLabors'],
    }),
    deleteLabor: build.mutation<void, DeleteLaborRequest>({
      query: (values) => ({
        url: `${BASE_URL.LABOR}/${values.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Quote', 'CustomLabors'],
    }),
    updateCustomLabor: build.mutation<void, UpdateCustomLaborRequest>({
      query: (values) => ({
        url: `${BASE_URL.LABOR}/${values.id}`,
        method: 'PUT',
        body: values.data,
      }),
      invalidatesTags: ['Labor', 'Quote', 'CustomLabors'],
    }),
    updateDefaultLabor: build.mutation<void, UpdateDefaultLaborRequest>({
      query: (values) => ({
        url: `${BASE_URL.LABOR}-default/${values.id}`,
        method: 'PUT',
        body: values.data,
      }),
      invalidatesTags: ['Labor', 'Quote', 'DefaultLabors'],
    }),
    npLabor: build.query<CompanyLabor, LaborRequest>({
      query: ({ id }) => ({
        url: `${BASE_URL.LABOR_NP}/${id}`,
        redirectOnNotFoundError: true,
      }),
      providesTags: (result) => [{ type: 'NpLabor', id: result?.id }],
    }),
    npLabors: build.query<LaborsResponse, PaginatedRequest>({
      query: (params: PaginatedRequest) => ({
        url: `${BASE_URL.LABOR_NP}/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['NpLabors'],
    }),
    createCompanyLabor: build.mutation<void, AddCompanyLaborRequest>({
      query: (values) => ({
        url: BASE_URL.LABOR_NP,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['NpLabors'],
    }),
    deleteCompanyLabor: build.mutation<void, string>({
      query: (id: string) => ({
        url: `${BASE_URL.LABOR_NP}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NpLabors'],
    }),
    updateCompanyLabor: build.mutation<void, UpdateCompanyLaborRequest>({
      query: (values) => ({
        url: `${BASE_URL.LABOR_NP}/${values.id}`,
        method: 'PUT',
        body: values.data,
      }),
      invalidatesTags: ['NpLabors', 'NpLabor'],
    }),
    allCatalogLabors: build.query<PaginatedData, PaginatedRequest>({
      query: (params: PaginatedRequest) => ({
        url: `${BASE_URL.LABOR}/catalog-all/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['Labor', 'NpLabors', 'NpLabor'],
    }),
    defaultCatalogLabors: build.query<PaginatedData, PaginatedRequest>({
      query: (params: PaginatedRequest) => ({
        url: `${BASE_URL.LABOR}/catalog-default/filtered`,
        method: 'POST',
        body: params,
      }),
      providesTags: ['Labor'],
    }),
  }),
});

export const {
  useLaborsQuery,
  useLoopLaborsQuery,
  useDefaultLaborsQuery,
  useNpLaborsQuery,
  useLaborQuery,
  useDefaultLaborQuery,
  useNpLaborQuery,
  useCreateLaborMutation,
  useCreateCompanyLaborMutation,
  useDeleteLaborMutation,
  useDeleteCompanyLaborMutation,
  useUpdateCompanyLaborMutation,
  useAllCatalogLaborsQuery,
  useDefaultCatalogLaborsQuery,
  useUpdateCustomLaborMutation,
  useUpdateDefaultLaborMutation,
  useLaborsLoopNpForAdminsQuery,
  useLaborsLoopNpQuery,
} = laborsApi;

export default laborsApi;

import {
  Box,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';

import { ReactComponent as ChevronDown } from '@loop/assets/img/icons/chevron-down-thin.svg';
import { ReactComponent as ChevronUp } from '@loop/assets/img/icons/chevron-up.svg';

import {
  ContactFormField,
  ContactFormStyles,
} from '@loop-crm/features/settings/types/embedded-form-types';

interface Props {
  field: ContactFormField;
  styles: ContactFormStyles;
}

const ContactFormInputNumberPreview = ({ field, styles }: Props) => {
  return (
    <Box mt={8}>
      <FormControl variant={'wide'} isRequired={field.fieldProperties.required}>
        <FormLabel requiredIndicator={<> *</>} mb={0}>
          {field.fieldProperties.label}
        </FormLabel>
        <NumberInput
          color={styles.placeholderTextColor}
          variant={styles.formFieldStyle}
          borderColor={styles.formFieldBorderColor}
          type={'numeric'}
          isDisabled
        >
          <NumberInputField _disabled={{ opacity: 1 }} />
          <NumberInputStepper mr={2}>
            <NumberIncrementStepper children={<ChevronUp />} border="none" />
            <NumberDecrementStepper children={<ChevronDown />} border="none" />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </Box>
  );
};

export default ContactFormInputNumberPreview;

import styled from '@emotion/styled';
import CurrencyFormat, { Values } from 'react-currency-format';
import { get, useController, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Select,
} from '@chakra-ui/react';

import config, { currencies } from '@loop/config/config';
import { getProperCurrency } from '@loop/hooks/use-currency';
import { IMoney } from '@loop/utils/money';

const CurrencySelect = styled(Select)`
  margin-left: 0.5rem;
  padding-inline-end: 0.5rem;
  border-radius: 4px;
  outline: none;
  color: var(--chakra-colors-grey-600);
  & + .chakra-select__icon-wrapper {
    width: 1rem;
    right: 0;
  }
`;

function CurrencyInput({ name, ...rest }: InputProps) {
  const { field } = useController({ name: `${name}.currency` });
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <InputGroup>
      <Input name={`${name}.value`} {...rest} pl={20} />
      <InputLeftElement w={16}>
        <CurrencySelect
          size="xs"
          borderColor={
            get(errors, `${name}.value`) ? 'transparent !important' : undefined
          }
          _focus={{ borderColor: '#3182ce !important' }}
          variant="filled"
          value={field.value}
          onChange={(e) => field.onChange(e.target.value)}
        >
          {currencies.map((currency) => (
            <option key={currency.code} value={currency.code}>
              {currency.code}
            </option>
          ))}
        </CurrencySelect>
      </InputLeftElement>
    </InputGroup>
  );
}

function NoCurrencyInput({ name, ...rest }: InputProps) {
  return <Input name={`${name}.value`} {...rest} />;
}
type Props = {
  name: string;
  label?: React.ReactNode;
  isRequired?: boolean;
  controlProps?: FormControlProps;
  InputComponent?: string | React.ComponentType;
  trim?: boolean;
  min?: number;
  max?: number;
  handleChange?: (value: IMoney) => void;
  currencyCode?: string;
  showCurrency?: boolean;
  isDisabled?: boolean;
  id?: string;
  maximumFractionDigits?: number;
} & Omit<React.ComponentProps<typeof CurrencyFormat>, 'displayType'>;

function CurrencyField({
  name,
  label,
  isRequired,
  controlProps,
  min = 0,
  max = 9999999999999,
  currencyCode,
  showCurrencySelect,
  showCurrency = true,
  isDisabled = false,
  maximumFractionDigits = 2,
  id,
  handleChange,
  ...rest
}: Props) {
  const { field, fieldState } = useController({
    name: `${name}.value`,
  });

  const { watch, setValue } = useFormContext();
  const chosenCurrency = watch(`${name}.currency`);

  const formatParts = getProperCurrency({
    currency: currencyCode || chosenCurrency,
    maximumFractionDigits,
  }).formatToParts(111111.111);

  const debouncedInputChange = ({ value }: Values) => {
    const price = {
      value: 0,
      currency: currencyCode || chosenCurrency || config.preferredCurrency,
    };

    if (!chosenCurrency) {
      setValue(`${name}.currency`, currencyCode || config.preferredCurrency);
    }

    if (typeof max === 'number' && +value > max) {
      field.onChange(max);
      price.value = +max;
    } else if (typeof min === 'number' && +value < min) {
      field.onChange(min);
      price.value = +min;
    } else {
      const currentValue =
        value && value[0] === '0' && value[1] !== '.' ? +value : value;
      field.onChange(currentValue);
      price.value = +currentValue;
    }
    handleChange && handleChange(price);
  };

  return (
    <FormControl
      id={`${name}.value`}
      isInvalid={fieldState.invalid}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isolation="isolate"
      {...controlProps}
    >
      {!!label && <FormLabel>{label}</FormLabel>}
      <CurrencyFormat
        thousandSeparator={
          formatParts.find(({ type }) => type === 'group')?.value
        }
        thousandSpacing={
          String(
            formatParts.filter(({ type }) => type === 'integer')[1].value.length
          ) as '2' | '3' | '4'
        }
        decimalScale={
          formatParts.find(({ type }) => type === 'fraction')?.value.length
        }
        decimal={formatParts.find(({ type }) => type === 'decimal')?.value}
        prefix={
          formatParts[0].type === 'currency' && showCurrency
            ? formatParts[0].value
            : undefined
        }
        suffix={
          formatParts[formatParts.length - 1].type === 'currency' &&
          showCurrency
            ? formatParts[formatParts.length - 1].value
            : undefined
        }
        allowNegative={false}
        fixedDecimalScale
        name={name}
        customInput={showCurrencySelect ? CurrencyInput : NoCurrencyInput}
        value={field.value}
        onValueChange={debouncedInputChange}
        onBlur={() => {
          field.onBlur();
          field.onChange(isNaN(field.value) ? 0 : +field.value);
        }}
        {...rest}
      />
      <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
    </FormControl>
  );
}

export default CurrencyField;

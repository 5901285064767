import { createSlice } from '@reduxjs/toolkit';

import { CurrencyCode } from '@loop/config/config';
import { STORE_PATH_NP } from '@loop/constants/store-constatns';
import { Address } from '@loop/types/address-types';

import {
  PurchaseRequestErrors,
  PurchaseRequestProductState,
  PurchaseRequestState,
} from '@loop-np/types/purchase-request-type';

interface PurchaseTaxes {
  taxes?: number;
  isTaxesIncluded: boolean;
}

const sliceName = STORE_PATH_NP.PURCHASE_REQUEST;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(
      savedState
    ) as PurchaseRequestState<PurchaseRequestProductState>)
  : null;

const defaultState = {
  products: [],
  taxes: 0,
  isTaxesIncluded: true,
};

let initialState: PurchaseRequestState<PurchaseRequestProductState> =
  parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveCompanyId: (state, action: { payload: string }) => {
      state.companyId = action.payload;
    },
    saveGeneralInformation: (
      state,
      action: {
        payload: {
          purchaseName?: string;
          contactName?: string;
          contactPhone?: string;
          contactEmail?: string;
          currency?: CurrencyCode;
          delivery?: {
            deliveryDate: Date | string | null;
            deliveryOptions: string;
            deliveryAddress?: Address;
          };
        };
      }
    ) => ({
      ...state,
      ...action.payload,
    }),
    addPurchaseRequestProduct: (
      state,
      action: { payload: PurchaseRequestProductState }
    ) => {
      state.products.push(action.payload);
    },
    setPurchaseRequestProducts: (
      state,
      action: { payload: PurchaseRequestProductState[] }
    ) => {
      state.products = action.payload;
    },
    editPurchaseRequestProduct: (
      state,
      action: {
        payload: { product: PurchaseRequestProductState; index: number };
      }
    ) => {
      state.products[action.payload.index] = action.payload.product;
    },
    removePurchaseRequestProduct: (
      state,
      action: {
        payload: { index: number };
      }
    ) => {
      state.products.splice(action.payload.index, 1);
    },
    resetPurchaseRequestState: () => {
      initialState = defaultState;
      return defaultState;
    },
    setPurchaseRequestState: (
      _,
      action: { payload: PurchaseRequestState<PurchaseRequestProductState> }
    ) => action.payload,
    setPurchaseRequestErrors: (
      state,
      action: { payload: PurchaseRequestErrors }
    ) => {
      state.errors = action.payload;
    },
    setTaxes: (state, action: { payload: PurchaseTaxes }) => {
      state.taxes = action.payload.taxes;
      state.isTaxesIncluded = action.payload.isTaxesIncluded;
    },
  },
});

export const {
  saveCompanyId,
  saveGeneralInformation,
  addPurchaseRequestProduct,
  editPurchaseRequestProduct,
  removePurchaseRequestProduct,
  resetPurchaseRequestState,
  setPurchaseRequestState,
  setPurchaseRequestErrors,
  setPurchaseRequestProducts,
  setTaxes,
} = slice.actions;

export default slice.reducer;

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: {
      50: '#E7F9F8',
      100: '#EDFEFD',
      200: '#DCF9F9',
      400: '#1DC7B8',
      500: '#64D3C8',
      600: '#1DC7B8',
      700: '#1DC7B9',
      alpha: {
        500: 'rgba(29, 199, 184, 0.2)',
        600: '#1DC7B81A',
      },
    },
    secondary: {
      500: '#1DC7B8',
    },
    grey: {
      50: '#FAFAFC',
      100: '#F5F7F7',
      105: '#F5F6FA',
      110: '#F2F3F4',
      120: '#EDF2F7',
      135: '#CBD5E0',
      150: '#E3E7F2',
      200: '#DFE1E5',
      210: '#ADBCCE',
      240: '#A0AEC0',
      250: '#A7B0C7',
      300: '#8890A0',
      320: '#8B8F95',
      350: '#707070',
      400: '#576073',
      500: '#3D454E',
      550: '#374758',
      600: '#172635',
      alpha: {
        100: 'rgba(245, 247, 247, 0.50)',
        500: 'rgba(61, 69, 78, 0.8)',
      },
    },
    red: {
      400: '#EB5757',
      500: '#E03D28',
    },
    yellow: {
      300: '#FFDA00',
      400: '#FBBD00',
      500: '#BB9900',
    },
    blue: {
      250: '#5DE3D6',
      300: '#9EE6FE',
      350: '#50BEE8',
      400: '#5A7DDD',
      500: '#397AED',
    },
    green: {
      600: '#1C423F',
    },
    white: '#FFFFFF',
    silver: '#CCCCCC',
  },
  fonts: {
    body: navigator.language === 'el' ? 'Manrope' : 'Rubik',
    heading: navigator.language === 'el' ? 'Manrope' : 'Rubik',
    mono: navigator.language === 'el' ? 'Manrope' : 'Rubik',
  },
  fontSizes: {
    xs: '0.75rem', // 12px
    md: '0.875rem', // 14px
    lg: '1rem', // 16px
    xl: '1.25rem', // 20px
    '2xl': '1.625rem', // 26px
    '4xl': '2rem', // 32px
  },
  sizes: {
    container: {
      xl: '1350px',
    },
  },
  borderRadius: {
    default: '8px',
  },
  styles: {
    global: {
      body: {
        color: 'grey.600',
        bg: 'grey.100',
        height: '100%',
      },
      html: {
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
      'input:disabled, textarea:disabled, select:disabled, label[data-disabled]':
        {
          opacity: '1 !important',
        },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 'medium',
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 'small',
      },
    },
    Table: {
      baseStyle: {
        th: {
          fontWeight: 'medium',
        },
      },
    },
    Text: {
      baseStyle: {
        fontSize: 'md',
        fontFamily: navigator.language === 'el' ? 'Manrope' : 'Rubik',
      },
    },
    Input: {
      baseStyle: {
        field: {
          color: 'grey.alpha.500',
          _disabled: {
            backgroundColor: 'grey.100',
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        color: 'grey.alpha.500',
        _disabled: {
          backgroundColor: 'grey.100',
        },
      },
    },
    NumberInput: {
      baseStyle: {
        field: {
          color: 'grey.alpha.500',
          _disabled: {
            backgroundColor: 'grey.100',
          },
        },
        stepper: {
          color: 'grey.400',
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          color: 'grey.alpha.500',
          _disabled: {
            backgroundColor: 'grey.100',
          },
        },
        icon: {
          color: 'grey.400',
        },
      },
    },
    Badge: {
      baseStyle: {
        padding: '0.5625rem 0.8125rem',
        fontWeight: 'normal',
        borderRadius: '4px',
      },
      variants: {
        outline: {
          color: 'grey.600',
          borderColor: 'grey.300',
        },
        solid: {
          background: 'grey.600',
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          border: '2px solid',
          borderColor: 'primary.400',
          borderRadius: '50%',
          height: '25px',
          width: '25px',
          cursor: 'pointer',

          _checked: {
            bg: 'primary.500',
            borderColor: 'primary.500',

            '::before': {
              bg: 'primary.500',
              height: '19px',
              width: '19px',
              border: '3px solid',
              borderColor: 'grey.50',
            },
          },
        },
        label: {
          fontSize: 'sm',
          color: 'gray.700',
          cursor: 'pointer',
          ml: '0',
          pl: '5px',
        },
      },
    },
    Switch: {
      variants: {
        wide: {
          track: {
            width: '34px',
            borderWidth: '1px',
            borderColor: 'grey.300',
            backgroundColor: 'white',
            _checked: {
              borderColor: 'primary.400',
            },
          },
          thumb: {
            backgroundColor: 'grey.300',
            _checked: {
              transform: 'translateX(22px)',
              backgroundColor: 'white',
            },
          },
        },
      },
    },
    Checkbox: {
      variants: {
        contactForm: {
          control: {
            marginTop: '2px',
            _checked: {
              backgroundColor: 'white',
              color: 'inherit',
              borderColor: 'inherit',
            },
          },
        },
        leadActivityCreation: {
          control: {
            _checked: {
              borderColor: 'primary.400',
              backgroundColor: 'primary.400',
            },
            _hover: {
              borderColor: 'primary.400',
              backgroundColor: 'primary.400',
            },
          },
        },
      },
    },
  },
});
export default theme;

import axios, { AxiosError, AxiosResponse } from 'axios';

import { logout } from '@loop/api/api';
import { Tokens } from '@loop/api/common/user';
import config from '@loop/config/config';
import { BASE_URL } from '@loop/config/constants';
import store, { RootState } from '@loop/store/store';

import { setCredentials } from '@loop-auth/store/slices/auth-slice';

interface ErrorResponse {
  error: {
    code: number;
    error: string;
    message: string;
    origin: string;
  };
}

export interface Response<T = void> {
  result: T;
  success: boolean;
}

let isAxiosRefreshing = false;
let axiosRefreshSubscribers: Promise<any>[] = [];

let isRefreshing = false;
let refreshSubscribers: Promise<any>[] = [];

export const refreshToken = async () => {
  return new Promise<void>(async (resolve, reject) => {
    if (!isRefreshing) {
      isRefreshing = true;
      const token = (store.getState() as RootState).authModule.auth.tokens;

      try {
        const response = await httpClient.post<Response<Tokens>>(
          `${config.apiUrl}/api/${BASE_URL.AUTH}/refresh`,
          JSON.stringify({ refreshToken: token.refreshToken }),
          { headers: { 'content-type': 'application/json' } }
        );

        if (response?.data?.result) {
          store.dispatch(setCredentials({ tokens: response.data.result }));
          onRefreshed();
          resolve();
        }
      } catch {
        reject();
        refreshSubscribers = [];
        logout();
        isRefreshing = false;
        throw Error;
      } finally {
        isRefreshing = false;
      }
    }

    subscribeTokenRefresh(() => {
      resolve();
    });
  });
};

const httpClient = axios.create({
  baseURL: `${config.apiUrl}/api/`,
});

httpClient.interceptors.request.use((config) => {
  const token = (store.getState() as RootState).authModule.auth.tokens;
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token.accessToken}`,
  };

  return config;
});

httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (response: AxiosError<ErrorResponse>) => {
    let error = response.response?.data.error;
    const originalRequest = response.config;

    if (response.response?.data instanceof Blob) {
      error = JSON.parse(await response.response?.data.text()).error;
    }

    const retryOrigReq = new Promise((resolve) => {
      axiosSubscribeTokenRefresh((token: any) => {
        originalRequest.headers!['Authorization'] = 'Bearer ' + token;
        resolve(axios(originalRequest));
      });
    });

    if (error?.code === 401) {
      if (!isAxiosRefreshing) {
        isAxiosRefreshing = true;
        await refreshToken();
        isAxiosRefreshing = false;
        const newToken = (store.getState() as RootState).authModule.auth.tokens
          .accessToken;
        onAxiosRefreshed(newToken as string);
      }

      return retryOrigReq;
    }

    return Promise.reject(error);
  }
);

const axiosSubscribeTokenRefresh = (cb: any) => {
  axiosRefreshSubscribers.push(cb);
};

const onAxiosRefreshed = (token: string) => {
  axiosRefreshSubscribers.map((cb: any) => cb(token));
  axiosRefreshSubscribers = [];
};

const subscribeTokenRefresh = (cb: any) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = () => {
  refreshSubscribers.map((cb: any) => cb());
  refreshSubscribers = [];
};

export default httpClient;

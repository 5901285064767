import { createSlice } from '@reduxjs/toolkit';

import config from '@loop/config/config';
import { STORE_PATH_NP } from '@loop/constants/store-constatns';

import { PRODUCT_TYPES } from '@loop-np/constants/product-constants';
import {
  ConnectProduct,
  CreateProduct,
  ProductConfiguration,
  SelectProduct,
} from '@loop-np/types/create-product-types';

const sliceName = STORE_PATH_NP.PRODUCT;

const savedState = localStorage.getItem(sliceName);
const parsedSavedState = savedState
  ? (JSON.parse(savedState) as CreateProduct)
  : null;

const defaultSelectProduct: SelectProduct = {
  productType: '',
  programmaticAdvertisingType: PRODUCT_TYPES.PROGRAMMATIC_ADVERTISING,
  name: '',
  make: '',
  model: '',
  specFile: '',
  imgFile: '',
  priceType: '',
  chargerLevel: '',
  price: {
    value: 0,
    currency: config.preferredCurrency,
  },
  additionalData: {
    warrantyCost: {
      value: 0,
      currency: config.preferredCurrency,
    },
    ratedOutput: 0,
  },
};

const defaultState = {
  selectProduct: null,
  productConfiguration: null,
  connectProduct: null,
};

let initialState: CreateProduct = parsedSavedState || defaultState;

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveProductType: (state, action: { payload: string }) => {
      state.selectProduct = {
        ...defaultSelectProduct,
        productType: action.payload,
        chargerLevel: action.payload === 'charger' ? 'L2' : '',
      };
    },
    saveSelectProduct: (state, action: { payload: SelectProduct }) => {
      state.selectProduct = action.payload;
    },
    saveProductConfiguration: (
      state,
      action: { payload: ProductConfiguration }
    ) => {
      state.productConfiguration = action.payload;
    },
    saveConnectProduct: (state, action: { payload: ConnectProduct }) => {
      state.connectProduct = action.payload;
    },
    resetProductState: (state) => {
      state.selectProduct = null;
      state.productConfiguration = null;
      state.connectProduct = null;
    },
  },
});

export const {
  saveSelectProduct,
  saveProductConfiguration,
  saveConnectProduct,
  resetProductState,
  saveProductType,
} = slice.actions;

export default slice.reducer;

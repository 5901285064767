import { useTranslation } from 'react-i18next';

import { Box, FormLabel, SimpleGrid, Text } from '@chakra-ui/react';

interface User {
  firstName?: string;
  lastName?: string;
  phone?: string | null;
  email?: string;
}

interface CompanyInfoProps {
  user?: User;
  label?: string;
  isLoading: boolean;
}

const UserInfo = ({ user, isLoading, label }: CompanyInfoProps) => {
  const { t } = useTranslation();
  return (
    <>
      {label && (
        <Text as="h3" color="grey.600" fontWeight="500" fontSize="xl" pb={2}>
          {label}
        </Text>
      )}
      <SimpleGrid
        columns={2}
        spacing={4}
        spacingY={0}
        opacity={isLoading ? '0.4' : '1'}
      >
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('First Name')}</FormLabel>
          <Text>{user?.firstName || '-'}</Text>
        </Box>
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Last Name')}</FormLabel>
          <Text>{user?.lastName || '-'}</Text>
        </Box>
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Phone Number')}</FormLabel>
          <Text>{user?.phone || '-'}</Text>
        </Box>

        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Email Address')}</FormLabel>
          <Text>{user?.email || '-'}</Text>
        </Box>
      </SimpleGrid>
    </>
  );
};

export default UserInfo;

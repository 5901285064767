import styled from '@emotion/styled';
import * as CSS from 'csstype';
import { find } from 'lodash';

import {
  Button,
  Circle,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { Token } from '@chakra-ui/styled-system/dist/types/utils';

import useStatus from '@loop/hooks/use-status';

interface Props {
  api: any;
  id: string;
  currentStatus: string;
  statusesList: StatusDescription[];
  allStatusesList?: StatusDescription[];
  onUpdateStatus: (id: string, status: string, companyId?: string) => void;
  isEdit: boolean;
  endpointName: string;
  tooltipLabel?: string;
  companyId?: string;
  fontSize?: string;
  isEmptyStatusesShown?: boolean;
  isDisabled?: boolean;
}

export interface StatusDescription {
  name: string;
  value: string;
  color: Token<CSS.Property.Color, 'colors'>;
}

const ProgressCircle = styled(Circle)`
  width: var(--chakra-space-2);
  height: var(--chakra-space-2);
  margin-right: var(--chakra-space-2);
  display: inline-block;
`;

const ProgressButton = styled(Button)`
  padding: var(--chakra-space-1) var(--chakra-space-2);
  height: 25px;
  line-height: 12px;
  font-size: var(--chakra-fontSizes-md);
  color: var(--chakra-colors-grey-800);
  font-weight: 400;
  white-space: nowrap;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.14);
  background-color: var(--chakra-colors-grey-100);
  min-width: 80px;
  text-align: left;
`;

const StatusHandler = ({
  api,
  isEdit,
  currentStatus,
  statusesList,
  id,
  endpointName,
  onUpdateStatus,
  tooltipLabel = '',
  companyId,
  fontSize = 'lg',
  allStatusesList = statusesList,
  isEmptyStatusesShown = true,
  isDisabled = false,
}: Props) => {
  const { data: statusesFromServer } = api.endpoints[endpointName].useQuery({});

  const { getCurrentStatusColor, getCurrentStatusName, getEmptyStatus } =
    useStatus({
      statusesList: allStatusesList,
      currentStatus,
    });

  const getProperStatus = (statusFromServer: string) =>
    find(
      statusesList,
      (statusFromList) => statusFromList.value === statusFromServer
    ) || getEmptyStatus(statusFromServer);

  return (
    <>
      {isEdit ? (
        <Menu variant="fixed" closeOnBlur={true}>
          <Tooltip
            hasArrow={true}
            placement="left"
            label={tooltipLabel}
            bg="rgb(232, 236, 241)"
            color="grey.400"
          >
            <MenuButton
              as={ProgressButton}
              disabled={isDisabled}
              onClick={(event) => event.stopPropagation()}
            >
              <ProgressCircle bg={getCurrentStatusColor()} />
              {getCurrentStatusName()}
            </MenuButton>
          </Tooltip>
          <MenuList minW="120px" zIndex={2}>
            <MenuGroup minWidth="100px" bg="grey.100">
              {statusesFromServer &&
                statusesFromServer.map((statusFromServer: string) => {
                  const status = getProperStatus(statusFromServer);

                  return (
                    ((status.value !== currentStatus && isEmptyStatusesShown) ||
                      (!isEmptyStatusesShown &&
                        status.value !== currentStatus &&
                        status.name)) && (
                      <MenuItem
                        fontSize="sm"
                        onClick={(event) => {
                          event.stopPropagation();
                          onUpdateStatus(id, status.value, companyId);
                        }}
                      >
                        <ProgressCircle bg={status.color} />
                        {status.name}
                      </MenuItem>
                    )
                  );
                })}
            </MenuGroup>
          </MenuList>
        </Menu>
      ) : (
        <Flex whiteSpace="nowrap" alignItems="center" fontSize={fontSize}>
          <Circle
            bg={getCurrentStatusColor()}
            w={2}
            h={2}
            mr={2}
            verticalAlign="2px"
            display="inline-block"
          />
          {getCurrentStatusName()}
        </Flex>
      )}
    </>
  );
};

export default StatusHandler;

import { useTranslation } from 'react-i18next';

import { Box, FormLabel, SimpleGrid, Text } from '@chakra-ui/react';

import AddressText from '@loop/components/common/text/address-text';
import { Address } from '@loop/types/address-types';
import { StateOperatingIn } from '@loop/types/company-type';

import CompanyStatusMenu from '@loop-np/features/settings/network-partner-companies/modals/company-status';

export interface UserCompany {
  id: string;
  isActive?: number;
  isVerified?: number;
  companyName: string;
  address: Address;
  stateOperatingIn: StateOperatingIn[] | null;
  phone: string;
  email: string;
  timeZone: string | null;
}

interface User {
  firstName?: string;
  lastName?: string;
  phone?: string | null;
  email?: string;
}

interface CompanyInfoProps {
  user?: User;
  company?: UserCompany;
  isLoading: boolean;
}

const CompanyInfo = ({ user, company, isLoading }: CompanyInfoProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Text as="h3" color="grey.600" fontWeight="500" fontSize="xl" pb={2}>
        {t('User Details')}
      </Text>

      <SimpleGrid
        columns={2}
        spacing={4}
        spacingY={0}
        opacity={isLoading ? '0.4' : '1'}
      >
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('First Name')}</FormLabel>
          <Text>{user?.firstName || '-'}</Text>
        </Box>
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Last Name')}</FormLabel>
          <Text>{user?.lastName || '-'}</Text>
        </Box>
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Phone Number')}</FormLabel>
          <Text>{user?.phone || '-'}</Text>
        </Box>

        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Email Address')}</FormLabel>
          <Text>{user?.email || '-'}</Text>
        </Box>
      </SimpleGrid>
      <Text as="h3" color="grey.600" fontWeight="500" fontSize="xl" pb={2}>
        {t('Company Details')}
      </Text>

      <SimpleGrid
        columns={2}
        spacing={4}
        spacingY={0}
        opacity={isLoading ? '0.4' : '1'}
      >
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Company Name')}</FormLabel>
          <Text>{company?.companyName}</Text>
        </Box>
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Company Address')}</FormLabel>
          {company ? (
            <AddressText serverAddress={company.address} />
          ) : (
            <Text>-</Text>
          )}
        </Box>
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Phone Number')}</FormLabel>
          <Text>{company?.phone || '-'}</Text>
        </Box>
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Contact Email')}</FormLabel>
          <Text>{company?.email}</Text>
        </Box>
        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Time Zone')}</FormLabel>
          <Text>{company?.timeZone || '-'}</Text>
        </Box>
        {company?.stateOperatingIn ? (
          <div />
        ) : (
          <Box mb={6}>
            <FormLabel fontWeight={500}>{t('Operating In')}</FormLabel>
            <Text>{'-'}</Text>
          </Box>
        )}
        {company?.stateOperatingIn?.map((state) => (
          <>
            <Box mb={6}>
              <FormLabel fontWeight={500}>
                {t('Country Operating In')}
              </FormLabel>
              <Text>{state.country.label}</Text>
            </Box>
            <Box mb={6}>
              <FormLabel fontWeight={500}>
                {state.country.value === 'US' ? t('State') : t('Region')}
              </FormLabel>
              <Text>
                {state.regions?.reduce(
                  (label, valueFromForm: any) =>
                    `${label ? label + ', ' : ''}${valueFromForm.label}`,
                  ''
                ) || '-'}
              </Text>
            </Box>
          </>
        ))}

        <Box mb={6}>
          <FormLabel fontWeight={500}>{t('Company Status')}</FormLabel>
          {company && (
            <CompanyStatusMenu company={company} isDisabled={!user} />
          )}
        </Box>
      </SimpleGrid>
    </>
  );
};

export default CompanyInfo;

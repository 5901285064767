import { createContext, useContext, useState } from 'react';

import { Portal } from '@chakra-ui/react';

import FullPageLoading from '@loop/components/loading/full-page-loading';

export const FullPageLoadingContext = createContext<{
  toggleLoading: (showLoading: boolean) => void;
}>({ toggleLoading: () => ({}) });

interface ProviderProps {
  children: React.ReactNode;
}

export const FullPageLoadingProvider = ({ children }: ProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleLoading = (showLoading: boolean) => {
    setIsLoading(showLoading);
  };

  return (
    <FullPageLoadingContext.Provider
      value={{
        toggleLoading,
      }}
    >
      {children}
      {isLoading && (
        <Portal>
          <FullPageLoading />
        </Portal>
      )}
    </FullPageLoadingContext.Provider>
  );
};

export const usePageLoading = () => {
  const context = useContext(FullPageLoadingContext);
  const togglePageLoading = (isLoading: boolean) => {
    context.toggleLoading(isLoading);
  };
  return togglePageLoading;
};

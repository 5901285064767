export const USER_ROLES = {
  LOOP_SUPER_ADMIN: 'loopSuperAdmin',
  LOOP_ADMIN: 'loopAdmin',
  NP_ADMIN: 'npAdmin',
  NP_USER: 'npUser',
  ORGANIZATION_ADMIN: 'organizationAdmin',
  ORGANIZATION_USER: 'organizationUser',
  DISTRIBUTOR_ADMIN: 'distributorAdmin',
  DISTRIBUTOR_USER: 'distributorUser',
  WAREHOUSE_ADMIN: 'warehouseAdmin',
  WAREHOUSE_USER: 'warehouseUser',
} as const;

export const USER_GROUPS = {
  LOOP: [USER_ROLES.LOOP_ADMIN, USER_ROLES.LOOP_SUPER_ADMIN],
  NP: [USER_ROLES.NP_ADMIN, USER_ROLES.NP_USER],
  ORGANIZATION: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.ORGANIZATION_USER],
  DISTRIBUTOR: [USER_ROLES.DISTRIBUTOR_ADMIN, USER_ROLES.DISTRIBUTOR_USER],
  WAREHOUSE: [USER_ROLES.WAREHOUSE_ADMIN, USER_ROLES.WAREHOUSE_USER],
};

const ALL_ROLES = Object.values(USER_ROLES);
export type RoleCheck = typeof ALL_ROLES;
export type UserRole = RoleCheck[0];

export type LoopUser = typeof USER_GROUPS.LOOP[0];
export type NpUser = typeof USER_GROUPS.NP[0];

export type UserGroup = keyof typeof USER_GROUPS;

const loopNpRoles = [...USER_GROUPS.LOOP, ...USER_GROUPS.NP];
type LoopNpRoles = typeof loopNpRoles;
export type LoopNpRole = LoopNpRoles[0];

export const Role: { [key in UserRole]: string } = {
  loopSuperAdmin: 'Loop Super Admin',
  loopAdmin: 'Loop Admin',
  npAdmin: 'NP Admin',
  npUser: 'NP User',
  organizationAdmin: 'OrganizationAdmin',
  organizationUser: 'Organization User',
  distributorAdmin: 'Distributor Admin',
  distributorUser: 'Distributor User',
  warehouseAdmin: 'Warehouse Admin',
  warehouseUser: 'Warehouse User',
} as const;

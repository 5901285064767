import { LEAD_TYPE } from '@loop/config/constants';
import { NpUser } from '@loop/constants/user-roles';

export interface Notification {
  id: string;
  type: string;
  category: string;
  message: string;
  isRead: boolean;
  metadata: {
    id: string;
    userId?: string;
    projectId?: string;
    proposalId?: string;
    proposalVersionId?: string;
    requestAssignUserId?: string;
    leadType?: LEAD_TYPE;
    role?: NpUser;
  };
  createdAt: string;
}

export enum NOTIFICATION_TYPES {
  DASHBOARD = 'Dashboard',
  USER = 'User',
  COMPANY = 'Company',
  MARKETING = 'Marketing',
  PROPOSAL = 'Proposal',
  QUOTES = 'Quote',
  INVOICE = 'Invoice',
  PURCHASE = 'Purchase',
  PROJECT = 'Project',
  BID = 'Bid',
  SERVICE = 'Service',
  CRM = 'CRM',
  NP_PRODUCT = 'NP Product',
  NP_LABOR = 'NP Labor',
}

export enum NOTIFICATION_QUOTES {
  DELETE = 'DeleteQuote',
}

export enum NOTIFICATION_INVOICES {
  DELETE = 'DeleteInvoice',
}

export enum NOTIFICATION_NP_PRODUCT {
  UPDATE = 'UpdateNpProduct',
  DELETE_FILE = 'DeleteNpProductFile',
}

export enum NOTIFICATION_NP_LABOR {
  UPDATE = 'UpdateNpLabor',
}

export enum NOTIFICATION_USER {
  UPDATE = 'UpdateUser',
  DEACTIVATE = 'DeactivateUser',
  REQUEST_ASSIGN = 'RequestAssignUser',
}

export enum NOTIFICATION_COMPANY {
  VERIFY = 'VerifyCompany',
  CREATE = 'CreateCompany',
}

export enum NOTIFICATION_LEAD {
  ASSIGN = 'AssignLead',
  CREATE = 'CreateLead',
  DELETE = 'DeleteLead',
  ARCHIVE = 'ArchiveLead',
  UPDATE = 'UpdateLead',
  RESTORED = 'Restored',
  UPDATE_ACTIVITY_CONFIG = 'UpdateActivityConfiguration',
  UPDATE_EMBEDDED_FORM_CONFIG = 'UpdateEmbeddedFormConfiguration',
}

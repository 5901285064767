import { range } from 'lodash';
import { useTranslation } from 'react-i18next';

interface IUsePaginationParams {
  offset: number;
  limit: number;
  totalRecords: number;
  onChange: (offset: number) => void;
  siblingCount?: number;
}

interface IPaginationItem {
  children: string | number;
  key: string | number;
  disabled: boolean;
  selected: boolean;
  onClick?: () => void;
  'aria-current'?: string;
}

export default function usePagination({
  totalRecords,
  offset,
  limit,
  siblingCount = 1,
  onChange,
}: IUsePaginationParams): IPaginationItem[] {
  const page = Math.floor(offset / limit) + 1;
  const totalPages = Math.ceil(totalRecords / limit);
  const { t } = useTranslation();

  const siblingsStart = Math.max(
    Math.min(page - siblingCount, totalPages - siblingCount * 2 - 2),
    3
  );

  const siblingsEnd = Math.min(
    Math.max(page + siblingCount, siblingCount * 2 + 3),
    totalPages - 2
  );

  const itemList = [];

  itemList.push('previous');
  itemList.push(1);

  if (siblingsStart > 3) {
    itemList.push('startSeparator');
  } else if (totalPages > 2) {
    itemList.push(2);
  }

  if (totalPages > siblingCount * 2 + 1) {
    itemList.push(...range(siblingsStart, siblingsEnd + 1));
  }

  if (siblingsEnd < totalPages - 2) {
    itemList.push('endSeparator');
  } else if (totalPages > siblingCount * 2 + 1) {
    itemList.push(totalPages - 1);
  }

  if (totalPages > siblingCount) {
    itemList.push(totalPages);
  }
  itemList.push('next');

  const items: IPaginationItem[] = itemList.map((item) => {
    switch (item) {
      case 'previous':
        return {
          onClick: () => {
            onChange((page - 2) * limit);
          },
          children: t('Previous') as string,
          key: item,
          selected: false,
          disabled: page === 1,
        };
      case 'next':
        return {
          onClick: () => {
            onChange(page * limit);
          },
          children: t('Next') as string,
          key: item,
          selected: false,
          disabled: page >= totalPages,
        };
      case 'startSeparator':
      case 'endSeparator':
        return {
          children: '...',
          key: item,
          selected: false,
          disabled: page >= totalPages,
        };
      default:
        return {
          onClick: () => {
            onChange(((item as number) - 1) * limit);
          },
          children: item,
          key: item,
          selected: item === page,
          disabled: false,
          'aria-current': item === page ? 'true' : undefined,
        };
    }
  });

  return items;
}
